export default class JobAd {
  public readonly id: string;
  public readonly title: string;
  public readonly description: string;
  public readonly start: string;
  public readonly city: string;

  constructor(id: string, title: string, description: string, start: string, city: string) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.start = start;
    this.city = city;
  }
}
