import {Entry} from "contentful";
import Artist from "../../domain/Artist";
import * as Contentful from "contentful";
import ArtistTourEvent from "../../domain/ArtistTourEvent";
import ContentService from "../ContentService";
import Page from "../../domain/Page";
import ArtistContentType from "./ArtistContentType";
import ContentTypeMapper from "./ContentTypeMapper";
import Navigation from "../../domain/Navigation";
import FooterData from "../../domain/FooterData";
import News from "../../domain/News";
import JobAd from "../../domain/JobAd";
import RouteInfo from "../../domain/RouteInfo";
import Creation from "../../domain/Creation";
import CrewMember from "../../domain/CrewMember";

export default class ContentfulContentService implements ContentService {
  private contentful: Contentful.ContentfulClientApi;
  private locale: string;

  constructor(contentful: Contentful.ContentfulClientApi, locale: string) {
    this.contentful = contentful;
    this.locale = locale;
  }

  public setLocale(locale: string): void {
    this.locale = locale;
  }

  public getLocale(): string {
    return this.locale;
  }

  public getAllRoutes(): Promise<RouteInfo[]> {
    return this.contentful
      .getLocales()
      .then((locales) =>
        Promise.all(
          locales.items.map((locale) => this.getRoutes(locale.code))
        ).then((routes) => routes.reduce((a, b) => a.concat(b), []))
      );
  }

  public getRoutes(locale: string): Promise<RouteInfo[]> {
    return this.contentful
      .getEntries({
        content_type: "page",
        include: 0,
        locale,
        select: "sys,fields.slug",
        limit: 1000
      })
      .then((collection) => {
        return collection.items.map(ContentTypeMapper.routeInfo);
      });
  }

  public getPage(id: string, locale?: string): Promise<Page> {
    return this.contentful
      .getEntry(id, {
        content_type: "page",
        locale: locale || this.locale,
        include: 2
      })
      .then(ContentTypeMapper.page);
  }

  public getTours(): any {
    const gt = new Date().toISOString();
    return this.contentful
      .getEntries({
          content_type: "tour",
          include: 0,
          locale: this.locale,
          order: "fields.start", 'fields.end[gte]': gt,
          limit: 500
        }
      )
      .then((collection) => collection.items.map(ContentTypeMapper.artistTour));
  }


  public getNavigation(): Promise<Navigation> {
    return this.contentful
      .getEntry("", this.query("navigation"))
      .then(ContentTypeMapper.navigation);
  }

  public getAllArtists(): Promise<Artist[]> {
    return (
      this.contentful
        .getEntries(this.query("artist", {limit: 1000}))
        // .getEntries(this.query("artist", { include: 3 }))
        .then((collection) => {
          return collection.items.map(ContentTypeMapper.artist);
        })
    );
  }

  public getCrew(): Promise<CrewMember[]> {
    return (
      this.contentful
        .getEntries(this.query("crewMember", {limit: 100}))
        .then((collection) => {
          return collection.items.map(ContentTypeMapper.crewMember);
        })
    );
  }


  public getArtistTourNameMapping(): Promise<Map<string, Artist>> {
    const limit: number = 300
    return this.contentful.getEntries(
      this.query("artist", {
        include: 0,
        limit,
        select:
          "sys.id,fields.tours,fields.name,fields.slug,fields.profileImage"
      })
    ).then((collection) => {
      const map = new Map();
      collection.items.forEach((artist: Entry<ArtistContentType>) => {
        ((artist.fields || {}).tours || []).forEach((tour) => {
          map.set(tour.sys.id, ContentTypeMapper.artist(artist));
        });
      });
      return map;
    });
  }


  public async getArtistsByIds(ids: string[]): Promise<Artist[]> {
    const artistIds = ids.sort().join(",");
    const collection = await this.contentful.getEntries({
      content_type: "artist",
      "sys.id[in]": artistIds,
      limit: 10
    });
    return collection.items.map(ContentTypeMapper.artist);
  }


  public getArtistBySlug(slug: string): Promise<Artist> {
    return this.contentful
      .getEntry(
        "",
        this.query("artist", {
          "fields.slug": slug,
          include: 2
        })
      )
      .then(ContentTypeMapper.artist);
  }

  public getCreationBySlug(slug: string): Promise<Creation> {
    return this.contentful
      .getEntry(
        "",
        this.query("creationDetail", {
          "fields.slug": slug,
          include: 2
        })
      )
      .then(ContentTypeMapper.creation);
  }

  public async getEventsOfTours(ids: string[]): Promise<ArtistTourEvent[]> {
    const tourIds = ids.sort().join(",");
    const collection = await this.contentful.getEntries({
      content_type: "event",
      "fields.tour.sys.id[in]": tourIds,
      order: "fields.start",
      limit: 1000
    });

    return collection.items.map(ContentTypeMapper.artistTourEvent);
  }

  public getUpcomingEvents(
    limit: number = 300,
    offset: number = 0
  ): Promise<ArtistTourEvent[]> {
    return this.contentful
      .getEntries(
        this.query("event", {
          order: "fields.start",
          limit,
          skip: offset,
          "fields.start[gte]": new Date().toISOString().substring(0, 10)
        })
      )
      .then((collection) => {
        return collection.items.map(ContentTypeMapper.artistTourEvent);
      });
  }

  public async getUpcomingEventsByCity(
    city: string,
    limit: number = 300,
    offset: number = 0
  ): Promise<ArtistTourEvent[]> {
    return this.contentful
      .getEntries(
        this.query("event", {
          order: "fields.start",
          limit,
          skip: offset,
          "fields.start[gte]": new Date().toISOString().substring(0, 10)
        })
      )
      .then((collection) => {
        return collection.items.map(ContentTypeMapper.artistTourEvent);
      });
  }

  public getPastEvents(
    limit: number = 1000,
    offset: number = 0
  ): Promise<ArtistTourEvent[]> {
    return this.contentful
      .getEntries(
        this.query("event", {
          order: "fields.start",
          limit,
          skip: offset,
          "fields.start[lte]": new Date().toISOString().substring(0, 10)
        })
      )
      .then((collection) => {
        return collection.items.map(ContentTypeMapper.artistTourEvent);
      });
  }

  public getFooter(): Promise<FooterData> {
    return this.contentful
      .getEntry("", this.query("footer", {include: 2}))
      .then(ContentTypeMapper.footerData);
  }

  public getNews(limit?: number, offset?: number): Promise<News[]> {
    return this.contentful
      .getEntries(
        this.query("news", {
          order: "-sys.createdAt",
          limit
        })
      )
      .then((collection) => collection.items.map(ContentTypeMapper.news));
  }

  public getNewsBySlug(slug: string): Promise<News> {
    return this.contentful
      .getEntry(
        "",
        this.query("news", {
          "fields.slug": slug
        })
      )
      .then(ContentTypeMapper.news);
  }

  public getJobs(): Promise<JobAd[]> {
    return this.contentful
      .getEntries(this.query("job"))
      .then((collection) => collection.items.map(ContentTypeMapper.jobAd));
  }

  private query(contentType: string, select: {} = {}): object {
    return {
      ...select,
      content_type: contentType,
      locale: this.locale
    };
  }
}
