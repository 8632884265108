import { Component } from "react";
import * as React from "react";
import Artist from "../../domain/Artist";
import styled from "react-emotion";

export interface Props {
  artistMap: Map<string, Artist[]>;
}

export interface State {}

interface ArtistNavigationInterface {
  theme?: any;
}
const ArtistNavigation = styled("div")((props: ArtistNavigationInterface) => ({
  fontFamily: props.theme.typography.headlineFontFamily,
  fontWeight: "normal",
  fontSize: "1.625rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  backgroundColor: props.theme.palette.grey["200"],
  flexWrap: "wrap",
  width: "100%",
  "@media(max-width: 767px)": { marginTop: 50 }
}));

interface ArtistNavigationItemInterface {
  href?: string;
  theme?: any;
}

const ArtistNavigationItem = styled("a")(
  (props: ArtistNavigationItemInterface) => ({
    // fontWeight: "bold",
    color: Boolean(props.href)
      ? props.theme.palette.common.black
      : props.theme.palette.grey["600"],

    textDecoration: "none",
    ":hover": { textDecoration: Boolean(props.href) ? "underline" : "none" }
  }),
  {
    padding: "10px 9px"
  }
);

export default class ArtistJumpBar extends Component<Props, State> {
  public render(): JSX.Element {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ#".split("");

    const { artistMap } = this.props;

    return (
      <ArtistNavigation>
        {alphabet.map(linkText => {
          const artists = artistMap.get(linkText);

          return artists && Array.isArray(artists) ? (
            <ArtistNavigationItem key={linkText} href={`#${linkText}`}>
              {linkText}
            </ArtistNavigationItem>
          ) : (
            <ArtistNavigationItem key={linkText}>
              {linkText}
            </ArtistNavigationItem>
          );
        })}
      </ArtistNavigation>
    );
  }
}
