import React from "react";

const Twitter = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icons/twitter</title>
    <desc>Created with Sketch.</desc>
    <g
      id="icons/twitter"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="twitter-svgrepo-com"
        transform="translate(0.000000, 2.000000)"
        fill="#000000"
      >
        <path
          d="M0.460655172,16.0959251 C1.47062069,15.9716049 2.49475862,16.1055203 3.50472414,15.9382303 C4.01203448,15.8569841 4.47672414,15.6848965 4.93665517,15.5032138 C3.79872414,14.7670961 2.77448276,13.9257443 1.82151724,12.9171025 C1.48013793,12.5585345 1.72665517,11.956228 2.22455172,11.9419395 C2.63234483,11.9610256 3.03062069,11.927651 3.41937931,11.8463005 C2.42834483,10.5795273 1.66024138,9.21711516 1.06282759,7.687413 C0.901655172,7.27628013 1.37575862,6.86045395 1.75986207,6.9846699 C1.98268966,7.07551128 2.21027586,7.1376714 2.44262069,7.18543874 C1.73617241,5.47405382 1.58917241,3.74838041 1.98268966,1.85531272 C2.0727931,1.42029617 2.64175862,1.3151663 2.931,1.60197894 C5.19744828,3.84860839 8.06131034,4.92900552 11.1243103,5.44526826 C10.854,3.33234565 12.342931,1.0856119 14.2963448,0.349389938 C15.918,-0.25771419 18.0943448,0.158216279 19.1848966,1.53491693 C19.9151379,1.07121912 20.925,0.918217617 21.7358276,0.774811299 C22.1625517,0.703055992 22.5751034,1.12378405 22.3238276,1.54920542 C22.0298276,2.04638208 21.7501034,2.57693331 21.441931,3.08370516 C22.0677931,2.8972248 22.7126897,2.78249975 23.3574828,2.70125355 C23.8221724,2.63909343 24.1018966,3.36092261 23.7463448,3.64773525 C22.9924138,4.25483938 22.2432414,4.9479873 21.441931,5.50252649 C21.4182414,10.8708247 19.863,15.3739916 15.1072759,18.4285984 C11.1432414,20.9814394 3.77493103,20.5512205 0.327931034,17.0758857 L0.323172414,17.0758857 C0.171413793,17.0376092 0.0908275862,16.9228842 0.0623793103,16.7986682 C-0.0703448276,16.5978994 0.0244137931,16.3493632 0.199862069,16.2537242 C0.266275862,16.1723737 0.35162069,16.1102136 0.460655172,16.0959251 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

export default Twitter;
