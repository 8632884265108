import * as React from "react";
import {Component} from "react";
import HeaderPart from "../../domain/HeaderPart";
import {
  BillboardContainer as Billboard,
  AspectRatio
} from "../Billboard";
// @ts-ignore
import SwipeableViews from "react-swipeable-views";
import {Grid, Row, Col} from "react-flexbox-grid";
import styled from "react-emotion";

const Container = styled("div")(({theme}: any) => ({
  height: "100vh",
  display: "flex",
  alignItems: "flex-end",
  [theme.palette.breakpoints.definitions.md]: {
    alignItems: "center"
  },
  [theme.palette.breakpoints.definitions.sm]: {
    alignItems: "center"
  },
  [theme.palette.breakpoints.definitions.xs]: {
    alignItems: "center"
  }
}));


export interface Props {
  parts: HeaderPart[];
}


interface DotsProps {
  active: boolean;
  theme?: any;
}

const DotsContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  paddingTop: 16,
  paddingBottom: 16
});

const Dot = styled("div")((props: DotsProps) => ({
  width: 7,
  height: 7,
  borderRadius: "50%",
  backgroundColor: props.active
    ? props.theme.palette.common.black
    : props.theme.palette.grey[500],
  marginRight: 9,
  cursor: "pointer"
}));

export interface State {
  index: number;
}

export default class FullHeightHeader extends Component<Props, State> {
  public state = {
    index: 0
  };

// @ts-ignore
  public slideTimeout: NodeJS.Timeout = undefined;
  public slideIntervalInMs = 5000;

  constructor(props: Props) {
    super(props);
    if (this.props.parts.length > 1) {
      this.activateSlideInterval();
    }
  }

  public activateSlideInterval() {
    this.slideTimeout = setTimeout(() => {
      const numParts = this.props.parts.length;
      const currentIndex = this.state.index;
      const nextIndex = currentIndex <= numParts - 2 ? currentIndex + 1 : 0;
      this.setState({index: nextIndex});
      this.activateSlideInterval();
    }, this.slideIntervalInMs);
  }


  public deactivateSlideInterval() {
    clearTimeout(this.slideTimeout);
  }

  public setIndex = (index: number) => {
    this.setState({index});
  };

  public onClickSlideControlElement(index: number) {
    this.deactivateSlideInterval();
    this.setIndex(index);
  }

  public render(): JSX.Element {
    const {parts} = this.props;
    return (
      <div>
        {parts && parts.length > 0 && (
          <>
            <SwipeableViews
              axis={"x"}
              index={this.state.index}
              onChangeIndex={this.setIndex}
              enableMouseEvents={true}
              style={{
                maxHeight: 1080,
                height: "100vh",
                margin: "0 auto",
                overflowY: "hidden"
              }}
            >
              {parts.filter((part, key) => part.imageWide).map((part, key) => (
                <Billboard
                  style={{height: "100vh"}}
                  key={`billboard_${key}`}
                  aspect={AspectRatio.LandscapeSmall}
                  imgSrc={part}
                  isFullheight={true}
                >
                  <Container>
                    <Grid>
                      <Row>
                        <Col
                          xs={12}
                          style={{
                            // padding: 0 // to keep the alignment
                            paddingBottom: "5%"
                          }}
                        >
                        </Col>
                      </Row>
                    </Grid>
                  </Container>
                </Billboard>
              ))}
            </SwipeableViews>

            {parts.length > 1 && (
              <DotsContainer>
                {parts.map((part, index) => (
                  <Dot
                    key={part.title}
                    active={this.state.index === index}
                    onClick={() => this.onClickSlideControlElement(index)}
                  />
                ))}
              </DotsContainer>
            )}

          </>
        )}
      </div>
    );
  }
}
