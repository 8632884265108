import React from "react";
import styled from "react-emotion";

import StaticTextService from "../services/StaticTextService";

const CopyrightContainer = styled("div")((props: any) => ({
  fontWeight: "normal",
  fontSize: "0.5rem",
  padding: "0.3rem",
  position: "absolute",
  color: "white",
  right: "12px",
  top: "0",
  transformOrigin: "right",
  transform: "rotate(270deg)",
  transformBox: "view-box",
  height: "20px",
  width: "calc(100% - 8px)",
}));

const CopyrightWrapper = styled("div")((props: any) => ({
  fontWeight: "normal",
  fontSize: "0.5rem",
  padding: "0.3rem",
  position: "absolute",
  color: "white",
  filter: "drop-shadow(0px 0px 1px black)",
  opacity: 0.6
}));


const CopyrightContainerRight = styled("div")((props: any) => ({
  fontWeight: "normal",
  fontSize: "0.5rem",
  padding: "0.3rem",
  position: "absolute",
  color: "white",
  right: "12px",
  top: "-12px",
  transformOrigin: "right",
  transform: "rotate(270deg)",
  transformBox: "view-box",
  height: "20px",
  // width: "calc(100% - 8px)",
}));

const CopyrightWrapperRight = styled("div")((props: any) => ({
  fontWeight: "normal",
  fontSize: "0.5rem",
  padding: "0.3rem",
  position: "relative",
  textAlign: "right",
  color: "white",
  filter: "drop-shadow(0px 0px 1px black)",
  opacity: 0.6
}));

const Copyright = (props: any) => (
  <>
    {
      props.alignLeft ?
        <CopyrightContainer>
          <CopyrightWrapper>
            {props.title && StaticTextService.renderCopyright(props.title)}
          </CopyrightWrapper>
        </CopyrightContainer>
        :
        <CopyrightContainerRight>
          <CopyrightWrapperRight>
            {props.title && StaticTextService.renderCopyright(props.title)}
          </CopyrightWrapperRight>
        </CopyrightContainerRight>
    }
  </>
);

export default Copyright;
