import React from "react";

const Arrow = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icons/chevron-right</title>
    <desc>Created with Sketch.</desc>
    <g
      id="icons/chevron-right"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="chevron-right"
        transform="translate(5.000000, 1.000000)"
        fill="#000000"
      >
        <polygon
          id="Path"
          points="0 19.3966667 8.41771094 11 0 2.585 2.5914787 0 13.6190476 11 2.5914787 22"
        />
      </g>
    </g>
  </svg>
);

export default Arrow;
