import * as React from "react";
import {Component} from "react";
import {RouteComponentProps} from "react-router-dom";
import SeoTags from "../../components/SeoTags";
import Artist from "../../domain/Artist";
import Header, {HeaderType} from "../../domain/Header";
import PrerenderingHelper from "protofy-prerender";
import ServiceRegistry from "../../services/ServiceRegistry";
import ArtistTourEvent from "../../domain/ArtistTourEvent";
import UpcomingEventsWidgetItem
  from "../../components/UpcomingEventsWidgetItem";
import MediumHeader from "../../components/header/MediumHeader";
import moment from "moment";
import {Grid, Row, Col} from "react-flexbox-grid";
import {FlexRow} from "../../components/FlexRow";
import styled from "react-emotion";
import RichText from "../../components/RichText";
import ArtistTour from "../../domain/ArtistTour";
import SubHeadlineWithIcon from "../../components/HeadlineWithIcon";
import StaticTextService from "../../services/StaticTextService";
import {AnchorButton} from "../../components/Button";
import {Section} from "../../components/Section";
import {Spacer, SpacerTopBottom} from "../../components/Spacer";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import PastEventsList from "./PastEventsList";
import VimeoEmbed from "../../components/VimeoEmbed";
import LabelConfig from "../../domain/LabelConfig";
import StaticLabelService from "../../services/StaticLabelService";
import Copyright from "../../components/Copyright";

interface Params {
  slug: string;
}

export interface Props extends RouteComponentProps<Params> {
  locale: string;
  render404Redirect: () => JSX.Element;
}

export interface State {
  notFound?: boolean;
  artist?: Artist;
  events?: ArtistTourEvent[];
  currentTour?: ArtistTour;
}

interface ColImgInterface {
  theme?: any;
  src: string;
}

const ColImg = styled(Col)((props: ColImgInterface) => ({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundImage: `url('${props.src}')`,
  backgroundColor: "black",
  paddingTop: "100%",
  position: "relative"
}));

const SectionHeadline = styled("h3")((props: any) => ({
  fontFamily: props.theme.typography.fontFamilyCondensedBold,
  fontWeight: "normal",
  fontSize: "1.625rem"
}));

export default class ArtistDetailsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      events: undefined,
      artist: undefined,
      currentTour: undefined
    };
  }

  public componentDidMount(): void {
    PrerenderingHelper.waitFor(this);
    this.loadArtistFromSlug(this.props.match.params.slug);
  }

  public componentDidUpdate(prevProps: Props): void {
    const currentSlug = this.props.match.params.slug;
    if (currentSlug !== prevProps.match.params.slug) {
      this.loadArtistFromSlug(currentSlug);
    }
    if (this.state.artist && this.state.events) {
      PrerenderingHelper.onNextFrameDone(this);
    }
  }

  public render(): JSX.Element {
    const {artist, events, notFound, currentTour} = this.state;

    const now = moment(new Date());
    const mappedCategorizedEventsFuture = this.getMappedCategorizedEvents(
      (events || []).filter(
        (e) =>
          moment(e.start).isAfter(now) && (!e.end || moment(e.end).isAfter(now))
      )
    );
    const mappedCategorizedEventsPast = this.getMappedCategorizedEvents(
      (events || []).filter(
        (e) =>
          moment(e.start).isBefore(now) &&
          (!e.end || moment(e.end).isBefore(now))
      )
    );

    const artistContactDefault = "booking@neuland-concerts.com";
    // actually category is a year-month combination
    const upcomingCategories = Array.from(mappedCategorizedEventsFuture.keys());
    const historyCategories = Array.from(mappedCategorizedEventsPast.keys());
    // const years = Array.

    const checkForPublicEvent = (config: LabelConfig[]) => {
      const cleanedList = config.length > 1 ?
        StaticLabelService.removeDuplicates(config, "tourTyp")
        :
        config;
      // Check if single event
      if (cleanedList.length === 1 && cleanedList[0].tourTyp === 'Örtliche Veranstaltung') {
        return true;
      }
      return false
    }
    if (artist) {
      const header = (currentTour || artist || {}).headerParts;
      return (
        <>
          {/* Meta Data */}
          <SeoTags
            location={this.props.location}
            title={(artist.seoTitle || "{{artist}} {{tour}} - Neuland Concerts")
              .replace("{{artist}}", artist.name || "")
              .replace("{{tour}}", currentTour ? currentTour.name || "" : "")}
            desc={
              artist.seoDescription || StaticTextService.getDefaultDescription()
            }
            noIndex={artist.seoNoIndex}
            artist={artist}
            header={new Header(HeaderType.Medium, header)}
          />
          <Section>{header && <MediumHeader parts={header}/>}</Section>
          <Section>
            <Grid>
              <Row>
                <Col xs={12}>
                  <Spacer>
                    <SubHeadlineWithIcon socials={artist.socials}>
                      {artist.name}
                    </SubHeadlineWithIcon>
                  </Spacer>
                </Col>
              </Row>
              <Row>
                {/* Artist image and booking  */}
                <Col xs={12} md={4}>
                  <FlexRow>
                    <ColImg
                      src={artist.profileImage.url}
                      title={artist.profileImage.title}
                      xs={12}
                    >
                      <Copyright title={artist.profileImage.title}/>
                    </ColImg>
                  </FlexRow>
                  <Row>
                    <Col xs={true}>
                      {(!checkForPublicEvent(artist.labelConfig) || artist.forceBookingButton) &&
                        <AnchorButton
                          fullWidth={true}
                          variant={"buttonCommonGhost"}
                          href={`mailto:${artist.contact ||
                          artistContactDefault}`}
                          style={{marginTop: "8px"}}
                        >
                          {StaticTextService.getBookingRequestText()}
                        </AnchorButton>
                      }
                    </Col>
                  </Row>
                </Col>
                {/* Artist description */}
                <Col xs={12} md={8}>
                  <RichText text={artist.description}/>
                  {artist.youtubeLink && (
                    <YoutubeEmbed url={artist.youtubeLink}/>
                  )}
                  {artist.vimeoLink && (
                    <VimeoEmbed url={artist.vimeoLink}/>
                  )}
                </Col>
              </Row>

              {/* Current tours */}
              {artist.tours
                .filter((tour) => tour.hideTour !== true)
                .map((tour) => (
                  <SpacerTopBottom key={tour.id}>
                    {currentTour && this.renderCurrentTourDetail(tour)}
                  </SpacerTopBottom>
                ))}
            </Grid>
          </Section>
          <Section>
            <Grid>
              {/* Upcoming events */}
              {upcomingCategories.length > 0 &&
                this.renderCurrentTourDateList(
                  upcomingCategories,
                  mappedCategorizedEventsFuture
                )}
            </Grid>
          </Section>
          <Section>
            <Grid>
              {/*Tour History  */}
              {Boolean(historyCategories.length) && (
                <PastEventsList
                  historyCategories={historyCategories}
                  mappedCategorizedEvents={mappedCategorizedEventsPast}
                />
              )}
            </Grid>
          </Section>
        </>
      );
    } else if (notFound) {
      return this.props.render404Redirect();
    } else {
      return <div/>; // loading?
    }
  }

  private renderCurrentTourDateList(
    currentCategories: string[],
    mappedCategorizedEvents?: Map<string, ArtistTourEvent[]>
  ): React.ReactNode {

    return (
      <Section>
        {currentCategories.map((catKey) => (
          <React.Fragment key={catKey}>
            <SectionHeadline>{moment(catKey).format("MMMM")}</SectionHeadline>
            {mappedCategorizedEvents.get(catKey)
              .map(
                (event: ArtistTourEvent, eKey: number) =>
                  event && (
                    <UpcomingEventsWidgetItem
                      key={catKey + eKey}
                      event={event}
                      renderDate={true}
                      renderCTA={true}
                      active={true}
                    />
                  )
              )}
          </React.Fragment>
        ))}
      </Section>
    );
  }

  private renderCurrentTourDetail(currentTour: ArtistTour): React.ReactNode {
    return (
      <Section>
        <Row>
          <Col xs={12}>
            <Spacer>
              <SubHeadlineWithIcon>
                <span>
                  {currentTour.name}
                </span>
              </SubHeadlineWithIcon>
            </Spacer>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <RichText text={currentTour.description}/>
          </Col>
        </Row>
      </Section>
    );
  }

  private loadArtistFromSlug(slug: string) {
    const contentService = ServiceRegistry.getContentService();
    contentService
      .getArtistBySlug(slug)
      .then((artist) => {
        if (artist.tours.length > 0) {
          contentService
            .getEventsOfTours(artist.tours.map((tour) => tour.id))
            .then((events) => {
              const tourId = this.getCurrentTourIdByEvents(artist, events);
              const currentTour = artist.tours.find(
                (tour) => tour.id === tourId
              );
              this.setState({events, currentTour});
            });
        }
        this.setState({artist});
      })
      .catch((e) => {
        console.log(e.sys || e);
        this.setState({notFound: true});
      });
  }

  /**
   * Get events categorized by start date
   * @param events
   */
  private getMappedCategorizedEvents(events: ArtistTourEvent[]) {
    const eventsCategorized = new Map<string, ArtistTourEvent[]>();
    const eventDates: string[] = events.map((event) =>
      moment(event.start).format("YYYY-MM")
    );
    const eventDatesUnique: string[] = Array.from(new Set(eventDates));
    eventDatesUnique.map((date) => {
      const eventsByMonth = events.map((event) => {
        if (moment(event.start).format("YYYY-MM") === date) {
          return event;
        }
      });
      eventsCategorized.set(date, eventsByMonth);
    });
    return eventsCategorized;
  }

  // we should lookup for the current tour
  private getCurrentTourIdByEvents(artist: Artist, events: ArtistTourEvent[]) {
    const hiddenIds = artist.tours
      .filter((tour) => tour.hideTour === true)
      .map((tour) => tour.id);
    const singleEvent =
      events &&
      events.length > 0 &&
      events.find(
        (event) => !!event.tourId && !hiddenIds.includes(event.tourId)
      );
    if (singleEvent && singleEvent.tourId) {
      return singleEvent.tourId;
    }
    return null;
  }
}
