import {Component} from "react";
import * as React from "react";
import {Row, Col} from "react-flexbox-grid";
import {SectionHeadline} from "src/components/Typography";
import {Section} from "src/components/Section";
import CrewMember from "../../domain/CrewMember";
import CrewGridTile from "./CrewGridTile";
import StaticLabelService from "../../services/StaticLabelService";

export interface Props {
  title: string;
  crewMember: CrewMember[];
}


export interface State {
}

export default class FeaturedCrewMember extends Component<Props, State> {
  public render(): JSX.Element {
    const {title, crewMember} = this.props;

    return (
      <Section>
        <SectionHeadline>{title}</SectionHeadline>
        <Row>
          {crewMember.map((person, index) => (
            <Col xs={12} sm={6} md={4} lg={3} key={index}>
              <CrewGridTile
                person={person}
                labelComponent={StaticLabelService.getPositionLabel(person)}
              />
            </Col>
          ))}
        </Row>
      </Section>
    );
  }
}
