import HeaderPart from "./HeaderPart";

export default class ArtistTour {
  public readonly id: string;
  public readonly hideTour: boolean;
  public readonly tourTyp: string;
  public readonly tourLocation: string;
  public readonly name: string;
  public readonly start: string;
  public readonly end: string;
  public readonly description: any;
  public readonly headerParts: HeaderPart[];

  constructor(
    id: string,
    tourTyp?: string,
    tourLocation?: string,
    hideTour?: boolean,
    name?: string,
    start?: string,
    end?: string,
    description?: any,
    headerParts?: HeaderPart[]
  ) {
    this.id = id;
    if (tourTyp) {
      this.tourTyp = tourTyp;
    }
    if (tourLocation) {
      this.tourLocation = tourLocation;
    }
    this.hideTour = false;
    if (hideTour === true) {
      this.hideTour = true;
    }

    if (name) {
      this.name = name;
    }
    if (start) {
      this.start = start;
    }
    if (end) {
      this.end = end;
    }
    if (description) {
      this.description = description;
    }
    if (headerParts) {
      this.headerParts = headerParts;
    }
  }
}
