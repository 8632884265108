import React from "react";

const instagram = (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <path stroke="null"
          d="m18.46154,13.84615c-0.50862,0 -0.92308,-0.41446 -0.92308,-0.92308l0,-1.84615c0,-0.50862 0.41446,-0.92308 0.92308,-0.92308l1.84615,0c0.50862,0 0.92308,0.41446 0.92308,0.92308l0,1.84615c0,0.50862 -0.41446,0.92308 -0.92308,0.92308l-1.84615,0z"
          id="Path" fill="#030104"/>
    <circle stroke="null" id="Oval" fill="#000000" fillRule="nonzero" cx="12"
            cy="12" r="3.69231"/>
    <path stroke="null"
          d="m12,6.46154c3.05815,0 5.53846,2.47938 5.53846,5.53846c0,3.05908 -2.48123,5.53846 -5.53846,5.53846c-3.05815,0 -5.53846,-2.47938 -5.53846,-5.53846c0,-3.05908 2.48031,-5.53846 5.53846,-5.53846zm0,-1.84615c-4.07169,0 -7.38462,3.31292 -7.38462,7.38462c0,4.07169 3.31292,7.38462 7.38462,7.38462c4.07169,0 7.38462,-3.31292 7.38462,-7.38462c0,-4.07169 -3.31292,-7.38462 -7.38462,-7.38462z"
          id="Shape" fill="#030104" fillRule="nonzero"/>
    <path stroke="null"
          d="m19.5,0l-15,0c-2.48585,0 -4.5,2.01415 -4.5,4.5l0,15c0,2.48585 2.01415,4.5 4.5,4.5l15,0c2.48585,0 4.5,-2.01415 4.5,-4.5l0,-15c0,-2.48585 -2.01415,-4.5 -4.5,-4.5zm2.65385,8.30769l-6.03415,0c0.87969,0.98123 1.41877,2.27169 1.41877,3.69231c0,3.05908 -2.48123,5.53846 -5.53846,5.53846c-3.05815,0 -5.53846,-2.47938 -5.53846,-5.53846c0,-1.42062 0.53908,-2.712 1.41877,-3.69231l-6.03415,0l0,-3.80769c0,-1.46308 1.19077,-2.65385 2.65385,-2.65385l15,0c1.464,0 2.65385,1.19077 2.65385,2.65385l0,3.80769z"
          id="Shape" fill="#030104" fillRule="nonzero"/>
  </svg>
);

export default instagram;
