import {Component} from "react";
import PageElement from "../domain/PageElement";
import * as React from "react";
import Hyperlink from "../domain/Hyperlink";
import RichText from "./RichText";
import {SectionHeadline} from "./Typography";
import {LinkButton} from "./Button";
import {Section} from "./Section";
import styled from "react-emotion";

export interface Props {
  title: string;
  text: any;
  callToAction: Hyperlink;
}

export interface State {
}

const CenterText = styled("div")({
  textAlign: "left"
});

export default class BookingWidget extends Component<Props, State>
  implements PageElement {
  public render(): JSX.Element {
    const {title, text, callToAction} = this.props;
    return (
      <Section>
        {title && <SectionHeadline>{title}</SectionHeadline>}
        <CenterText>
          <RichText text={text}/>
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            {callToAction && callToAction.href && (
              <LinkButton
                to={callToAction.href.replace("https://neuland-concerts.com", "")}
              >
                {callToAction.label}
              </LinkButton>
            )}
          </div>
        </CenterText>
      </Section>
    );
  }
}
