import * as React from "react";
import {Component} from "react";
import {
  TileHeadLine,
  TileContainer,
  TileContent
} from "../../components/Tile/Tile";
import Creation from "../../domain/Creation";
import styled from "react-emotion";

export interface Props {
  creation: Creation;
  labelComponent?: any;
  link?: any;
  onClick?: any;
  style?: any;
}

interface TileLabelInterface {
  theme?: any;
}

const TileLabelWrapper = styled("div")(({ theme }: TileLabelInterface ) => ({
  position: "relative",
  marginLeft:'-6px',
  ":before": {
    content: '""',
    position: "absolute",
    top: "6px",
    left: 0,
    backgroundColor: theme.palette.primary.main,
    width: "6px",
    height: "100%",
    transform: "skewY(20deg)",
    zIndex: 0,
  },
}));


class CreationsGridTile extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    const {creation} = this.props;

    if (!creation) {
      //@ts-ignore
      return null;
    }

    return (
      <TileContainer
        to={this.props.link}
        id={creation.id}
        data-background-image-url={
          creation.profileImage && creation.profileImage.url
        }
        title={creation.name}
        onClick={null}
      >
        <TileContent>
          <TileLabelWrapper>
            <TileHeadLine style={this.props.style}>{creation.name}</TileHeadLine>
          </TileLabelWrapper>
        </TileContent>
      </TileContainer>
    );
  }

  // public handleSelectArtist = (artist: any) => {
  //   this.props.selectArtist(this.props.artist);
  // };
}

export default CreationsGridTile;
