import React from "react";
import styled from "react-emotion";

// @ts-ignore
import * as logo from "../assets/images/logoSmall.png";
// @ts-ignore
import * as logo2x from "../assets/images/logoSmall@2x.png";
import * as neuland from "../assets/images/neuland-n.svg";
import {AnchorTextLink} from "./Button";
import {StyleProps} from "./Citation";

const updateConsent = () => {
  const newConsent = {...window.CookieFirst.consent};
  newConsent.advertising = true;
  window.CookieFirst.updateConsent(newConsent);
}

const Frame = styled("div")(({theme}: any) => ({
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.grey["100"],
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"

}));

const Content = styled("div")(({theme}: any) => ({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
  justifyContent: "center"

}));

const BackgroundLogo = styled("div")(({theme}: any) => ({}));

const VideoButton = styled("div")(({theme}: any) => ({
  position: "absolute",
  right: "1rem",
  bottom: "1rem"
}));


export const InverseClickButton = styled('div')(({theme}: StyleProps) => ({
  textAlign: "center",
  display: "inline-block",
  width: theme.fullWidth ? "100%" : "auto",
  textDecoration: "none",
  fontFamily: theme.typography.fontFamilyCondensedBold,
  fontWeight: "normal",
  letterSpacing: 0.5,
  paddingTop: theme.spacing.unit,
  paddingBottom: theme.spacing.unit,
  paddingRight: theme.spacing.unit * 3,
  paddingLeft: theme.spacing.unit * 3,
  borderWidth: "2px",
  borderStyle: "solid",
  marginTop: "1rem",
  cursor: "pointer",

  color: theme.palette.buttonCommon.color + "!important",
  backgroundColor: theme.palette.buttonCommon.background,
  borderColor: theme.palette.buttonCommon.color,
  transition: "background-color 0.5s, color 0.5s, border 0.5s",
  ":hover": {
    color: theme.palette.buttonCommon.color + "!important",
    backgroundColor: theme.palette.buttonCommon.backgroundHover,
    borderColor: theme.palette.buttonCommon.color,
  }
}));

const renderContent = (showContent: boolean, children: any, type: string, url: string, clickHandler: any) => {
  if (showContent) {
    return children
  }
  return <Frame>
    <Content>
      <BackgroundLogo>
        <img
          style={{
            margin: "1.3rem 0 1rem"
          }}
          src={neuland}
          alt="Neuland Concerts"
          width={'auto'}
          height={'180px'}
        />
      </BackgroundLogo>
      Mit dem Laden des Videos akzeptieren Sie Marketing-Cookies.
      <InverseClickButton onClick={() => {
        clickHandler('cookiefirst-consent')
      }}>
        Jetzt Video laden
      </InverseClickButton>
    </Content>
    <VideoButton>
      {type === 'youtube' &&
        <AnchorTextLink to={url}>Video auf Youtube ansehen </AnchorTextLink>}
      {type === 'vimeo' &&
        <AnchorTextLink to={url}>Video auf Vimeo ansehen </AnchorTextLink>}
    </VideoButton>
  </Frame>;
}

const IframeBlocker = (props: any) => {

  let showContent = false
  if (window.CookieFirst.consent) {
    showContent = !!window.CookieFirst.consent.advertising
  }

  window.addEventListener("cf_consent", function (event) {
    if (event.detail.advertising) {
      location.reload();
    }
  });

  return renderContent(showContent, props.children, props.type, props.url, updateConsent)
};

export default IframeBlocker;
