import Artist from "../domain/Artist";
import {TileLabel} from "../components/Tile/Tile";
import * as React from "react";
import styled from "react-emotion";
import CrewMember from "../domain/CrewMember";


const TileLabelContainer = styled("div")(() => ({
  position: 'absolute',
  margin: 0,
  top: '0',
  display: 'flex',
  flexFlow: 'column nowrap'
}));

export default class StaticLabelService {

  public static getPositionLabel = (person: CrewMember) => {
    return <TileLabel key={person.name}>
      <div style={{fontSize: '12px'}}>
        {person.position}
      </div>
    </TileLabel>
  }


  public static getTourLabel = (artist: Artist) => {
    let options = [];
    if (artist.formerArtist) {
      options.push(<TileLabel invertColor={artist.formerArtist}
                              key={'history'}>History</TileLabel>);
    }

    if (artist.isOnTour && artist.labelConfig) {
      const cleanedList = StaticLabelService.removeDuplicates(artist.labelConfig, "tourTyp");
      const sortedList = StaticLabelService.sortBySortMap(cleanedList, ['Tournee', 'Einzelshow(s)', 'Örtliche Veranstaltung', 'none'], 'tourTyp')
      options = sortedList.map((config, index) => {
        let singleShow = false;
        let tour = false;
        let local = false;

        if (artist.tours) {
          singleShow = artist.tours.filter((item: any) => item.tourTyp === 'Einzelshow(s)' && new Date(item.end).getTime() >= Date.now()).length > 0
          tour = artist.tours.filter((item: any) => item.tourTyp === 'Tournee' && new Date(item.end).getTime() >= Date.now()).length > 0
          local = artist.tours.filter((item: any) => item.tourTyp === 'Örtliche Veranstaltung' && new Date(item.end).getTime() >= Date.now()).length > 0
        }
        const list: any = [];
        switch (config.tourTyp) {
          case 'Tournee':
            if (tour) {
              list.push(<TileLabel key={config.name + '-' + index}>
                Auf Tour
              </TileLabel>);
            }
            break;
          case 'Örtliche Veranstaltung':
            if (local) {
              const text = config.tourLocation ? `Live in ${config.tourLocation}` : 'Örtliche Veranstaltung';
              list.push(<TileLabel key={config.name + '-' + index}>
                {text}
              </TileLabel>);
            }
            break;
          case 'Einzelshow(s)':
            if (singleShow) {
              list.push(<TileLabel key={config.name + '-' + index}>
                LIVE ON STAGE
              </TileLabel>);
            }
            break;
          default:
        }
        return list;
      })
    }
    return <TileLabelContainer>{options}</TileLabelContainer>;
  }

  public static sortBySortMap(originalArray: any[], sortMap: string[], key: string) {
    return originalArray.sort((a, b) => sortMap.indexOf(a[key]) - sortMap.indexOf(b[key])
    );
  }

  public static removeDuplicates(originalArray: any[], prop: string): any[] {
    const newArray: any[] = [];
    const lookupObject = {};

    originalArray.forEach((value, index) => {
      lookupObject[originalArray[index][prop]] = originalArray[index];
    })

    Object.keys(lookupObject).forEach((value) => {
      if (lookupObject[value]) {
        newArray.push(lookupObject[value]);
      }
    })

    return newArray;
  }
}
