import React, {Component} from "react";
import RichText from "./RichText";
import {TextLink} from "../components/Button";
import styled from "react-emotion";
import {
  TileHeadLine,
  TileContent,
  TileContainer
} from "../components/Tile/Tile";
import Copyright from "./Copyright";

export interface Props {
  title: string;
  text: any;
  actionLabel: string;
  actionLink: string;
  image: any;
}

interface TileLabelInterface {
  theme?: any;
}

const TileLabelWrapper = styled("div")(({theme}: TileLabelInterface) => ({
  marginLeft: '-6px',
  ":before": {
    content: '""',
    position: "absolute",
    top: "6px",
    left: "-6px",
    backgroundColor: theme.palette.primary.main,
    width: "10px",
    height: "100%",
    transform: "skewY(20deg)",
    zIndex: -1,
  },
}));

const Root = styled("div")(({theme}: any) => {
  const {xs, sm, md} = theme.palette.breakpoints.definitions;
  return {
    [xs]: {
      marginBottom: theme.spacing.unit * 3
    },
    [sm]: {
      marginBottom: theme.spacing.unit * 3
    },
    [md]: {
      marginBottom: theme.spacing.unit * 3
    }
  };
});

interface State {
}

export default class KnowHowColumn extends Component<Props, State> {
  public render(): JSX.Element {
    const {title, text, actionLink, actionLabel, image} = this.props;
    return (
      <Root>
        <TileContainer
          to={actionLink}
          id={'#'}
          data-background-image-url={image && image.url}
          title={title}
        >
          <TileContent>
            <TileLabelWrapper>
              <TileHeadLine style={{
                fontSize: "26px",
                outline: "none",
                lineHeight: "36px"
              }}>{title}</TileHeadLine>
            </TileLabelWrapper>
          </TileContent>
          <Copyright title={image.title}/>
        </TileContainer>
        <RichText text={text}/>
        <TextLink to={actionLink}>{actionLabel}</TextLink>
      </Root>
    );
  }
}
