import {Component} from "react";
import PageElement from "../domain/PageElement";
import * as React from "react";
import ImageInfo from "../domain/ImageInfo";
import {Row, Col} from "react-flexbox-grid";
import styled from "react-emotion";
import {Container} from "./Claims";
import RichText from "./RichText";

export interface Props {
  title: string;
  text: string;
  image: ImageInfo;
}

export interface State {
}

const PanoramaHeadline = styled("h2")((props: any) => ({
  ...props.theme.typography.panoramaHeading
}));

const RichTextContainer = styled("div")({
  margin: "0 56px 48px 56px"
});

export default class Panorama extends Component<Props, State>
  implements PageElement {
  public render(): JSX.Element {
    const {title, image, text} = this.props;

    return (
      <Container>
        <PanoramaHeadline>{title}</PanoramaHeadline>

        <RichTextContainer>
          <RichText text={text} centered={true}/>
        </RichTextContainer>

        {image &&
        <Row>
          <Col md={true}>
            <p>
              <img
                src={image.url}
                alt={image.title}
                style={{maxWidth: "100%"}}
              />
            </p>
          </Col>
        </Row>
        }
      </Container>
    );
  }
}
