import * as React from "react";
import { Component } from "react";
import styled from "react-emotion";
import { Col } from "react-flexbox-grid";

import PrerenderingHelper from "protofy-prerender";
import ServiceRegistry from "../services/ServiceRegistry";
import JobAd from "../domain/JobAd";

import { LightText } from "./LightText";

export interface Props {
  title: string;
}

export interface State {
  jobs?: JobAd[];
}

export default class JobsWidget extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public componentDidMount(): void {
    PrerenderingHelper.waitFor(this);
    ServiceRegistry.getContentService()
      .getJobs()
      .then((jobs) => this.setState({ jobs }));
  }

  public componentDidUpdate(): void {
    if (this.state.jobs) {
      PrerenderingHelper.onNextFrameDone(this);
    }
  }

  public render(): JSX.Element {
    const { jobs } = this.state;
    const { title } = this.props;

    return (
      <>
        {jobs && jobs.length >= 1 && <Headline>{title}</Headline>}
        <Col>
          {(jobs || []).map((job) => (
            <JobPosting
              key={job.id}
              title={job.title}
              description={job.description}
              start={job.start}
              city={job.city}
            />
          ))}
        </Col>
      </>
    );
  }
}

const Headline = styled("div")((props: any) => ({
  fontFamily: props.theme.typography.headlineFontFamily,
  fontSize: "1.5rem",
  fontWeight: "normal",
  paddingBottom: 24
}));

const Title = styled("div")((props: any) => ({
  // ...props.theme.typography.panorama,
  fontFamily: props.theme.typography.headlineFontFamily,
  fontSize: "1.625rem",
  fontWeight: "normal",
  lineHeight: 1
}));

const Root = styled("div")({
  borderLeft: "1px solid #000",
  paddingLeft: 21,
  marginBottom: 40
});

const JobPosting = ({ title, description, start, city }: any) => (
  <Root>
    <div>
      <div>
        {/* artist */}
        <Title>{title}</Title>
      </div>
    </div>
    <div>
      <div>{description}</div>
    </div>
    <div>
      <div>
        <LightText>{start}</LightText>
        <span> · </span>
        <LightText>{city}</LightText>
      </div>
    </div>
  </Root>
);
