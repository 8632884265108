import ContentService from "./ContentService";
import Artist from "../domain/Artist";
import Page from "../domain/Page";
import RouteInfo from "../domain/RouteInfo";
import ArtistTourEvent from "../domain/ArtistTourEvent";
import FooterData from "../domain/FooterData";
import JobAd from "../domain/JobAd";
import Navigation from "../domain/Navigation";
import News from "../domain/News";
import Creation from "../domain/Creation";
import CrewMember from "../domain/CrewMember";

class CacheEntry<T> {
  public payload?: T;
  public timeout: number;

  constructor(timeout: number, payload?: T) {
    this.payload = payload;
    this.timeout = timeout;
  }
}

export default class CachedContentService implements ContentService {
  private readonly backend: ContentService;
  private readonly allArtists: CacheEntry<Artist[]>;
  private readonly artistByIds: {
    [key: string]: CacheEntry<Artist[]>;
  };

  private readonly crew: CacheEntry<CrewMember[]>;

  private readonly artistTourNameMapping: CacheEntry<Map<string, Artist>>;
  private readonly allRoutes: CacheEntry<RouteInfo[]>;
  private readonly artists: { [slug: string]: CacheEntry<Artist> };
  private readonly creation: { [slug: string]: CacheEntry<Creation> };
  private readonly eventsOfTours: {
    [key: string]: CacheEntry<ArtistTourEvent[]>;
  };
  private readonly footer: CacheEntry<FooterData>;
  private readonly tours: any;
  private readonly jobs: CacheEntry<JobAd[]>;
  private readonly navigation: CacheEntry<Navigation>;
  private readonly newsLists: { [key: string]: CacheEntry<News[]> };
  private readonly news: { [key: string]: CacheEntry<News> };
  private readonly pages: { [key: string]: CacheEntry<Page> };
  private readonly upcomingEvents: {
    [key: string]: CacheEntry<ArtistTourEvent[]>;
  };
  private readonly upcomingEventsByCity: {
    [key: string]: CacheEntry<ArtistTourEvent[]>;
  };
  private ttlMs: number;

  constructor(backend: ContentService, ttlMs: number) {
    this.backend = backend;
    this.ttlMs = ttlMs;
    this.allArtists = new CacheEntry<Artist[]>(0, undefined);
    this.crew = new CacheEntry<CrewMember[]>(0, undefined);
    this.artistByIds = {};
    this.artistTourNameMapping = new CacheEntry<Map<string, Artist>>(
      0,
      undefined
    );
    this.allRoutes = new CacheEntry<RouteInfo[]>(0, undefined);
    this.artists = {};
    this.creation = {};
    this.eventsOfTours = {};
    this.footer = new CacheEntry<FooterData>(0, undefined);
    this.jobs = new CacheEntry<JobAd[]>(0, undefined);
    this.navigation = new CacheEntry<Navigation>(0, undefined);
    this.newsLists = {};
    this.news = {};
    this.tours = [];
    this.pages = {};
    this.upcomingEvents = {};
    this.upcomingEventsByCity = {};
  }

  public getAllArtists(): Promise<Artist[]> {
    // could build artists map, too, see news
    return this.get(this.allArtists, () => this.backend.getAllArtists());
  }

  public getCrew(): Promise<CrewMember[]> {
    // could build artists map, too, see news
    return this.get(this.crew, () => this.backend.getCrew());
  }

  public getArtistsByIds(ids: string[]): Promise<Artist[]> {
    // could build artists map, too, see news
    const cacheKey = ids.join(",");
    if (!this.artistByIds[cacheKey]) {
      this.artistByIds[cacheKey] = new CacheEntry<Artist[]>(
        0,
        undefined
      );
    }
    return this.get(this.artistByIds[cacheKey], () =>
      this.backend.getArtistsByIds(ids)
    );
  }

  public getAllRoutes(): Promise<RouteInfo[]> {
    return this.get(this.allRoutes, () => this.backend.getAllRoutes());
  }

  public getArtistTourNameMapping(): Promise<Map<string, Artist>> {
    return this.get(this.artistTourNameMapping, () =>
      this.backend.getArtistTourNameMapping()
    );
  }

  public getArtistBySlug(slug: string): Promise<Artist> {
    if (!this.artists[slug]) {
      this.artists[slug] = new CacheEntry<Artist>(0, undefined);
    }
    return this.get(this.artists[slug], () =>
      this.backend.getArtistBySlug(slug)
    );
  }

  public getCreationBySlug(slug: string): Promise<Creation> {
    if (!this.creation[slug]) {
      this.creation[slug] = new CacheEntry<Creation>(0, undefined);
    }
    return this.get(this.creation[slug], () =>
      this.backend.getCreationBySlug(slug)
    );
  }

  public getEventsOfTours(ids: string[]): Promise<ArtistTourEvent[]> {
    const cacheKey = ids.join(",");
    if (!this.eventsOfTours[cacheKey]) {
      this.eventsOfTours[cacheKey] = new CacheEntry<ArtistTourEvent[]>(
        0,
        undefined
      );
    }
    return this.get(this.eventsOfTours[cacheKey], () =>
      this.backend.getEventsOfTours(ids)
    );
  }

  public getFooter(): Promise<FooterData> {
    return this.get(this.footer, () => this.backend.getFooter());
  }

  public getTours(): Promise<FooterData> {
    return this.get(this.tours, () => this.backend.getTours());
  }

  public getJobs(): Promise<JobAd[]> {
    return this.get(this.jobs, () => this.backend.getJobs());
  }

  public getNavigation(): Promise<Navigation> {
    return this.get(this.navigation, () => this.backend.getNavigation());
  }

  public getNews(limit?: number, offset?: number): Promise<News[]> {
    const cacheKey = `${limit}:${offset}`;
    if (!this.newsLists[cacheKey]) {
      this.newsLists[cacheKey] = new CacheEntry<News[]>(0, undefined);
    }

    const now = new Date().getTime();
    return this.get(this.newsLists[cacheKey], () =>
      this.backend.getNews(limit, offset).then(news => {
        // also fillig slug based cache
        news.forEach(n => {
          if (!this.news[n.slug]) {
            this.news[n.slug] = new CacheEntry<News>(0, undefined);
          }
          this.set(this.news[n.slug], now, n);
        });
        return news;
      })
    );
  }

  public getNewsBySlug(slug: string): Promise<News> {
    if (!this.news[slug]) {
      this.news[slug] = new CacheEntry<News>(0, undefined);
    }
    return this.get(this.news[slug], () => this.backend.getNewsBySlug(slug));
  }

  public getPage(id: string, locale?: string): Promise<Page> {
    const loc = locale ? locale : this.backend.getLocale();
    const cacheKey = `${loc}:${id}`;
    if (!this.pages[cacheKey]) {
      this.pages[cacheKey] = new CacheEntry<Page>(0, undefined);
    }
    return this.get(this.pages[cacheKey], () => this.backend.getPage(id, loc));
  }

  public getRoutes(locale: string): Promise<RouteInfo[]> {
    return this.backend.getRoutes(locale);
  }

  public getUpcomingEventsByCity(
    city?: string,
    limit?: number,
    offset?: number
  ): Promise<ArtistTourEvent[]> {
    const cacheKey = `${limit}:${offset}`;
    if (!this.upcomingEventsByCity[cacheKey]) {
      this.upcomingEventsByCity[cacheKey] = new CacheEntry<ArtistTourEvent[]>(
        0,
        undefined
      );
    }
    return this.backend.getUpcomingEventsByCity(city, limit, offset);
  }

  public getUpcomingEvents(
    limit?: number,
    offset?: number
  ): Promise<ArtistTourEvent[]> {
    const cacheKey = `${limit}:${offset}`;
    if (!this.upcomingEvents[cacheKey]) {
      this.upcomingEvents[cacheKey] = new CacheEntry<ArtistTourEvent[]>(
        0,
        undefined
      );
    }
    return this.get(this.upcomingEvents[cacheKey], () =>
      this.backend.getUpcomingEvents(limit, offset)
    );
  }

  public setLocale(locale: string): void {
    this.backend.setLocale(locale);
  }

  public getLocale(): string {
    return this.backend.getLocale();
  }

  private get<T>(cache: CacheEntry<T>, loader: () => Promise<T>): Promise<T> {
    const now = new Date().getTime();
    if (cache && cache.timeout > now) {
      return new Promise<T>((resolve, reject) => resolve(cache.payload));
    }

    return loader().then(payload => this.set(cache, now, payload));
  }

  private set<T>(cache: CacheEntry<T>, now: number, payload: T): T {
    cache.payload = payload;
    cache.timeout = now + this.ttlMs;
    return payload;
  }
}
