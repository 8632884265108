import * as React from "react";
import {Component} from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import Page from "../domain/Page";
import LargeHeader from "../components/header/LargeHeader";
import MediumHeader from "../components/header/MediumHeader";
import FullHeightHeader from "../components/header/FullHeightHeader";
import MediumHeaderWithHeadlines
  from "../components/header/MediumHeaderWithHeadlines";
import DefaultHeader from "../components/header/DefaultHeader";
import Header, {HeaderType} from "../domain/Header";
import ServiceRegistry from "../services/ServiceRegistry";
import RichText from "../components/RichText";
import {Grid, Row, Col} from "react-flexbox-grid";
import PrerenderingHelper from "protofy-prerender";
import SeoTags from "../components/SeoTags";
import {Section} from "../components/Section";
import {BackgroundLazyLoader} from "../functions/lazyLoad.js";
import StaticTextService from "./../services/StaticTextService";
//import CookieFirstTable from "../components/CookieFirstTable";
import styled from "react-emotion";
import Copyright from "../components/Copyright";
import {isMobile} from "react-device-detect";

interface Props extends RouteComponentProps<{}> {
  pageId: string;
  locale: string;
}

interface State {
  page?: Page;
}

interface BackgroundWrapperInterface {
  theme?: any;
}

const Disruptor = styled(Col)(() => ({
  position: "absolute",
  width: "100%",
  zIndex: 1,
  // top: 0,
  // right: "15%",
  // zIndex: 1,
}));


const BackgroundWrapper = styled("div")(({theme}: BackgroundWrapperInterface) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.contrastColor.default
}));

export default class GenericPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      page: undefined
    };
  }

  public componentDidMount(): void {
    PrerenderingHelper.waitFor(this);
    ServiceRegistry.getContentService()
      .getPage(this.props.pageId, this.props.locale)
      .then((page) => {
        this.setState({page});
      });
  }

  public componentDidUpdate(): void {
    if (this.state.page) {
      PrerenderingHelper.onNextFrameDone(this);
    }
    BackgroundLazyLoader();
  }

  public renderHeader(header: Header, title: string): object {
    const {parts} = header;
    const path = this.props.location.pathname;

    switch (header.type) {
      case HeaderType.Large:
        return <LargeHeader title={title} parts={parts}/>;
      case HeaderType.Medium:
        return <MediumHeader title={title} parts={parts}/>;
      case HeaderType.FullHeight:
        return <FullHeightHeader parts={parts}/>;
      case HeaderType.MediumWithHeadlines:
        return (
          <MediumHeaderWithHeadlines
            headlines={header.headlines}
            parts={parts}
          />
        );
      default:
        if (path === "/artists" || path === "/en/artists") {
          return (
            <DefaultHeader
              title={title}
              filter={true}
              search={true}
              active={true}
              searchPlaceholder={StaticTextService.getArtistPlaceholder()}
              multiSelectContent={[StaticTextService.getOnTourLabel()]}
              displayHistory={true}
              moment={false}
            />
          );
        } else {
          return (
            <DefaultHeader
              title={title}
              filter={false}
              search={false}
              active={true}
            />
          );
        }
    }
  }

  public render(): JSX.Element {
    const {page} = this.state;

    if (!page) {
      return <div/>;
    }

    // if (page.disruptor) {
    //   console.log("renderHeader", page.disruptor.fields.file.url);
    // }
    const text = page.text ? <RichText text={page.text}/> : null;
    return (
      <>
        <BackgroundWrapper>
          <SeoTags
            location={this.props.location}
            title={page.seoTitle || page.title || page.navigationTitle}
            desc={page.seoDescription}
            noIndex={page.seoNoIndex}
            header={page.header}
          />
          <Section style={{
            position: "relative"
          }}>
            {page.disruptor &&
              <Disruptor>
                <Grid>
                  <Row>
                    <Col xs={12} lg={2} lgOffset={10}>
                      {isMobile ?
                        <a href={page.disruptorUrl} target={'_blank'} style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "1rem",
                          marginRight: "0rem",
                        }}>
                          <img
                            src={page.disruptor.fields.file.url}
                            alt="Neuland Concerts"
                            width={'auto'}
                            height={'180px'}
                          />
                        </a>
                        :
                        <a href={page.disruptorUrl} target={'_blank'} style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "1rem",
                          marginRight: "-7rem",
                        }}>
                          <img
                            src={page.disruptor.fields.file.url}
                            alt="Neuland Concerts"
                            width={'auto'}
                            height={'300px'}
                          />
                        </a>
                      }
                    </Col>
                  </Row>
                </Grid>
              </Disruptor>
            }
            {this.renderHeader(page.header, page.title)}
          </Section>

          <Grid>
            <Row>
              <Col xs={12}>
                {text}
                {page.elements}
              </Col>
            </Row>
          </Grid>
          {/*<CookieFirstTable pageId={this.props.pageId}/>*/}
        </BackgroundWrapper>
      </>
    );
  }
}
