import {Component} from "react";
import * as React from "react";
import {SectionHeadline} from "src/components/Typography";
import {Section} from "src/components/Section";
import RichText from "../../components/RichText";
import {Row, Col} from "react-flexbox-grid";
import styled from "react-emotion";

export interface Props {
  headline: string;
  text1: string;
  text2: string;
  text3: string;
  text4: string;

}

export interface State {
}

interface StyledColumnInterface {
  theme?: any;
  active?: string;
}

const StyledColumn = styled("div")((props: StyledColumnInterface) => ({
  color: props.theme.palette.common.black,
  padding: '0 1rem 0 0'
}));


export default class MultiColumnText extends Component<Props, State> {
  public render(): JSX.Element {
    const {headline, text1, text2, text3, text4} = this.props;
    const textCount = [text1, text2, text3, text4].filter(item => item).length;

    const columnCount = 12 / textCount;

    return (
      <Section>
        <SectionHeadline>{headline}</SectionHeadline>
        <Row>
          {text1 &&
            <Col xs={12} md={6} lg={columnCount} key={'text1'}>
              <StyledColumn>
                <RichText text={text1} centered={false}/>
              </StyledColumn>
            </Col>
          }
          {text2 &&
            <Col xs={12} md={6} lg={columnCount} key={'text2'}>
              <StyledColumn>
                <RichText text={text2} centered={false}/>
              </StyledColumn>
            </Col>
          }
          {text3 &&
            <Col xs={12} md={6} lg={columnCount} key={'text3'}>
              <StyledColumn>
                <RichText text={text3} centered={false}/>
              </StyledColumn>
            </Col>
          }
          {text4 &&
            <Col xs={12} md={6} lg={columnCount} key={'text4'}>
              <StyledColumn>
                <RichText text={text4} centered={false}/>
              </StyledColumn>
            </Col>
          }
        </Row>
        {/*<SectionHeadline>{this.props}</SectionHeadline>*/}
      </Section>
    );
  }
}
