import React from "react";
import styled from "react-emotion";

const YearSelectButton = styled("div")((props: any) => ({
  fontFamily: props.theme.typography.fontFamilyCondensedBold,
  fontWeight: "normal",
  fontSize: "1.625rem",
  textDecoration: props.active ? "underline" : "none",
  padding: `0 ${props.theme.spacing.unit}px`,
  cursor: "pointer"
}));

class YearSelect extends React.Component<
  { category: string; handleSelectYear: (x: string) => void; active: boolean },
  {}
> {
  public handleClick = () => {
    const { category } = this.props;
    this.props.handleSelectYear(category);
  };
  public render() {
    const { active } = this.props;

    return (
      <YearSelectButton onClick={this.handleClick} active={active}>
        {this.props.category}
      </YearSelectButton>
    );
  }
}

export default YearSelect;
