import {Component} from "react";
import {Row, Col, Grid} from "react-flexbox-grid";
import ServiceRegistry from "../services/ServiceRegistry";
import * as React from "react";
import PageElement from "../domain/PageElement";
import RichText from "./RichText";
import {SubHeadline} from "../components/Typography";

import FacebookBoxed from "./icons/facebookBoxed";
import InstagramBoxed from "./icons/instagramBoxed";
import YoutubeBoxed from "./icons/youtubeBoxed";

import styled from "react-emotion";
import {Section} from "./Section";
import {InverseButton, SimpleTextLink} from "./Button";
import StaticTextService from "src/services/StaticTextService";
import LinkedInBoxed from "./icons/linkedInBoxed";

const FooterWrapper = styled("div")(props => ({
  ...props.theme.typography.body1,
  padding: `${props.theme.spacing.unit * 9}px 0`,
  backgroundColor: props.theme.palette.common.black,
  color: props.theme.palette.common.white,
  flexShrink: 0,
  a: {
    color: props.theme.palette.common.white,
  }
}));

const SocialMediaLink = styled("a")(props => ({
  marginRight: props.theme.spacing.unit
}));

const FooterLinkWrapper = styled("span")(props => ({
  a: {
    color: props.theme.palette.footer.white,
  },
  svg: {

    path: {
      fill: props.theme.palette.footer.white
    },
    polygon: {
      fill: props.theme.palette.footer.white
    }
  }
}));


const FooterHeadlines = styled(SubHeadline)((props: any) => ({
  fontSize: "2rem",
  color: props.theme.palette.common.white,
  marginBottom: props.theme.spacing.unit * 2
}));

export interface Props {
  columns?: any[];
}

export interface State {
  columns: any[];
  facebookLink: string;
  instagramLink: string;
  youtubeLink: string;
  linkedInLink: string;
  linkedInCreationsLink: string;
}

export default class Footer extends Component<Props, State>
  implements PageElement {
  constructor(props: Readonly<Props>) {
    super(props);

    console.log("props", props);
    this.state = {
      columns: props.columns || [],
      facebookLink: "",
      instagramLink: "",
      youtubeLink: "",
      linkedInLink: "",
      linkedInCreationsLink: "",
    };
  }

  public componentDidMount(): void {
    ServiceRegistry.getContentService()
      .getFooter()
      .then(footer => {
        const {
          columns = [],
          facebookLink = "",
          instagramLink = "",
          youtubeLink = "",
          linkedInLink = "",
          linkedInCreationsLink = "",
        } = footer;

        this.setState({
          columns,
          facebookLink,
          instagramLink,
          youtubeLink,
          linkedInLink,
          linkedInCreationsLink,
        });
      });
  }

  public render(): JSX.Element {
    return (
      <FooterWrapper className="footer">
        <Grid>
          <Section>
            <Row center={"xs"} start={"md"}>
              {this.state.columns.map(column => (
                <Col xs={12} md={true} key={column.id}>
                  <FooterHeadlines>{column.title}</FooterHeadlines>
                  <FooterLinkWrapper>
                    {column.actionLink && (
                      <SimpleTextLink
                        to={(column.actionLink || "").replace(
                          "https://neuland-concerts.com",
                          ""
                        )}
                      >
                        {column.actionLabel}
                      </SimpleTextLink>
                    )}
                    <RichText text={column.text}/>
                  </FooterLinkWrapper>
                </Col>
              ))}
            </Row>
            <Row center={"xs"} start={"md"}>
              <Col xs={12} md={8}>
                <FooterLinkWrapper>
                  <SocialMediaLink href={this.state.facebookLink} target={'_blank'}>
                    <FacebookBoxed/>
                  </SocialMediaLink>
                  <SocialMediaLink href={this.state.instagramLink} target={'_blank'}>
                    <InstagramBoxed/>
                  </SocialMediaLink>
                  <SocialMediaLink href={this.state.youtubeLink} target={'_blank'}>
                    <YoutubeBoxed/>
                  </SocialMediaLink>
                  {(this.state.linkedInLink || this.state.linkedInCreationsLink) &&
                    <SocialMediaLink
                      href={(location.pathname.indexOf('/creations') === -1) ? this.state.linkedInLink : this.state.linkedInCreationsLink}
                      target={'_blank'}
                    >
                      <LinkedInBoxed/>
                    </SocialMediaLink>
                  }
                </FooterLinkWrapper>
              </Col>
              <Col xs={12} md={4}>
                <FooterLinkWrapper>
                  <InverseButton to="/newsletter">
                    {StaticTextService.getNewsletterLabel()}
                  </InverseButton>
                </FooterLinkWrapper>
              </Col>
            </Row>
          </Section>
        </Grid>
      </FooterWrapper>
    );
  }
}
