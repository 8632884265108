import React from "react";

const VIP = (props: any) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.817263,16.4149438 L12.237263,14.1149438 L8.65726295,16.4149438 L9.73726295,12.2949438 L6.44726295,9.6149438 L10.697263,9.3549438 L12.237263,5.4149438 L13.777263,9.3549438 L18.027263,9.6149438 L14.737263,12.2949438 L15.817263,16.4149438 Z M20.237263,11.6149438 C20.237263,10.5049438 21.137263,9.6149438 22.237263,9.6149438 L22.237263,5.6149438 C22.237263,4.5049438 21.337263,3.6149438 20.237263,3.6149438 L4.23726295,3.6149438 C3.13269345,3.6149438 2.23726295,4.5103743 2.23726295,5.6149438 L2.23726295,9.6149438 C3.34726295,9.6149438 4.23726295,10.5149438 4.23726295,11.6149438 C4.23726295,12.7195133 3.34183245,13.6149438 2.23726295,13.6149438 L2.23726295,17.6149438 C2.23726295,18.7195133 3.13269345,19.6149438 4.23726295,19.6149438 L20.237263,19.6149438 C21.3418325,19.6149438 22.237263,18.7195133 22.237263,17.6149438 L22.237263,13.6149438 C21.1326935,13.6149438 20.237263,12.7195133 20.237263,11.6149438 Z"
      id="Shape"
      fill="#FFFFFF"
      fillRule="nonzero"
      transform="translate(12.237263, 11.614944) rotate(-10.000000) translate(-12.237263, -11.614944) "
    />
  </svg>
);

export default VIP;
