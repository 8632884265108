import React from "react";
import styled from "react-emotion";

import HeaderPart from "../../domain/HeaderPart";
import Copyright from "../Copyright";

export enum AspectRatio {
  Landscape = "50%",
  Portrait = "85%",
  Quadrat = "100%",
  LandscapeSmall = "30%",
  a16to9 = "56.25%",
  a16to10 = "62.5%",
  a4to3 = "75%",
  a1to1 = "100%"
}

interface BillboardContainerInterface {
  imgSrc: HeaderPart;
  aspectRatio: AspectRatio;
}

const BillboardContainer = styled("div")(
  ({imgSrc, aspectRatio}: BillboardContainerInterface) => ({
    backgroundImage: `url(${
      imgSrc.imagePhone ? imgSrc.imagePhone.url : imgSrc.imageWide && imgSrc.imageWide.url
    })`,
    backgroundSize: "cover",
    backgroundPosition: "top center",
    paddingTop: "100%",
    position: "relative",
    overflow: "hidden",
    height: 0,

    "@media (min-width: 768px)": {paddingTop: aspectRatio},
    "@media (min-width: 992px)": {
      paddingTop: aspectRatio,
      backgroundImage: `url(${
        imgSrc.imageTablet ? imgSrc.imageTablet.url : imgSrc.imageWide && imgSrc.imageWide.url
      })`
    },
    "@media (min-width: 1280px)": {
      paddingTop: aspectRatio,
      backgroundImage: `url(${imgSrc.imageWide && imgSrc.imageWide.url})`
    }
  })
);

const BillboardCanvas = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%"
});

interface BillboardInterface {
  imgSrc: HeaderPart;
  label?: string;
  children: JSX.Element;
  aspect?: AspectRatio;
  isFullheight?: boolean;
}

const Billboard = ({
                     imgSrc,
                     children,
                     aspect = AspectRatio.Landscape,
                     isFullheight = false
                   }: BillboardInterface) => {

  // @ts-ignore
  const title = imgSrc.imageWide.title || imgSrc.imageTablet.title || imgSrc.imagePhone.title;
  return (
    <>
      <BillboardContainer
        imgSrc={imgSrc}
        title={imgSrc.title}
        aspectRatio={aspect}
        style={isFullheight ? {height: "calc(100vh - 72px)"} : {}}
      >
        <BillboardCanvas>{children}</BillboardCanvas>
      </BillboardContainer>
      <Copyright title={title} />
    </>
  );
};

export default Billboard;
