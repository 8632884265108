import "@babel/polyfill";
import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "normalize.css";
import {injectGlobal} from "emotion";
import "moment/locale/de";
import themeLight from "./neuland-concerts-theme";
import themeDark from "./neuland-concerts-dark-theme";


const themeHandler = () => {
  let theme = themeLight
  if (window.location.pathname.indexOf('/creations') !== -1) {
    theme = themeDark;
  } else {
    theme = themeLight;
  }
  return theme;
}

const theme = themeHandler();
window.addEventListener('popstate', themeHandler);


injectGlobal`
  /* roboto-300 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local(''),
         url('/assets/fonts/roboto-v29-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto-v29-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
         font-display: swap;
  }
  /* roboto-regular - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('/assets/fonts/roboto-v29-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto-v29-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
         font-display: swap;
  }

  /* roboto-500 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local(''),
         url('/assets/fonts/roboto-v29-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto-v29-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
         font-display: swap;
  }

  /* roboto-900 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: local(''),
         url('/assets/fonts/roboto-v29-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto-v29-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
         font-display: swap;
  }

  html, body, #root {
    height: 100%;
  }

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSize}px;
    letter-spacing: ${theme.typography.letterSpacing};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Rich text section */
  .rich-text-section p { margin-top: 0 }
  .rich-text-section h1 {
    margin-top: 0;
    font-family: ${theme.typography.fontFamilyCondensedBold};
    font-weight: normal;
   }
  .rich-text-section h2 {
    margin-top: 0;
    font-family: ${theme.typography.fontFamilyCondensedBold};
    font-weight: normal;
   }
  .rich-text-section h3 {
    margin-top: 0;
    font-family: ${theme.typography.fontFamilyCondensedBold};
    font-size: 1.5rem;
    font-weight: normal;
    margin-bottom: ${theme.spacing.unit}px;
   }
  .rich-text-section ul { padding-left: 1rem; margin-top: 0; }
  .rich-text-section ul p { margin-bottom: 0; }
  .rich-text-section a { color: ${theme.palette.common.black}; transition: opacity 0.5s }
  .rich-text-section a:hover { color: ${theme.palette.common.black}; opacity:0.6 }
  .rich-text-section a:visited { color: ${theme.palette.common.black} }

  .newsletter-n2g{
     a:visited {
        color: #0000EE;
      }

     label {
       font-weight: bold;
       margin-bottom: 5px;
     }

    input[type="checkbox"], input[type="radio"] {
       margin-right: 10px;
        + label {
       font-weight: normal;
     }
    }
    input[type="text"], input[type="email"], input[type="date"] {
      width: 300px;
    }

     input {
       margin-top: 10px;
     }

     button {
       background-color: #000 !important;
       margin-bottom: 10px;
       font-weight: bold;
       border-radius: 0px !important;

       @media(max-width: 991px){
         width: 300px;
       }
     }
   }
  hr {
    color: ${theme.palette.grey.A100};
    background-color: ${theme.palette.grey.A100};
    margin: ${theme.spacing.unit * 6}px 0;
    height: 1px;
    border: none;
  }

  .footer a {
    text-decoration: none;
    color: ${theme.palette.common.white};
    transition: opacity 0.5s;
    font-weight: 600;
  }
  .footer a:hover {
    text-decoration: none;
    color: ${theme.palette.common.white};
    font-weight: 600
  }
   .footer a:visited {
    text-decoration: none;
    color: ${theme.palette.common.white};
    font-weight: 600
  }

  .footer b{
    color: ${theme.palette.common.white};
  }
`;

ReactDOM.render(<App/>, document.getElementById("root") as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
