import styled from "react-emotion";
interface NavigationListItemProps {
  active?: string;
  className?: string;
  theme?: any;
  children?: any;
  key?: any;
  to?: any;
}
const MobileNavigationListItem = styled("li")(
  (props: NavigationListItemProps) => {
    return {
      padding: `0 ${props.theme.spacing.unit}px`,
      display: "flex",
      alignItems: "center",
      textDecoration: "none"
    };
  }
);

export const LanguageSwitchListItem = styled(MobileNavigationListItem)(
  (props: NavigationListItemProps) => {
    return {
      padding: `${props.theme.spacing.unit}px`,
      cursor: "pointer"
    };
  }
);
export const LanguageSwitchDivider = styled(MobileNavigationListItem)(
  (props: NavigationListItemProps) => {
    return {
      padding: `${props.theme.spacing.unit}px 0`
    };
  }
);
export default MobileNavigationListItem;
