import * as React from "react";
import { Component } from "react";
import HeadlineWithIcon from "../HeadlineWithIcon";
import { Row, Col, Grid } from "react-flexbox-grid";
import styled from "react-emotion";

export interface Props {
  title: string;
  filter?: boolean;
  search?: boolean;
  active?: boolean;
  searchPlaceholder?: any;
  multiSelectContent?: any;
  displayHistory?: boolean;
  moment?: boolean;
}

export interface State {}

const Container = styled("div")((props: any) => ({
  marginTop: props.theme.spacing.unit * 10
}));

export default class DefaultHeader extends Component<Props, State> {
  public render(): JSX.Element {
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Container>
              <HeadlineWithIcon
                filter={this.props.filter}
                search={this.props.search}
                searchPlaceholder={this.props.searchPlaceholder}
                multiSelectContent={this.props.multiSelectContent}
                displayHistory={this.props.displayHistory}
                moment={this.props.moment}
              >
                {this.props.title}
              </HeadlineWithIcon>
            </Container>
          </Col>
        </Row>
      </Grid>
    );
  }
}
