export default class RouteInfo {
  public readonly pageId: string;
  public readonly locale: string;
  public readonly path: string;

  constructor(pageId: string, locale: string, path: string) {
    this.pageId = pageId;
    this.locale = locale;
    this.path = path;
  }
}
