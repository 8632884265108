import React, {FunctionComponent} from "react";
import {HashLink as Link} from "react-router-hash-link";
import styled from "react-emotion";
import Arrow from "./Arrow";

interface TextLinkProps {
  children?: any;
  to: string;
  variant?: "buttonCommon" | "buttonCommonGhost" | "buttonPrimary" | "buttonPrimaryGhost";
}

interface StyleProps {
  theme?: any;
  href?: any;
  fullWidth?: boolean | undefined;
  state?: string;
  variant?: "buttonCommon" | "buttonCommonGhost" | "buttonPrimary" | "buttonPrimaryGhost";
  disabled?: boolean | undefined;
  iconDisabled?: string;
  icon?: string; // must be of type base 64 svg string
}

const StyledLink = styled(Link)((props: StyleProps) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  fontFamily: props.theme.typography.headlineFontFamily,
  fontSize: "1rem",
  fontWeight: "bold",
  lineHeight: 1.5,
  transition: 'opacity 0.5s',
  color: props.theme.palette.common.black,
  ':hover': {
    opacity: 0.6,
  }
}));

const StyledAnchor = styled("a")((props: StyleProps) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  fontFamily: props.theme.typography.headlineFontFamily,
  fontSize: "1rem",
  fontWeight: "bold",
  lineHeight: 1.5,
  transition: 'opacity 0.5s',
  color: props.theme.palette.common.black,
  ':hover': {
    opacity: 0.6,
  }
}));

const StylesTexLink = styled(Link)((props: any) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  fontFamily: props.theme.typography.headlineFontFamily,
  fontSize: "1rem",
  fontWeight: "bold",
  lineHeight: 1.5,
  transition: 'opacity 0.5s',
  color: props.theme.palette.common.black,
  ':hover': {
    opacity: 0.6,
  }
}));

export const SimpleTextLink = (props: any) => (
  <StylesTexLink to={props.to}>{props.children}</StylesTexLink>
);

export const AnchorTextLink: FunctionComponent<TextLinkProps> = props => (
  <StyledAnchor href={props.to} variant={props.variant} target="_blank">
    {props.children}
    <Arrow/>
  </StyledAnchor>
);

export const TextLink: FunctionComponent<TextLinkProps> = props => (
  <StyledLink to={props.to} variant={props.variant}>
    {props.children}
    <Arrow/>
  </StyledLink>
);

export const AnchorButton = styled("a")(
  ({
     theme,
     fullWidth,
     disabled,
     variant = "buttonCommon",
     icon = ""
   }: StyleProps) => {
    return {
      textAlign: "center",
      display: "inline-block",
      width: fullWidth ? "100%" : "auto",
      textDecoration: "none",
      color: disabled ? theme.palette.grey["500"] : theme.palette[variant].color,
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 1.7,
      paddingRight: theme.spacing.unit * 2,
      paddingLeft: icon ? theme.spacing.unit * 5 : theme.spacing.unit * 2,
      fontSize: "1rem",
      fontFamily: theme.typography.fontFamilyCondensedBold,
      fontWeight: "bold",
      letterSpacing: "0.5px",
      borderRadius: theme.spacing.unit / 4,
      // background
      backgroundColor: disabled
        ? theme.palette.common.white
        : theme.palette[variant].background,
      backgroundImage: icon ? `url(data:image/svg+xml;base64,${icon})` : "none",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: theme.spacing.unit * 2,
      backgroundPositionY: theme.spacing.unit,
      // border
      borderWidth: "2px",
      borderStyle: "solid",

      cursor: "pointer",
      borderColor: disabled
        ? theme.palette.grey["500"]
        : theme.palette[variant].border,
      textTransform: "uppercase",
      transition: "background-color 0.5s, color 0.5s, border 0.5s",
      ":hover": {
        color: theme.palette[variant].colorHover,
        backgroundColor: theme.palette[variant].backgroundHover,
        borderColor: theme.palette[variant].borderHover,
      }

    };
  }
);

export const InverseButton = styled(Link)(({theme}: StyleProps) => ({
  textAlign: "center",
  display: "inline-block",
  width: theme.fullWidth ? "100%" : "auto",
  textDecoration: "none",
  fontFamily: theme.typography.fontFamilyCondensedBold,
  fontWeight: "normal",
  letterSpacing: 0.5,
  paddingTop: theme.spacing.unit,
  paddingBottom: theme.spacing.unit,
  paddingRight: theme.spacing.unit * 3,
  paddingLeft: theme.spacing.unit * 3,
  borderWidth: "2px",
  borderStyle: "solid",
  marginTop: "1rem",


  color: theme.palette.buttonCommon.color + "!important",
  backgroundColor: theme.palette.buttonCommon.background,
  borderColor: theme.palette.buttonCommon.color,
  transition: "background-color 0.5s, color 0.5s, border 0.5s",
  ":hover": {
    color: theme.palette.buttonCommon.color + "!important",
    backgroundColor: theme.palette.buttonCommon.backgroundHover,
    borderColor: theme.palette.buttonCommon.color,
  }

  // color: theme.palette.buttonPrimaryGhost.color + "!important",
  // backgroundColor: theme.palette.buttonPrimaryGhost.background,
  // borderColor: theme.palette.buttonPrimaryGhost.border,
  // transition: "background-color 0.5s, color 0.5s, border 0.5s",
  // ":hover": {
  //   color: theme.palette.buttonPrimaryGhost.colorHover + "!important",
  //   backgroundColor: theme.palette.buttonPrimaryGhost.backgroundHover,
  //   borderColor: theme.palette.buttonPrimaryGhost.borderHover,
  // }
}));

export const LinkButton = AnchorButton.withComponent(Link);
export const Button = AnchorButton.withComponent("button");
