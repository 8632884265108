import React from "react";
import styled from "react-emotion";
import {AspectRatio} from "./Billboard";
import IframeBlocker from "./IframeBlocker";

interface Props {
  url: string;
}

interface YoutubeIdObject {
  id: string;
  list: string | never[];
}

const Root = styled("div")({
  position: "relative",
  paddingBottom: AspectRatio.a16to9,
  paddingTop: 24,
  height: 0,
  "& iframe": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }
});

const getId = (url: string = ''): YoutubeIdObject => {
  const isShortUrl: boolean = url.indexOf('https://youtu.be') !== -1;

  let id: any = url.match('v=([a-zA-Z0-9_-]+)&?');
  isShortUrl ?
    id = url.replace('https://youtu.be/', '')
    :
    id = id[1];

  let isList = url.match('list=([a-zA-Z0-9-_]+)&?');
  const list = isList ? isList[1] : [];

  return {id, list};
};

const YoutubeEmbed = ({url}: Props) => {
  const {id, list} = getId(url);

  let ytSrc = `https://www.youtube.com/embed/${id}`;
  if (list.length > 0) {
    ytSrc = `https://www.youtube.com/embed/videoseries?list=${list}`
  }

  return (
    <Root>
      <IframeBlocker type={'youtube'} url={url}>
        <iframe
          src={ytSrc}
          frameBorder="0"
          allowFullScreen={true}
        />
      </IframeBlocker>
    </Root>
  );
};

export default YoutubeEmbed;
