import ArtistContentType from "./ArtistContentType";
import Artist from "../../domain/Artist";
import ArtistTourContentType from "./ArtistTourContentType";
import ArtistTour from "../../domain/ArtistTour";
import ArtistTourEventContentType from "./ArtistTourEventContentType";
import ArtistTourEvent from "../../domain/ArtistTourEvent";
import PageContentType from "./PageContentType";
import Page from "../../domain/Page";
import NavigationContentType from "./NavigationContentType";
import Navigation from "../../domain/Navigation";
import NavigationItem from "../../domain/NavigationItem";
import FooterContentType from "./FooterContentType";
import PageElementColumnContentType from "./PageElementColumnContentType";
import FooterColumn from "../../domain/FooterColumn";
import {Entry} from "contentful";
import FooterData from "../../domain/FooterData";
import News from "../../domain/News";
import NewsContentType from "./NewsContentType";
import PageElementMapper from "./PageElementMapper";
import Header, {HeaderType} from "../../domain/Header";
import HeaderPartContentType from "./HeaderPartContentType";
import HeaderPart from "../../domain/HeaderPart";
import ImageInfo from "../../domain/ImageInfo";
import JobAd from "../../domain/JobAd";
import JobAdContentType from "./JobAdContentType";
import RouteInfo from "../../domain/RouteInfo";
import PathHelper from "../PathHelper";
import moment from "moment";
import LabelConfig from "../../domain/LabelConfig";
import CreationsContentType from "./CreationsContentType";
import Creation from "../../domain/Creation";
import CrewMemberContentType from "./CrewMemberContentType";
import CrewMember from "../../domain/CrewMember";

export default class ContentTypeMapper {
  public static artist(entry: Entry<ArtistContentType>): Artist {
    const fields = entry.fields;


    const tours = !!fields.tours && fields.tours.map(ContentTypeMapper.artistTour);

    // labelConfig will be piped into artist
    const labelConfig: any[] = tours && tours.map(t => {
      const isActiveTour = !!t.end && moment(t.end).isAfter(moment(new Date()).add(-1, "days"));
      return t ? new LabelConfig(t.name, t.tourTyp, t.tourLocation, t.hideTour, isActiveTour) : new LabelConfig('', 'Tournee', '', true, false);
    });

    return new Artist(
      entry.sys.id,
      fields.name,
      fields.slug,
      fields.description,
      fields.formerArtist,
      ContentTypeMapper.isValidImageInfo(fields.profileImage) &&
      ContentTypeMapper.imageInfo(
        fields.profileImage,
        400,
        window.devicePixelRatio
      ),
      fields.forceBookingButton,
      fields.contact,
      fields.headerParts &&
      fields.headerParts.map(part => ContentTypeMapper.headerPart(part)),
      fields.website,
      fields.facebook,
      fields.twitter,
      fields.instagram,
      fields.tiktok,
      fields.spotify,
      fields.youtubeLink,
      fields.vimeoLink,
      fields.tours ? tours : [],
      fields.tours &&
      !!fields.tours.find(
        tour =>
          tour.fields &&
          moment(tour.fields.end).isAfter(moment(new Date()).add(-1, "days"))
      ),
      fields.seoTitle,
      fields.seoDescription,
      fields.seoNoIndex,
      fields.seoFacebookProfileId,
      fields.seoUsername,
      fields.seoFirstName,
      fields.seoLastName,
      fields.seoGender,
      labelConfig
    );
  }

  public static artistTour(entry: Entry<ArtistTourContentType>): ArtistTour {
    const fields = entry.fields;

    if (fields) {
      const tourType = fields.tourTyp || 'none'
      return new ArtistTour(
        entry.sys.id,
        tourType,
        fields.tourLocation,
        fields.hideTour,
        fields.name,
        fields.start,
        fields.end,
        fields.description,
        fields.headerParts &&
        fields.headerParts.map(part => ContentTypeMapper.headerPart(part))
      );
    }
    return new ArtistTour(entry.sys.id, 'Tournee');
  }

  public static artistTourEvent(
    entry: Entry<ArtistTourEventContentType>
  ): ArtistTourEvent {
    const fields = entry.fields;
    const tour = fields.tour;
    return new ArtistTourEvent(
      entry.sys.id,
      tour ? tour.sys.id : undefined,
      tour && tour.fields ? tour.fields.name : undefined,
      tour && tour.fields,
      fields.ticketmasterEvent,
      fields.context,
      fields.city,
      fields.comment,
      fields.venue,
      fields.start,
      fields.end,
      fields.hideTime,
      fields.buttonText,
      fields.regularAvailability,
      fields.regularPrice,
      fields.regularLink,
      fields.vipTicketmasterEvent,
      fields.vipAvailability,
      fields.vipPrice,
      fields.vipLink
    );
  }

  public static page(entry: Entry<PageContentType>): Page {
    const fields = entry.fields;

    return new Page(
      entry.sys.id,
      fields.slug,
      fields.navigationTitle,
      new Header(
        HeaderType[fields.headerType],
        fields.headerParts
          ? fields.headerParts.map(ContentTypeMapper.headerPart)
          : [],
        HeaderType[fields.headerType] === HeaderType.MediumWithHeadlines
          ? fields.elements.map(element => element.fields.title)
          : []
      ),
      fields.title,
      fields.text,
      fields.elements && fields.elements.map(PageElementMapper.pageElement),
      fields.seoTitle,
      fields.seoDescription,
      fields.seoNoIndex,
      fields.invertColors,
      fields.disruptor,
      fields.disruptorUrl
    );
  }

  public static routeInfo(entry: Entry<PageContentType>): RouteInfo {
    return new RouteInfo(
      entry.sys.id,
      entry.sys.locale,
      PathHelper.pathFromSlug(entry.fields.slug, entry.sys.locale)
    );
  }

  public static headerPart(entry: Entry<HeaderPartContentType>): HeaderPart {
    const fields = entry.fields;
    if (fields) {
      return new HeaderPart(
        fields.title,
        ContentTypeMapper.isValidImageInfo(fields.imageWide)
          ? ContentTypeMapper.imageInfo(fields.imageWide, 2160, 1)
          : undefined,
        ContentTypeMapper.isValidImageInfo(fields.imageTablet)
          ? ContentTypeMapper.imageInfo(fields.imageTablet, 1440, 1)
          : undefined,
        ContentTypeMapper.isValidImageInfo(fields.imagePhone)
          ? ContentTypeMapper.imageInfo(fields.imagePhone, 812, 1)
          : undefined,
        fields.youtubeVideoLink,
        fields.artist
      );
    } else {
      return new HeaderPart("");
    }
  }

  public static crewMember(entry: Entry<CrewMemberContentType>): CrewMember {
    const fields = entry.fields;

    return new CrewMember(
      entry.sys.id,
      fields.name,
      fields.position,
      ContentTypeMapper.isValidImageInfo(fields.profileImage) &&
      ContentTypeMapper.imageInfo(
        fields.profileImage,
        400,
        window.devicePixelRatio
      ),
      fields.contact
    );
  }


  public static isValidImageInfo(entry: Entry<any>): boolean {
    return entry && entry.fields && entry.fields.file;
  }

  public static imageInfo(
    entry: Entry<any>,
    maxWidth: number,
    pixelRatio: number
  ): ImageInfo {
    const fields = entry.fields;
    const file = fields.file;
    return new ImageInfo(
      file.contentType,
      file.details.image.width,
      file.details.image.height,
      file.url +
      `?fm=jpg&w=${Math.min(
        4000,
        maxWidth * (pixelRatio || 1)
      )}`,
      fields.title
    );
  }


  public static imageGalleryInfo(
    entry: Entry<any>,
    maxHeight: number,
    maxHeightFactor: number,
    pixelRatio: number
  ): ImageInfo {
    const fields = entry.fields;
    const file = fields.file;
    return new ImageInfo(
      file.contentType,
      file.details.image.width,
      file.details.image.height,
      file.url +
      `?fm=jpg&h=${Math.min(
        maxHeightFactor,
        maxHeight * (pixelRatio || 1)
      )}`,
      fields.title
    );
  }

  public static navigation(entry: Entry<NavigationContentType>): Navigation {
    return new Navigation(
      entry.sys.id,
      entry.fields.items.map(e => {
        const slug = e.fields.slug;
        return new NavigationItem(
          PathHelper.pathFromSlug(slug, entry.sys.locale),
          e.fields.navigationTitle
        );
      })
    );
  }

  public static footerData(entry: Entry<FooterContentType>): FooterData {
    const fields = entry.fields;
    return new FooterData(
      fields.columns.map(ContentTypeMapper.footerColumn),
      fields.facebookLink,
      fields.instagramLink,
      fields.youtubeLink,
      fields.linkedInLink,
      fields.linkedInCreationsLink,
    );
  }

  public static footerColumn(
    entry: Entry<PageElementColumnContentType>
  ): FooterColumn {
    return new FooterColumn(
      entry.sys.id,
      entry.fields.title,
      entry.fields.text,
      entry.fields.actionLink,
      entry.fields.actionLabel
    );
  }

  public static news(entry: Entry<NewsContentType>): News {
    const fields = entry.fields;

    return new News(
      entry.sys.id,
      fields.title,
      new Header(
        HeaderType.Medium,
        fields.headerParts
          ? fields.headerParts.map(ContentTypeMapper.headerPart)
          : []
      ),
      fields.slug,
      fields.abstract,
      fields.text,
      fields.linkInternal && fields.linkInternal.fields.slug,
      fields.linkExternal && fields.linkExternal,
      fields.readNewsLabel,
      fields.seoTitle,
      fields.seoDescription,
      fields.seoNoIndex,
      new Date(entry.sys.createdAt),
      new Date(entry.sys.updatedAt),
      fields.seoAuthors,
      fields.seoPublisher,
      fields.seoSection,
      fields.seoTags
    );
  }

  public static jobAd(entry: Entry<JobAdContentType>): JobAd {
    const fields = entry.fields;
    return new JobAd(
      entry.sys.id,
      fields.title,
      fields.description,
      fields.start,
      fields.city
    );
  }

  public static creation(entry: Entry<CreationsContentType>): Creation {
    const fields = entry.fields;
    const imageList: any[] = fields.imageGallery && fields.imageGallery.map((field: any) => {
      return ContentTypeMapper.isValidImageInfo(field) &&
        {
          thumbnail:
            ContentTypeMapper.imageGalleryInfo(
              field,
              200,
              200,
              window.devicePixelRatio
            ),
          image: ContentTypeMapper.imageGalleryInfo(
            field,
            600,
            4000,
            window.devicePixelRatio
          ),
        }
    });

    return new Creation(
      entry.sys.id,
      fields.name,
      fields.slug,
      fields.introduction,
      fields.description,
      ContentTypeMapper.isValidImageInfo(fields.profileImage) &&
      ContentTypeMapper.imageInfo(
        fields.profileImage,
        400,
        window.devicePixelRatio
      ),
      imageList && imageList || [],
      fields.contact,
      fields.headerParts &&
      fields.headerParts.map(part => ContentTypeMapper.headerPart(part)),
      fields.website,
      fields.videoDescription,
      fields.youtubeLink,
      fields.vimeoLink,

      fields.seoTitle,
      fields.seoDescription,
      fields.seoNoIndex,
      fields.seoFacebookProfileId,
      fields.seoUsername,
      fields.seoFirstName,
      fields.seoLastName,
      fields.seoGender
    );
  }

}
