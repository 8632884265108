import * as React from "react";
// @ts-ignore
import Gallery from 'react-grid-gallery';
import {Component} from "react";

interface Props {
  images: any[]
}


class GridGallery extends Component<Props> {

  constructor(props: Readonly<Props>) {
    super(props);
  }

  public getAspectRatio(width: number, height: number, rowHeight: number) {

    // square
    const obj: any = {
      width: rowHeight,
      height: rowHeight
    };

    if (width > height) {
      // landscape
      obj.width = width / height * rowHeight
      obj.height = rowHeight

    } else if (width < height) {
      // portrait
      obj.width = width / height * rowHeight
      obj.height = rowHeight
    }

    return obj;
  }


  public createImageObjects(images: any[], rowHeight: number): Array<any> {
    const returnValue = images.map((item: any) => {
      const {
        width,
        height
      } = this.getAspectRatio(item.thumbnail.width, item.thumbnail.height, rowHeight);

      return {
        src: item.image.url,
        thumbnail: item.thumbnail.url,
        thumbnailWidth: width,
        thumbnailHeight: height,
        caption: item.image.title,
      }
    })
    return returnValue
  }

  public render(): JSX.Element {
    const props = this.props;

    return (
      <div style={{
        display: "block",
        minHeight: "1px",
        width: "100%",
        overflow: "auto"
      }}>
        <Gallery
          images={this.createImageObjects(props.images, 200)}
          lightboxWidth={1536}
          enableImageSelection={false}
          rowHeight={200}
          margin={10}
        />
      </div>
    );
  }
}

export default GridGallery;
