import Artist from "../../domain/Artist";

import ArtistGridTile from "./ArtistGridTile";
import * as React from "react";
import {Row, Col} from "react-flexbox-grid";
import styled from "react-emotion";
import ServiceRegistry from "src/services/ServiceRegistry";
import StaticLabelService from "../../services/StaticLabelService";

export interface Props {
  character: string;
  artists: Artist[];
}

interface GridSectionInterface {
  theme?: any;
}

const GridSection = styled("section")((props: GridSectionInterface) => ({
  marginTop: props.theme.spacing.unit * 4
}));

const SectionHeader = styled("h2")((props: GridSectionInterface) => ({
  fontSize: props.theme.typography.fontSize * 3,
  fontWeight: 700,
  fontFamily: `Roboto, "Helvetica", "Roboto", "Arial", sans-serif`,
  lineHeight: 1.2,
  margin: 0
}));

const ArtistGridSection = (props: Props) => {
  let {artists} = props;
  const {character} = props;

  if (!artists) {
    artists = [];
  }

  // sort artist name alphabetically
  artists.sort((a, b) => {
    if (!a.name) {
      return -1;
    }
    if (!b.name) {
      return 1;
    }
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    } else {
      return 1;
    }
  });

  const locale = ServiceRegistry.getContentService().getLocale();
  const prefixes = {
    // @todo this is very dirty and duplicated in EventItemInterface
    de: "/artists/",
    en: "/en/artists/"
  };

  const anchorName = `${character}`;

  return (
    <GridSection>
      <Row>
        <Col xs={12}>
          <SectionHeader id={anchorName}>{character}</SectionHeader>
        </Col>
      </Row>
      <Row>
          {artists.map(artist => (
            <Col xs={12} md={4} key={artist.id}>
              <ArtistGridTile
                artist={artist}
                link={`${prefixes[locale]}${artist.slug}`}
                labelComponent={StaticLabelService.getTourLabel(artist)}
              />
            </Col>
          ))}
      </Row>
    </GridSection>
  );
};

export default ArtistGridSection;
