export default class ImageInfo {
  public readonly mimeType: string;
  public readonly width: number;
  public readonly height: number;
  public readonly url: string;
  public readonly title: string;

  constructor(
    mimeType: string,
    width: number,
    height: number,
    url: string,
    title: string
  ) {
    this.mimeType = mimeType;
    this.width = width;
    this.height = height;
    this.url = url;
    this.title = title;
  }
}
