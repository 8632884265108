import styled from 'react-emotion';
import React from 'react';

const FirstLine = styled("hr")({
    height: "3px",
    backgroundColor: "black",
    width: "30px",
    margin: "0",
});

const SecondLine = styled("hr")({
    height: "3px",
    backgroundColor: "black",
    width: "20px",
    margin: "5px 0",
});

const ThirdLine = styled("hr")({
    height: "3px",
    backgroundColor: "black",
    width: "8px",
    margin: "0",
});

const IconWrapper = styled("div")({
    width: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 0 5px 10px",
});

const FilterIcon = () => {
    return (
        <IconWrapper>
            <FirstLine/>
            <SecondLine/>
            <ThirdLine/>
        </IconWrapper>
    );
};

export default FilterIcon;

