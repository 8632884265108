//@ts-nocheck
import React, { Component } from "react";
import styled from "react-emotion";
import Helmet from "react-helmet";
import SubHeadlineWithIcon from "./../components/HeadlineWithIcon";
import { Spacer } from "./../components/Spacer";
import { RouteComponentProps } from "react-router-dom";
import { Grid, Row, Col } from "react-flexbox-grid";

interface Props extends RouteComponentProps<{}> {}

const Wrapper = styled(Grid)({
  marginTop: 50,
  marginBottom: 75,
  "@media (min-width: 576px)": {
    marginTop: 75,
    marginBottom: 100
  },
  "@media (min-width: 992px)": {
    marginTop: 100,
    marginBottom: 100
  }
});

const ContactCard = styled("div")({
  color: "#fff",
  backgroundColor: "#000",
  padding: 20,
  display: "flex",
  flexDirection: "column",
  margin: "30px 0 50px",
  maxWidth: 400
});

const ContactInfos = styled("p")({
  marginBlockStart: "0",
  marginBlockEnd: "0",
  marginTop: "0.2em",
  marginBottom: "0.2em"
});

const ContactTitle = styled("p")({
  marginBlockStart: "0",
  marginBlockEnd: "0",
  marginTop: "0.3em",
  fontSize: "12px",
  marginBottom: "1.5em"
});

class CorporateNewsletter extends Component<Props> {
  public componentDidMount() {
    !(function(e, t, n, c, r, a, i) {
      (e.Newsletter2GoTrackingObject = r),
        (e[r] =
          e[r] ||
          function() {
            (e[r].q = e[r].q || []).push(arguments);
          }),
        (e[r].l = 1 * new Date()),
        (a = t.createElement(n)),
        (i = t.getElementsByTagName(n)[0]),
        (a.async = 1),
        (a.src = c),
        i.parentNode.insertBefore(a, i);
    })(
      window,
      document,
      "script",
      "https://static.newsletter2go.com/utils.js",
      "n2g"
    );
    const config = {
      container: { type: "div", class: "", style: "" },
      row: { type: "div", class: "", style: "margin-top: 15px;" },
      columnLeft: { type: "div", class: "", style: "" },
      columnRight: { type: "div", class: "", style: "" },
      label: { type: "label", class: "", style: "" }
    };
    n2g("create", "dejn10c3-t5uozkkz-riv");
    n2g("subscribe:createForm", config);
  }

  public render() {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <Wrapper>
          <Spacer>
            <SubHeadlineWithIcon>Firmenkunden-Anmeldung</SubHeadlineWithIcon>{" "}
          </Spacer>
          <Row>
            <Col xs={12} lg={6}>
              <h2>JETZT LIVE DABEI SEIN</h2>
              <h3>– hier geht’s zu Ihrem Gästelistenplatz!</h3>
              <p>
                In regelmäßigen Mailings informieren wir Sie über kommende
                Konzerte und Tourneen unserer Künstler, bestehend aus
                vielversprechenden Newcomern und angesagten Weltstars!
              </p>
              <p>
                Wählen Sie gerne ein Konzert in Ihrer Nähe aus, melden Sie sich
                an und seien Sie unser Gast!
              </p>
              <p>
                Einfach für unseren <b>exklusiven</b> CORPORATE NEWSLETTER
                eintragen und live dabei sein.
              </p>
              <p>Ich freue mich auf Sie!</p>
              <p>Ihre</p>
              <p>Tina Kugler</p>
              <ContactCard>
                <span>
                  <b>TINA KUGLER</b>
                </span>
                <ContactTitle>Manager Brand & Special Events</ContactTitle>
                <ContactInfos>Neuland Concerts GmbH</ContactInfos>
                <ContactInfos>Schulterblatt 58 / Maschinenhalle</ContactInfos>
                <ContactInfos>20357 Hamburg</ContactInfos>
                <ContactInfos>Phone: +49 40 189 87 67 17</ContactInfos>
                <ContactInfos>tina.kugler@neuland-concerts.com</ContactInfos>
              </ContactCard>
            </Col>
            <Col xs={12} lg={6}>
              <div id="n2g_script" className={"newsletter-n2g"} />
            </Col>
          </Row>
        </Wrapper>
      </>
    );
  }
}

export default CorporateNewsletter;
