import React from "react";
import { Route } from "react-router";
import styled from "react-emotion";
import { Link } from "react-router-dom";
import NavigationListItem from "./NavigationListItem";

interface StyledLinkInterface {
  theme?: any;
  active?: any;
}

const StyledLink = styled(Link)((props: StyledLinkInterface) => ({
  textDecoration: "none",
  color: props.theme.palette.common.black,
  borderBottom: `2px solid ${props.active === "true" ? props.theme.palette.common.black : "transparent"}`
}));

interface NavLinkInterface {
  "aria-current"?: string;
  className?: object;
  exact?: boolean;
  isActive?: any;
  location?: any;
  strict?: boolean;
  style?: string;
  to?: any;
  onClick?: () => {};
}

/**
 * A <Link> wrapper that knows if it's "active" or not.
 */
function NavLink({
  "aria-current": ariaCurrent = "page",
  className: classNameProp,
  exact,
  isActive: isActiveProp,
  location,
  strict,
  // style: styleProp,
  to,
  onClick,
  ...rest
}: NavLinkInterface) {
  const path = typeof to === "object" ? to.pathname : to;

  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");
  return (
    <Route
      path={escapedPath}
      exact={exact}
      strict={strict}
      location={location}
      children={({ location, match }) => {
        const isCurrentlyActive = !!(isActiveProp
          ? isActiveProp(match, location)
          : match);
        return (
          <NavigationListItem
            active={String(isCurrentlyActive)}
            onClick={onClick}
          >
            <StyledLink active={String(isCurrentlyActive)} to={to} {...rest} />
          </NavigationListItem>
        );
      }}
    />
  );
}

export default NavLink;
