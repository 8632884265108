import * as React from "react";
import { getContext } from "../getContext";
import Artist from "src/domain/Artist";
import ArtistTour from "src/domain/ArtistTour";
import ArtistTourEvent from "src/domain/ArtistTourEvent";
import moment from "moment";
import ServiceRegistry from "src/services/ServiceRegistry";
import MonthListEvents from "src/components/MonthListEvents";
import EventLoader from "./../components/EventLoader";

export interface MonthListEventsContainerProps {
  data: any;
  selectedArtist: Artist | undefined;
}

export interface MonthListEventsContainerState {
  currentTours?: ArtistTour[];
  tourNameMapping?: Map<string, Artist>;
  events: ArtistTourEvent[];
  isLoading: boolean;
  artists: Artist[];
  listOffset: number;
  listOffsetStep: number;
  allLoaded: boolean;
}

class MonthListEventsContainer extends React.Component<
  MonthListEventsContainerProps,
  MonthListEventsContainerState
> {
  constructor(props: MonthListEventsContainerProps) {
    super(props);

    this.state = {
      events: [],
      artists: [],
      isLoading: true,
      listOffset: 0,
      listOffsetStep: 20,
      allLoaded: false
    };
  }

  public componentDidMount() {
    ServiceRegistry.getContentService()
      .getUpcomingEvents()
      .then((events) => this.setState({ events }));
  }

  public componentDidUpdate(prevProps: MonthListEventsContainerProps) {
    if (this.props.selectedArtist !== prevProps.selectedArtist) {
      ServiceRegistry.getContentService()
        .getUpcomingEvents()
        .then((events) => this.setState({ events }));
    }
  }

  public loadMore = () => {
    const { listOffset, listOffsetStep } = this.state;

    ServiceRegistry.getContentService()
      .getUpcomingEvents(50, listOffset + listOffsetStep)
      .then((events) =>
        this.setState({ events, listOffset: listOffset + listOffsetStep })
      );
  };

  public reloadEvents = () => {
    const stateEvents = this.state.events;
    if (this.state.events === []) {
      return;
    } else {
      const loadedEvents = this.state.events.length;
      const toLoadEvents = 20;

      ServiceRegistry.getContentService()
        .getUpcomingEvents(toLoadEvents, loadedEvents)
        .then((items) => {
          this.setState({
            events: [...stateEvents, ...items]
          });
          if (items.length === toLoadEvents) {
            this.setState({
              allLoaded: false
            });
          } else {
            this.setState({
              events: [...stateEvents, ...items],
              allLoaded: true
            });
          }
        });
    }
  };

  public render() {
    const { selectedArtist, data } = this.props;

    if (!this.state.isLoading) {
      return <div />;
    }
    const events: ArtistTourEvent[] = !!data.data.filteredEvents.length
      ? data.data.filteredEvents
      : !!data.data.activeFilter
      ? data.data.events
      : this.state.events;

    const tourNameMapping =
      this.state.tourNameMapping || new Map<string, Artist>();

    let mappedTours: any;
    mappedTours = this.getMappedEvents(events);

    let selectedTours: any = [];
    let filteredEvents: any = [];
    let filteredArtists: any = [];

    const onTourArtists = this.state.artists.filter(
      (artist) => artist.isOnTour
    );

    // Artist filter
    if (selectedArtist !== undefined) {
      //@ts-ignore
      selectedTours = selectedArtist.tours.map((tour) => tour.id);
      //show events of the selected artist
      filteredEvents = events.filter((event) =>
        selectedTours.includes(event.tourId)
      );
      //show the tile of the selected Artist

      filteredArtists = onTourArtists.filter((artist) =>
        artist.name.includes(selectedArtist.name)
      );

      mappedTours = this.getMappedEvents(filteredEvents);
    } else {
      filteredEvents = events;
      filteredArtists = onTourArtists;
    }

    // search filter
    if (this.props.data.data.searchValue !== "") {
      // show artists that have events in searched city
      //@ts-ignore
      const filteredEventsId = filteredEvents.map((event) => event.tourId);

      const filteredSearchArtists = onTourArtists.filter((artist) =>
        artist.tours.some((tour) => filteredEventsId.includes(tour.id))
      );

      filteredArtists = filteredSearchArtists;

      if (selectedArtist !== undefined) {
        //show events of the selected artist
        filteredEvents = events.filter((event) =>
          selectedTours.includes(event.tourId)
        );

        filteredArtists = onTourArtists.filter((artist) =>
          artist.name.includes(selectedArtist.name)
        );
      }
    } else {
      filteredEvents = events;
    }

    const mappedMonths = this.getMappedEventsMonths(filteredEvents);
    let months: string[];
    const allMonths = Array.from(mappedMonths.keys());

    let monthEventsForArtist: any = [];
    // show list of events of selected month
    if (data.data.activeFilter !== null) {
      months = allMonths.filter((month) => month === data.data.activeFilter);

      monthEventsForArtist = mappedMonths.get(data.data.activeFilter);

      if (monthEventsForArtist) {
        //@ts-ignore
        const MonthEventsIds = monthEventsForArtist.map(
          (event: any) => event.tourId
        );
        // Artists für gefilterte Tiles nach Monat
        let filteredSearchArtists;
        filteredSearchArtists = onTourArtists.filter((artist) =>
          artist.tours.some((tour) =>
            MonthEventsIds.some((event: any) => event === tour.id)
          )
        );

        if (selectedArtist !== undefined) {
          filteredSearchArtists = onTourArtists.filter(
            (artist) =>
              artist.name.includes(selectedArtist.name) &&
              artist.tours.some((tour) =>
                MonthEventsIds.some((event: any) => event === tour.id)
              )
          );
        }
        filteredArtists = filteredSearchArtists;
      }
    } else {
      months = Array.from(mappedMonths.keys());
    }
    const map = new Map();
    const res: Artist[] = [];

    if (this.state.currentTours) {
      this.state.currentTours.forEach((current: ArtistTour) => {
        const artist = tourNameMapping.get(current.id);
        if (artist && !map.has(artist.id)) {
          map.set(artist.id, true);

          const tourArtist = filteredArtists.find(
            //@ts-ignore
            (filtered) => filtered.id === artist.id
          );

          // @ts-ignore
          res.push(tourArtist);
        }
      });
    }

    return (
      <>
        {months.map((month) => {
          let monthEvents;
          monthEvents = mappedMonths.get(month);
          if (this.props.selectedArtist !== undefined) {
            //@ts-ignore
            const filteredMonthEvents = monthEvents.filter((event) =>
              selectedTours.includes(event.tourId)
            );
            monthEvents = filteredMonthEvents;
          }
          //@ts-ignore
          if (!monthEvents.length) {
            return null;
          }
          return (
            <MonthListEvents
              title={month}
              events={monthEvents}
              key={month}
              mappedTours={mappedTours}
              tourNameMapping={this.props.data.data.tourNameMapping}
              selectedArtist={this.props.selectedArtist}
            />
          );
        })}
        {!this.state.allLoaded &&
          (this.props.selectedArtist === null &&
            (this.props.data.data.activeFilter === null &&
              (this.props.data.data.searchValue === "" && (
                <EventLoader handleChange={this.reloadEvents} />
              ))))}
      </>
    );
  }

  private getMappedEvents(events: ArtistTourEvent[]) {
    const eventsCategorized = new Map<string, ArtistTourEvent[]>();
    const eventDates: string[] = events.map((event) =>
      moment(event.start).format("YYYY-MM-DD")
    );
    const eventDatesUnique: string[] = Array.from(new Set(eventDates));
    eventDatesUnique.map((date) => {
      const eventsByDay = events.filter(
        (event) => moment(event.start).format("YYYY-MM-DD") === date
      );
      eventsCategorized.set(date, eventsByDay);
    });
    return eventsCategorized;
  }

  private getMappedEventsMonths(events: ArtistTourEvent[]) {
    const eventsByMonth = new Map<string, ArtistTourEvent[]>();
    const eventMonths: string[] = events.map((event) =>
      moment(event.start).format("YYYY-MM")
    );
    const eventMonthsUnique: string[] = Array.from(new Set(eventMonths));

    eventMonthsUnique.map((month) => {
      const monthEvents = events.filter(
        (event) => moment(event.start).format("YYYY-MM") === month
      );
      eventsByMonth.set(month, monthEvents);
    });
    return eventsByMonth;
  }
}

export default getContext(MonthListEventsContainer);
