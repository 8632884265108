import React from "react";
import styled from "react-emotion";

import { Paragraph } from "./Typography";

const Container = styled("div")(props => ({
  margin: `${props.theme.spacing.unit}px 0`
}));

const SubLine = styled(Paragraph)(props => ({
  fontSize: props.theme.typography.body1
}));

const ImageCaption = (params: any) => (
  <Container>
    <Paragraph bold={true}>{params.name}</Paragraph>
    <SubLine>{params.description}</SubLine>
  </Container>
);

export default ImageCaption;
