import React from "react";

const filter = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icons/filter</title>
    <desc>Created with Sketch.</desc>
    <g
      id="icons/filter"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="filter" transform="translate(2.000000, 0.000000)" fill="#000000">
        <path
          d="M12.6332495,11.8684211 L12.6332495,21.8221053 C12.6837759,22.2010526 12.5574601,22.6052632 12.2669338,22.8705263 C11.7743022,23.3631579 10.9785127,23.3631579 10.4858811,22.8705263 L7.94693375,20.3315789 C7.65640743,20.0410526 7.53009164,19.6494737 7.58061796,19.2831579 L7.58061796,11.8684211 L7.54272322,11.8684211 L0.26693375,2.54631579 C-0.162539934,2.00315789 -0.0614873024,1.20736842 0.481670592,0.777894737 C0.721670592,0.601052632 0.98693375,0.5 1.26482849,0.5 L18.949039,0.5 C19.2269338,0.5 19.4921969,0.601052632 19.7321969,0.777894737 C20.2753548,1.20736842 20.3764074,2.00315789 19.9469338,2.54631579 L12.6711443,11.8684211 L12.6332495,11.8684211 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

export default filter;
