import News from "../domain/News";
import {Component} from "react";
import * as React from "react";
import {SubHeadline} from "./Typography";
import RichText from "./RichText";
import styled from "react-emotion";
import {TextLink, AnchorTextLink} from "./Button";
import ServiceRegistry from "src/services/ServiceRegistry";
import {EqualHeightElement} from "react-equal-height";

export interface Props {
  news: News;
  rowCount: string;
}

export interface State {
}

const Root = styled("div")(({theme}: any) => {
  const {xs} = theme.palette.breakpoints.definitions;
  return {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingBottom: theme.spacing.unit * 2,
    [xs]: {
      paddingBottom: theme.spacing.unit * 4
    }
  };
});


const SubHeadlineWrapper = styled("div")(({theme}: any) => {
  return {
    marginBottom: "1rem",
    letterSpacing: theme.typography.letterSpacing,
  };
});

export default class NewsWidgetItem extends Component<Props, State> {
  public getNewsLink(news: News) {
    const {linkInternal, linkExternal} = news;

    const locale = ServiceRegistry.getContentService().getLocale();
    const prefixes = {
      // @todo this is very dirty and duplicated in EventItemInterface
      de: "artists/",
      en: "en/artists/"
    };
    const linkLocale =
      ServiceRegistry.getContentService().getLocale() === "en" ? "en/" : "";
    if (linkInternal) {
      return linkInternal === "neuland-concerts/Jobs"
        ? `${linkLocale}${linkInternal}`
        : `${prefixes[locale]}${linkInternal}`;
    } else if (linkExternal) {
      return linkExternal;
    } else {
      return this.props.news.slug;
    }
  }

  public render(): JSX.Element {
    const {news, rowCount} = this.props;
    return (
      <Root>
        <EqualHeightElement name={"headline-" + rowCount}>
          <SubHeadlineWrapper>
            <SubHeadline>{news.title}</SubHeadline>
          </SubHeadlineWrapper>
        </EqualHeightElement>
        <EqualHeightElement name={"copy-" + rowCount}>
          <RichText text={news.abstract}/>
          {news.linkInternal ? (
            <TextLink to={`/${this.getNewsLink(news)}`}>
              {news.readNewsLabel ? news.readNewsLabel : "Weiterlesen"}
            </TextLink>
          ) : (
            <AnchorTextLink to={this.getNewsLink(news)}>
              {news.readNewsLabel ? news.readNewsLabel : "Weiterlesen"}
            </AnchorTextLink>
          )}
        </EqualHeightElement>
      </Root>
    );
  }
}
