import React from "react";

const mailContact = (
  <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <g>
      <title>Layer 1</title>
      <g stroke="null" fillRule="evenodd" fill="none" strokeWidth="1" id="icons/mail">
        <g stroke="null" fillRule="nonzero" fill="#ffffff" id="emailContact">
          <path stroke="null" id="Shape" d="m10.5333,3.73335l-4.5333,2.83331l-4.5333,-2.83331l0,-1.13333l4.5333,2.83331l4.5333,-2.83331l0,1.13333zm0,-2.26665l-9.0666,0c-0.629,0 -1.13333,0.50433 -1.13333,1.13333l0,6.79995c0,0.62592 0.50741,1.13333 1.13333,1.13333l9.0666,0c0.62592,0 1.13333,-0.50741 1.13333,-1.13333l0,-6.79995c0,-0.629 -0.51,-1.13333 -1.13333,-1.13333z"/>
        </g>
      </g>
    </g>
  </svg>
);

export default mailContact;
