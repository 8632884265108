import {Component} from "react";
import * as React from "react";
import Hyperlink from "../../domain/Hyperlink";
import {Row, Col} from "react-flexbox-grid";

import {SectionHeadline} from "src/components/Typography";
import {Section} from "src/components/Section";
import ServiceRegistry from "./../../services/ServiceRegistry";
import Creation from "../../domain/Creation";
import CreationsGridTile from "./CreationsGridTile";

export interface Props {
  title: string;
  creations: Creation[];
  showMore: Hyperlink;
}

export interface State {
}

export default class FeaturedCreations extends Component<Props, State> {
  public render(): JSX.Element {
    const {title, creations} = this.props;

    const locale = ServiceRegistry.getContentService().getLocale();
    const prefixes = {
      // @todo this is very dirty and duplicated in EventItemInterface
      de: "/creations/",
      en: "/en/creations/"
    };

    let columnCount: number = 4;

    switch (creations.length) {
      case 3:
      case 5:
      case 6:
        columnCount = 4;
        break;
      default:
        columnCount = 3;
    }

    return (
      <Section>
        <SectionHeadline>{title}</SectionHeadline>
        <Row>
          {creations && creations.map((creation, index) => {
              return (
                <Col xs={12} md={columnCount} key={index}>
                  <CreationsGridTile creation={creation}
                                     link={`${prefixes[locale]}${creation.slug}`}
                  />
                </Col>
              )
            }
          )}
        </Row>
      </Section>
    );
  }
}
