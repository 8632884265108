import styled from "react-emotion";
import React, { Component } from "react";
import Moment from "react-moment";
import ServiceRegistry from "./../../services/ServiceRegistry";

import FilterTagIcon from "./filterTagIcon";

interface Props {
  theme?: any;
  children?: any;
  title: any;
  handleChange: any;
  filters?: {
    filterDropdownActive: boolean;
    activeFilter: any;
    historyActive: boolean;
    countFilter: number;
  };
  moment?: boolean;
}

const TagWrapper = styled("div")({
  height: "auto",
  width: "auto",
  fontFamily: "Roboto",
  fontSize: "20px",
  lineHeight: "20px",
  backgroundColor: "#eeeeee",
  display: "flex",
  padding: "5px 0 5px 8px",
  letterSpacing: "-1px",
  margin: "4px 10px 0 0",
  "@media(min-width: 767px)": {
    margin: "0 14px 0 0"
  }
});

export default class FilterTag extends Component<Props> {
  constructor(props: any) {
    super(props);
  }

  public resetFilter = () => {
    this.props.handleChange({
      activeFilter: null,
      historyActive: false,
      countFilter: 0,
      passedFilter: null
    });
  };

  public render(): JSX.Element {
    const locale = ServiceRegistry.getContentService().getLocale();

    return (
      <TagWrapper>
        {this.props.moment && (
          <Moment locale={locale} format={"MMMM YYYY"}>
            {this.props.title}
          </Moment>
        )}
        {!this.props.moment && <>{this.props.title}</>}
        <FilterTagIcon onClick={this.resetFilter} />
      </TagWrapper>
    );
  }
}
