import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import News from "../domain/News";
import ServiceRegistry from "../services/ServiceRegistry";
import RichText from "../components/RichText";
import MediumHeader from "../components/header/MediumHeader";
import DefaultHeader from "../components/header/DefaultHeader";
import PrerenderingHelper from "protofy-prerender";
import SeoTags from "../components/SeoTags";
import { Grid, Row, Col } from "react-flexbox-grid";

interface Params {
  slug: string;
}

export interface Props extends RouteComponentProps<Params> {
  locale: string;
  render404Redirect: () => JSX.Element;
}

export interface State {
  notFound?: boolean;
  news?: News;
}

export default class NewsDetailsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public componentDidMount(): void {
    PrerenderingHelper.waitFor(this);
    this.loadNewsFromSlug(this.props.match.params.slug);
  }

  public componentDidUpdate(prevProps: Props): void {
    const currentSlug = this.props.match.params.slug;
    if (currentSlug !== prevProps.match.params.slug) {
      this.loadNewsFromSlug(currentSlug);
    }
    if (this.state.news) {
      PrerenderingHelper.onNextFrameDone(this);
    }
  }

  public render(): JSX.Element {
    const { news, notFound } = this.state;

    if (news) {
      return (
        <div>
          <SeoTags
            location={this.props.location}
            title={news.seoTitle || news.title}
            desc={news.seoDescription}
            noIndex={news.seoNoIndex}
            news={news}
            header={news.header}
          />

          {news.header.parts && news.header.parts.length > 0 ? (
            <MediumHeader title={news.title} parts={news.header.parts} />
          ) : (
            <DefaultHeader title={news.title} />
          )}
          <Grid>
            <Row>
              <Col xs={true}>
                <RichText text={news.text} />
              </Col>
            </Row>
          </Grid>
        </div>
      );
    } else if (notFound) {
      return this.props.render404Redirect();
    } else {
      return <div />;
    }
  }

  private loadNewsFromSlug(slug: string): void {
    ServiceRegistry.getContentService()
      .getNewsBySlug(slug)
      .then((news) => {
        this.setState({ news });
      })
      .catch((e) => {
        console.log(e.sys || e);
        this.setState({ notFound: true });
      });
  }
}
