export default class FooterData {
  public readonly columns: any[];
  public readonly facebookLink?: string;
  public readonly instagramLink?: string;
  public readonly youtubeLink?: string;
  public readonly linkedInLink?: string;
  public readonly linkedInCreationsLink?: string;

  constructor(
    columns: any[],
    facebookLink: string | undefined,
    instagramLink: string | undefined,
    youtubeLink: string | undefined,
    linkedInLink: string | undefined,
    linkedInCreationsLink: string | undefined
  ) {
    this.columns = columns;
    this.facebookLink = facebookLink;
    this.instagramLink = instagramLink;
    this.youtubeLink = youtubeLink;
    this.linkedInLink = linkedInLink;
    this.linkedInCreationsLink = linkedInCreationsLink;
  }
}
