import React, { Component } from "react";
import ReactDOM from "react-dom";
import Moment from "react-moment";

import ArtistTourEvent from "src/domain/ArtistTourEvent";
import UpcomingEventsWidgetItem from "./UpcomingEventsWidgetItem";
import moment from "moment";
import { Spacer } from "./Spacer";
import SubHeadlineWithIcon from "./HeadlineWithIcon";
import ServiceRegistry from "../services/ServiceRegistry";

export interface MonthListEventProps {
  title: string;
  events?: ArtistTourEvent[] | boolean;
  tourNameMapping: any;
  mappedTours: Map<string, ArtistTourEvent[]>;
  selectedArtist: any;
}

interface State {
  yPosition: number;
  listActive: boolean;
}

class MonthListEvents extends Component<MonthListEventProps, State> {
  constructor(props: MonthListEventProps) {
    super(props);
    this.state = {
      yPosition: 0,
      listActive: false
    };
  }

  public getMappedEvents = (events: ArtistTourEvent[]) => {
    const eventsCategorized = new Map<string, ArtistTourEvent[]>();
    if (!Boolean(events)) {
      return eventsCategorized;
    }
    const eventDates: string[] = events.map(event =>
      moment(event.start).format("YYYY-MM-DD")
    );
    const eventDatesUnique: string[] = Array.from(new Set(eventDates));
    eventDatesUnique.map(date => {
      const eventsByDay = events.filter(
        event => moment(event.start).format("YYYY-MM-DD") === date
      );
      eventsCategorized.set(date, eventsByDay);
    });
    return eventsCategorized;
  };

  public componentDidMount() {
    //@ts-ignore
    window.addEventListener("scroll", this.updatePosition);
    this.updatePosition();
  }

  public componentWillUnmount() {
    //@ts-ignore
    window.removeEventListener("scroll", this.updatePosition);
  }

  public updatePosition = () => {
    const position = window.scrollY;
    const rect = ReactDOM.findDOMNode(this);
    this.setState({ yPosition: position });
    //@ts-ignore
    const offset = rect.offsetTop - 900;

    if (window.scrollY === 0 && offset <= window.innerHeight) {
      this.setState({
        listActive: true
      });
    } else if (window.scrollY >= offset) {
      this.setState({
        listActive: true
      });
    } else {
      return;
    }
  };

  public render() {
    const { mappedTours, title, events, tourNameMapping } = this.props;
    const { listActive } = this.state;
    const days = Array.from(mappedTours.keys());
    //@ts-ignore
    const mappedEvents = this.getMappedEvents(events);
    const locale = ServiceRegistry.getContentService().getLocale();

    //@ts-ignore
    if (!this.props.events.length) {
      return <div />;
    } else {
      return (
        <div>
          <Spacer>
            <SubHeadlineWithIcon>
              <Moment locale={locale} format="MMMM YYYY">
                {title}
              </Moment>
            </SubHeadlineWithIcon>
          </Spacer>
          {days.map((day, dayIndex) => {
            const dayEvents = mappedEvents.get(day);
            if (!dayEvents) {
              return;
            }
            return (
              <Spacer large={true} key={dayIndex}>
                {dayEvents.map((event: any, eventIndex: any) => (
                  <UpcomingEventsWidgetItem
                    key={event.id}
                    event={event}
                    eventArtist={tourNameMapping.get(event.tourId)}
                    renderDate={eventIndex === 0}
                    renderCTA={true}
                    active={listActive}
                  />
                ))}
              </Spacer>
            );
          })}
        </div>
      );
    }
  }
}

export default MonthListEvents;
