import styled from "react-emotion";
const NavigationList = styled("ul")((props: any) => ({
  listStyle: "none",
  // padding: `${props.theme.spacing.unit * 2}px 0`,
  margin: 0,
  padding: 0,
  display: "flex",
  height: "100%",
  alignItems: "stretch",
  justifyContent: "center"
}));
export default NavigationList;
