import React from "react";
import styled from "react-emotion";

interface BillboardLabelInterface {
  theme?: any;
}

const BillboardLabel = styled("h2")(({theme}: BillboardLabelInterface) => ({
  fontSize: theme.typography.fontSize * 2,
  fontFamily: theme.typography.headlineFontFamily,
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: theme.typography.letterSpacing,
  margin: 0,
  color: theme.palette.common.white,
  textTransform: "uppercase",

  display: "flex",
  flexFlow: "column",

  "@media only screen and (min-width: 991px)": {
    fontSize: "calc(100% + 2.1vw)",
    flexFlow: "row",
  },

  "& span": {
    padding: `
    ${theme.spacing.unit * 0.8}px
    ${theme.spacing.unit}px
    1px
    ${theme.spacing.unit}px
    `,
    whiteSpace: "nowrap",
    marginTop: `-${theme.spacing.unit}px`,
    width: "fit-content",
    backgroundColor: theme.palette.common.black,
    display: "inline-block",

    "@media only screen and (min-width: 992px)": {
      "&:first-of-type": {
        paddingLeft: "1rem"
      },
      "&:last-of-type": {
        paddingRight: "1rem"
      }
    }
  }
}));

const BillboardLabelComponent = (props: any) => {

  const {children} = props;
  let delimiter = ' '
  if (children.indexOf('.') !== -1) {
    delimiter = '.';
  }
  const items = children.split(delimiter);
  return (
    <BillboardLabel>
      {items.map((text: string, index: number) => {
          const suffix = index < items.length - 1
            ?
            <span key={index}>{text.trim() + delimiter}</span>
            :
            null

          return suffix
        }
      )}
    </BillboardLabel>
  )
}


export default BillboardLabelComponent;
