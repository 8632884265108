import React, {Component} from "react";
import ArtistTourEvent from "../domain/ArtistTourEvent";
import Artist from "../domain/Artist";
import {Row, Col} from "react-flexbox-grid";
import StaticTextService from "../services/StaticTextService";
import ServiceRegistry from "../services/ServiceRegistry";
import {AnchorButton} from "./Button";

import {DateItem} from "./DateItem";
import {EventItem} from "./EventItem";
import styled, {css} from "react-emotion";
import vipIcon from "./icons/svg/vip";

interface ITheme {
  theme?: any;
}

const Container = styled("div")(({active}: any) => ({
  opacity: 1,
  height: "auto",
  transition: "height 200ms ease-in, opacity 200ms ease-in"
}));

const ListRow = styled(Row)(({theme}: ITheme) => ({
  marginBottom: theme.spacing.unit * 2
}));

const ListAnchorButton = styled(AnchorButton)(({theme}: ITheme) => {
  const {xs, sm} = theme.palette.breakpoints.definitions;
  return {
    [xs]: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit
    },
    [sm]: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit
    }
  };
});

export interface Props {
  event: ArtistTourEvent;
  eventArtist?: Artist;
  renderDate: boolean;
  renderCTA: boolean;
  active: any;
}

export interface State {
  isVisible: boolean;
}

const marginLeft = css({marginLeft: "8px"});

class UpcomingEventsWidgetItem extends Component<Props, State> {
  public element: any;

  constructor(props: Props) {
    super(props);
    this.element = React.createRef();
    this.state = {
      isVisible: false
    };
  }

  public componentDidMount() {
    window.addEventListener("scroll", this.isVisible);
    this.isVisible();
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll", this.isVisible);
  }

  public isVisible = (): any => {
    const offset: number = 200;

    if (!this.element.current) {
      {
        return false;
      }
    }
    const top = this.element.current.getBoundingClientRect().top;

    if (top + offset >= 0 && top - offset <= window.innerHeight) {
      this.setState({isVisible: true});
    }
  };

  public render() {
    const {renderDate, event, eventArtist, renderCTA, active} = this.props;
    const date = event.start;

    const locale = ServiceRegistry.getContentService().getLocale();
    return (
      <Container active={active}>
        <ListRow>
          <Col xs={6} lg={2}>
            {renderDate && <DateItem date={date} locale={locale}/>}
          </Col>

          <Col xs={6} lg={7} style={{borderLeft: "1px solid grey"}}>
            <div className={marginLeft}>
              <EventItem date={date} event={event} artist={eventArtist}/>
            </div>
          </Col>

          {renderCTA && (
            <Col xs={12} lg={3} style={{alignSelf: "flex-end"}}>
              {renderRegularButton(event)}

              {event.vipPrice && (

                  <ListAnchorButton fullWidth={true}
                                    variant={"buttonPrimaryGhost"}
                                    icon={vipIcon}>
                    {event.vipPrice}
                  </ListAnchorButton>
              )}
            </Col>
          )}
        </ListRow>
      </Container>
    );
  }
}

const renderRegularButton = (event: ArtistTourEvent) => {
  if (event.buttonLabel) {
    return (
      <ListAnchorButton fullWidth={true} variant={"buttonPrimaryGhost"}
                        href={event.regularLink}>
        {event.buttonLabel}
      </ListAnchorButton>
    );
  }
  if (event.regularPrice) {
    return (
      <ListAnchorButton fullWidth={true} variant={"buttonPrimary"}
                        href={event.regularLink}>
        {StaticTextService.getTicketsFromText()} {event.regularPrice}
      </ListAnchorButton>
    );
  }

  return (
    <ListAnchorButton fullWidth={true} variant={"buttonCommon"} disabled={true}>
      {StaticTextService.getSoldOutText()}
    </ListAnchorButton>
  );
};

export default UpcomingEventsWidgetItem;
