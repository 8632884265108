import React from "react";
import styled from "react-emotion";

interface BillboardHeadlineInterface {
  theme?: any;
}

const BillboardHeadline = styled("h1")(
  ({theme}: BillboardHeadlineInterface) => ({
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.headlineFontFamily,
    display: "inline",
    margin: "0 auto 0 auto",
    color: theme.palette.common.white,
    marginTop: theme.spacing.unit * 2,
    backgroundColor: theme.palette.common.black,
    boxDecorationBreak: "clone",
    "& span": {
      padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit *
      0.5}px ${theme.spacing.unit * 0.25}px ${theme.spacing.unit * 0.5}px`,
      backgroundColor: theme.palette.common.black,
      display: "inline-block"
    }
  })
);

const BillboardHeadlineComponent = (props: any) => {
  const {children} = props;
  let delimiter = ' '
  if (children.indexOf('.') !== -1) {
    delimiter = '.';
  }
  const items = children.split(delimiter);
  return (
    <BillboardHeadline>
      {items.map((text: string, index: number) => {
          const suffix = index < items.length - 1
            ?
            <span key={index}>{text.trim() + delimiter}</span>
            :
            null
          return suffix
        }
      )}
    </BillboardHeadline>
  )
};

export default BillboardHeadlineComponent;

export const BillboardSubheadline = styled("h2")(({theme}: any) => ({
  fontSize: theme.typography.fontSize * 1.625,
  fontFamily: theme.typography.headlineFontFamily,
  fontWeight: "normal",
  display: "inline",
  lineHeight:1.4,
  margin: 0,
  color: theme.palette.common.white,
  padding: `${theme.spacing.unit}px`,
  backgroundColor: theme.palette.common.black,
  textTransform: "uppercase",
  boxDecorationBreak: "clone",
  "@media only screen and (min-width: 992px)": {
    lineHeight:1,
    fontSize: theme.typography.fontSize * 2
  },
  "@media only screen and (min-width: 1280px)": {
    fontSize: theme.typography.fontSize * 2.5
  },
  "& span": {
    display: "inline-block",
    verticalAlign: "middle",
    lineHeight: 1
  }
}));
