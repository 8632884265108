import React, {Component} from "react";
import {Row, Col, Grid} from "react-flexbox-grid";
import LanguageSwitch from "./LanguageSwitch";
import Navigation from "./Navigation/Navigation";
import {Link} from "react-router-dom";

// @ts-ignore
import * as logo from "../assets/images/logo.png";
// @ts-ignore
import * as logo2x from "../assets/images/logo@2x.png";
// @ts-ignore
import * as burgerIcon from "./Navigation/assets/icons/burger.svg";
// @ts-ignore
import * as burgerIconWhite from "./Navigation/assets/icons/burgerWhite.svg";
// @ts-ignore
import * as logoWhite from "../assets/images/neuland-creations.png";
// @ts-ignore
import * as logoWhite2x from "../assets/images/neuland-creations@2x.png";

import MobileNavigation from "./Navigation/MobileNavigation";

interface Props {
  isDarkTheme: boolean,
  locales: string[],
  selected: string,
  onChange: any
}

interface State {
  mobileNavigationActive: boolean;
}

class AppBar extends Component<Props, State> {
  public state = {mobileNavigationActive: false};

  constructor(props: Readonly<Props>) {
    super(props);
    this.toggleMobileNavigation = this.toggleMobileNavigation.bind(this);
  }

  public toggleMobileNavigation() {
    this.setState({
      mobileNavigationActive: !this.state.mobileNavigationActive
    });
  }

  public render() {
    const props = this.props;
    const {isDarkTheme, locales, selected, onChange} = props;
    const themedLogo = isDarkTheme ? logoWhite : logo;
    const themedLogo2X = isDarkTheme ? logoWhite2x : logo2x;
    const themedBurger = isDarkTheme ? burgerIconWhite : burgerIcon;

    return (
      <>
        <MobileNavigation
          isDarkTheme={isDarkTheme}
          active={this.state.mobileNavigationActive}
          onItemClick={this.toggleMobileNavigation}
        />
        <Grid>
          {/* desktop */}
          <Row
            className="hidden-xs hidden-sm hidden-md"
            style={{height: "100%"}}
          >
            <Col lg={2}>
              <Link to="/">

                <img
                  style={{
                    margin: "1.3rem 0 1rem"
                  }}
                  src={themedLogo}
                  srcSet={`${themedLogo} 1x, ${themedLogo2X} 2x`}
                  alt="Neuland Concerts"
                  width={'auto'}
                  height={'60px'}
                />
              </Link>
            </Col>
            <Col lg={8}>
              <Row center="xs" style={{height: "100%"}}>
                <Col xs={true}>
                  <Navigation/>
                </Col>
              </Row>
            </Col>
            <Col lg={2}>
              <Row end="xs" style={{height: "100%"}}>
                <Col xs={true}>
                  <LanguageSwitch
                    locales={locales}
                    selected={selected}
                    onChange={onChange}
                    style={{justifyContent: "flex-end"}}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          {/* mobile */}
          <Row className="hidden-lg hidden-xl">
            <Col xs={6}>
              <Link to="/">
                <img
                  src={themedLogo}
                  srcSet={`${themedLogo} 1x, ${themedLogo2X} 2x`}
                  alt="Neuland Concerts"
                  width={this.props.isDarkTheme ? '200px' : '165px'}
                  height={'68px'}
                />
              </Link>
            </Col>
            <Col xs={4} sm={5}>
              <Row end="xs" style={{height: "100%", alignItems: "center"}}>
                <Col xs={true}>
                  <LanguageSwitch
                    style={{justifyContent: "flex-end"}}
                    locales={locales}
                    selected={selected}
                    onChange={onChange}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={2} sm={1}>
              <Row end="xs" style={{height: "100%", alignItems: "center"}}>
                <Col xs={true} onClick={this.toggleMobileNavigation}>
                  <img src={themedBurger} width={'32px'} height={'32px'}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </>
    );
  }
}

export default AppBar;
