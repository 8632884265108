import React from "react";

const InstagramBoxed = () => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="footer"
        transform="translate(-388.000000, -376.000000)"
        fill="#FFFFFF"
      >
        <g
          id="instagram-svgrepo-com"
          transform="translate(388.000000, 376.000000)"
        >
          <path
            d="M23.5089109,17.409901 C22.7168317,16.3009901 21.4178218,15.5722772 19.960396,15.5722772 C18.5029703,15.5722772 17.2039604,16.3009901 16.4118812,17.409901 C15.9049505,18.1386139 15.5881188,19.0257426 15.5881188,19.9762376 C15.5881188,22.3841584 17.5524752,24.3485149 19.960396,24.3485149 C22.3683168,24.3485149 24.3326733,22.3841584 24.3326733,19.9762376 C24.3326733,19.0257426 24.0158416,18.1386139 23.5089109,17.409901 Z"
            id="Path"
          />
          <path
            d="M26.2653465,17.4415842 C26.5821782,18.0752475 26.7722772,19.0891089 26.7722772,19.9762376 C26.7722772,23.7148515 23.7306931,26.7881188 19.960396,26.7881188 C16.190099,26.7881188 13.1485149,23.7465347 13.1485149,19.9762376 C13.1485149,19.0574257 13.3386139,18.0752475 13.6554455,17.4415842 L9.82178218,17.4415842 L9.82178218,27.6118812 C9.82178218,28.9425743 10.9940594,30.1148515 12.3247525,30.1148515 L27.5960396,30.1148515 C28.9267327,30.1148515 30.0990099,28.9425743 30.0990099,27.6118812 L30.0990099,17.4415842 L26.2653465,17.4415842 Z"
            id="Path"
          />
          <polygon
            id="Path"
            points="28.9584158 10.4079208 25.2831683 10.4079208 25.2831683 14.6217822 29.4653465 14.6217822 29.4653465 10.9465347 29.4653465 10.3762376"
          />
          <path
            d="M29.9089109,0.0158415842 L10.0118812,0.0158415842 C4.4990099,0.0158415842 0,4.51485149 0,10.0277228 L0,29.9564356 C0,35.4376238 4.4990099,39.9366337 10.0118812,39.9366337 L29.9405941,39.9366337 C35.4534653,39.9366337 39.9524752,35.4376238 39.9524752,29.9247525 L39.9524752,10.0277228 C39.9207921,4.51485149 35.4217822,0.0158415842 29.9089109,0.0158415842 Z M32.3168317,17.409901 L32.3168317,27.6118812 C32.3168317,30.2732673 30.2574257,32.3326733 27.5960396,32.3326733 L12.3247525,32.3326733 C9.66336634,32.3326733 7.6039604,30.2732673 7.6039604,27.6118812 L7.6039604,17.409901 L7.6039604,12.3405941 C7.6039604,9.67920792 9.66336634,7.61980198 12.3247525,7.61980198 L27.5960396,7.61980198 C30.2574257,7.61980198 32.3168317,9.67920792 32.3168317,12.3405941 L32.3168317,17.409901 Z"
            id="Shape"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default InstagramBoxed;
