import React from "react";
import Moment from "react-moment";
import {Col, Row} from "react-flexbox-grid";
import {FlexRow} from "./FlexRow";
import ArtistTourEvent from "src/domain/ArtistTourEvent";
import ServiceRegistry from "../services/ServiceRegistry";
import {LightText} from "./LightText";
import styled from "react-emotion";

export interface EventItemInterface {
  date: string;
  event: ArtistTourEvent;
  artist?: any;
  style?: any;
}

const Artist = styled("div")(({theme}: any) => ({
  fontSize: "2rem",
  fontFamily: theme.typography.fontFamilyCondensedBold
}));

export const EventItem = ({date, event, artist}: EventItemInterface) => {
  const locale = ServiceRegistry.getContentService().getLocale();
  const prefixes = {
    // @todo this is very dirty and duplicated in ArtistGridTile
    de: "/artists/",
    en: "/en/artists/"
  };

  return (
    <Row>
      <Col xs={true}>
        {artist && (
          <FlexRow>
            <Col xs={true}>
              {/* artist */}
              <Artist><a href={`${prefixes[locale]}${artist.slug}`} style={{
                textDecoration: "none",
                color: "#000"
              }}>{artist.name}</a></Artist>
            </Col>
          </FlexRow>
        )}
        <FlexRow>
          <Col xs={true}>
            {/* context */}
            {event.context && <strong>{event.context}</strong>}
            {event.context && <strong> · </strong>}
            {/* city */}
            <strong> {event.city}</strong>
            {/* comment */}
            {event.comment && <strong> · </strong>}
            {event.comment && <strong> {event.comment}</strong>}
          </Col>
        </FlexRow>
        <FlexRow>
          <Col xs={true}>
            {event.venue && (
              <>
                <LightText>{event.venue}</LightText>
                {!event.hideTime && <strong> · </strong>}
              </>
            )}
            {!event.hideTime && <LightText>
              <Moment locale="de" format="HH:mm">
                {date}
              </Moment>
            </LightText>
            }
          </Col>
        </FlexRow>
      </Col>
    </Row>
  );
};
