import * as React from "react";
import { Component } from "react";
import styled from "react-emotion";
import StaticTextService from "./../../services/StaticTextService";
import DropdownMultiSelectItem from "./multiSelectItem";

interface Props {
  active: boolean;
  handleChange: any;
  filters: {
    filterDropdownActive: boolean;
    activeFilter: any;
    historyActive: boolean;
    countFilter: number;
    passedFilter: any;
  };
  data: any;
  multiSelectContent?: any;
  displayHistory?: boolean;
  moment?: boolean;
}

const DropdownWrapper = styled("div")(({ active = false }: any) => {
  return {
    position: "absolute",
    minWidth: "200px",
    display: active ? "block" : "none",
    right: "0",
    marginTop: 5,
    backgroundColor: "white",
    border: "1px solid #757575",
    padding: "0px 20px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    zIndex: 100,
    maxHeight: 470,
    overflowY: "scroll",
    "@media(min-width: 767px)": { marginTop: 15, borderTop: "none" },
    "@media(min-width: 1147px)": { marginTop: 14 },
    "@media(min-width: 1250px)": { marginTop: 15 }
  };
});

const DropdownList = styled("ul")({
  listStyle: "none",
  textAlign: "right",
  fontSize: "20px",
  fontFamily: "Roboto",
  paddingInlineStart: 0,
  WebkitPaddingStart: 0,
  paddingLeft: 0
});

const DropdownListItem = styled("li")(({ active = false }: any) => {
  return {
    padding: "10px 10px",
    borderBottom: "1px solid #757575",
    backgroundColor: `${active ? "#eeeeee" : "#ffffff"}`,
    fontFamily: "Roboto",
    userSelect: "none",
    cursor: "pointer",

    ":last-of-type": {
      border: "none",
      paddingBottom: "0"
    },

    ":first-of-type": {
      paddingTop: "10px"
    }
  };
});

export default class FilterDropdown extends Component<Props> {
  constructor(props: any) {
    super(props);
  }

  public toggleHistorySelected = (ev: { preventDefault: () => void }) => {
    ev.preventDefault();
    if (this.props.filters.historyActive === false) {
      this.setState({ passedFilter: null });
      this.props.handleChange({
        activeFilter: null,
        historyActive: true,
        countFilter: 1,
        passedFilter: null
      });
    } else {
      this.setState({ passedFilter: null });
      this.props.handleChange({
        activeFilter: null,
        historyActive: false,
        countFilter: 0,
        passedFilter: null
      });
    }
  };

  public resetFilter = () => {
    this.setState({ passedFilter: null });
    this.props.handleChange({
      activeFilter: null,
      historyActive: false,
      countFilter: 0,
      passedFilter: null
    });
  };

  public stateSet = (content: any) => {
    if (this.props.filters.passedFilter === null) {
      this.setState({ passedFilter: content });
      this.props.handleChange({
        activeFilter: content,
        historyActive: false,
        countFilter: 1,
        passedFilter: content
      });
    } else if (this.props.filters.passedFilter === content) {
      this.setState({ passedFilter: null });
      this.props.handleChange({
        activeFilter: null,
        historyActive: false,
        countFilter: 0,
        passedFilter: null
      });
    } else if (
      content !== this.props.filters.passedFilter &&
      this.props.filters.passedFilter !== null
    ) {
      this.setState({ passedFilter: content });
      this.props.handleChange({
        activeFilter: content,
        historyActive: false,
        countFilter: 1,
        passedFilter: content
      });
    }
  };

  public render(): JSX.Element {
    return (
      <DropdownWrapper active={this.props.active}>
        <DropdownList>
          {/*
            //@ts-ignore*/}
          {this.props.multiSelectContent.map(content => {
            return (
              <DropdownMultiSelectItem
                key={content}
                title={content}
                onClick={this.stateSet}
                active={this.props.filters.passedFilter === content}
                moment={this.props.moment}
              />
            );
          })}
          {this.props.displayHistory && (
            <DropdownListItem
              active={this.props.filters.historyActive}
              onClick={this.toggleHistorySelected}
            >
              {StaticTextService.getHistoryFilter()}
            </DropdownListItem>
          )}
          <DropdownListItem onClick={this.resetFilter}>
            {StaticTextService.getDeleteFilter()}
          </DropdownListItem>
        </DropdownList>
      </DropdownWrapper>
    );
  }
}
