import { Location } from 'history';

export default class PathHelper {
  public static pathFromSlug(slug: string, locale: string): string {
    if (!slug || slug === '' || slug === '/') {
      return (locale === 'de') ? '/' : `/${locale}`;
    }

    const prefix = (locale === 'de') ? '' : `/${locale}`;
    const suffix = slug.startsWith('/') ? slug : `/${slug}`;

    return prefix + suffix;
  }

  public static canonicalPath(location: Location): string {
    return PathHelper.canonicalPathOfPath(location.pathname);
  }

  public static canonicalPathOfPath(path: string): string {
    let p = path;
    if(path[path.length - 1] === "/") {
      p = path.substring(0, path.length - 1);
    }
    return "https://neuland-concerts.com" + p;
  }
}
