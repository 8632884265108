import React, { Component } from "react";
import { Context } from "./FilterContext";

export const getContext = (Comp: any) =>
  class UseContext extends Component {
    public render() {
      return (
        <Context.Consumer>
          {data => <Comp {...this.props} data={data} />}
        </Context.Consumer>
      );
    }
  };
