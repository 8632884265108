import styled from "react-emotion";

interface PageWrapperInterface {
  theme?: any;
}

export const PageWrapper = styled("div")(({theme}: PageWrapperInterface) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.default
}));
