import React, {Component} from "react";
import ServiceRegistry from "./services/ServiceRegistry";
import moment from "moment";
import ArtistTourEvent from "./domain/ArtistTourEvent";

interface ContextInterface {
  data: {
    activeFilter: string | null;
    historyActive: boolean;
    countFilter: number;
    searchValue: string;
    passedFilter: any;
  };
  handleChange: (
    activeFilter: any,
    historyActive: any,
    countFilter: any,
    passedFilter: any
  ) => void;
  handleSearch: (searchValue: any) => void;
  handleSearchByCity: (searchValue: any) => void;
}

interface State {
  isFiltered: boolean;
  activeFilter: any;
  historyActive: boolean;
  countFilter: number;
  searchValue: string;
  passedFilter: any;
  events: ArtistTourEvent[];
  tourNameMapping: any;
  currentTours?: any;
  artists?: any;
  filteredEvents?: ArtistTourEvent[];
}

export const Context = React.createContext<ContextInterface | null>(null);

export default class FilterContext extends Component<ContextInterface, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      activeFilter: null,
      historyActive: false,
      countFilter: 0,
      searchValue: "",
      passedFilter: null,
      events: [],
      tourNameMapping: undefined,
      filteredEvents: [],
      isFiltered: false
    };
    this.init();
  }

  public init() {
    this.getUpcomingEvents();
    this.getNameTourMapping();
  }

  public getUpcomingEvents = () => {
    ServiceRegistry.getContentService()
      .getUpcomingEvents()
      .then(events => this.setState({events}));
  };

  public getNameTourMapping = () => {
    ServiceRegistry.getContentService()
      .getArtistTourNameMapping()
      .then(tourNameMapping => this.setState({tourNameMapping}));
  };

  public getUpcomingEventsByCity = ({searchValue}: {
    searchValue: string;
  }) => {
    const value = searchValue.charAt(0).toUpperCase() + searchValue.slice(1);
    const filteredEvents = this.state.events.filter(event => {
        if (event && event.city && event.city.toLowerCase().includes(value.toLowerCase())) {
          return event
        }
        return null
      }
    );

    if (searchValue.length >= 3) {
      setTimeout(
        () =>
          this.setState({
            filteredEvents,
            searchValue: value,
            isFiltered: true
          }),
        300
      );
    } else if (searchValue.length === 0) {
      setTimeout(
        () =>
          this.setState({
            searchValue,
            filteredEvents: [],
            isFiltered: false
          }),
        300
      );
    }

    // ServiceRegistry.getContentService()
    //   .getUpcomingEventsByCity(searchValue)
    //   .then(res => console.log(res));
  };

  public handleStateChange = (
    activeFilter: any,
    historyActive: any,
    countFilter: any,
    passedFilter: any
  ) => {
    this.setState({
      activeFilter,
      historyActive,
      countFilter,
      passedFilter
    });
  };

  public handleSearchChange = (searchValue: any) => {
    if (searchValue.length === 0) {
      this.setState({
        searchValue,
        filteredEvents: [],
        isFiltered: false
      });
    } else {
      this.setState({
        searchValue
      });
    }
  };

  public getToursBySearchValue = (searchValue: string) => {
    ServiceRegistry.getContentService()
      .getTours()
      .then((tours: any) => {
        const currentTours = tours.filter((tour: any) =>
          moment(tour.end).isAfter(moment(new Date()).add(-1, "days"))
        );

        this.setState({currentTours});
      });
  };

  /**
   * getAllArtist
   */
  public getAllArtist() {
    ServiceRegistry.getContentService()
      .getAllArtists()
      .then(artists => this.setState({artists}));
  }

  public render() {
    const contextValue = {
      data: this.state,
      handleChange: this.handleStateChange,
      handleSearch: this.handleSearchChange,
      handleSearchByCity: this.getUpcomingEventsByCity
    };
    return (
      <Context.Provider value={contextValue}>
        {this.props.children}
      </Context.Provider>
    );
  }
}
