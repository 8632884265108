import React, {SFC} from "react";

import AspectRatioImage from "./AspectRatioImage";
// @ts-ignore
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";
import {Row, Col} from "react-flexbox-grid";
import styled from "react-emotion";
import {hiddenXs} from "src/constants/flexBoxGrid";
import Copyright from "./Copyright";

export interface Props {
  title: string;
  text?: any;
  personName: string;
  personDescription?: string;
  image: any;
}

export interface StyleProps {
  theme?: any;
}

const ImageContainer = styled("div")((props: StyleProps) => {
  return {
    padding: props.theme.spacing.unit * 4
  };
});

const ImageWrapper = styled("div")((props: StyleProps) => {
  return {
    position: "relative"
  };
});

const Person = styled("p")((props: StyleProps) => ({
  ...props.theme.typography.citationCaption,
  paddingLeft: props.theme.spacing.unit * 5
}));

const CitationText = styled("p")((props: StyleProps) => ({
  ...props.theme.typography.citationText,
  paddingLeft: props.theme.spacing.unit * 5,
  marginBottom: props.theme.spacing.unit * 2,
  position: "relative",
  fontWeight: "bold"

}));

const CitationString = styled("blockquote")((props: StyleProps) => ({
  ...props.theme.typography.citation,
  marginBottom: props.theme.spacing.unit * 2,
  position: "relative",
  ":before": {
    content: '"\\201E"',
    position: "absolute",
    left: "-1rem"
  },
  ":after": {
    content: '"\\201D"'
  }
}));

const Citation: SFC<Props> = ({
                                title,
                                text,
                                personName,
                                personDescription,
                                image
                              }) => {
  let html;
  try {
    html = documentToHtmlString(text, {});
  } catch (err) {
    console.warn("Cannot render rich text:", err);
  }
  return (
    <Row>
      <Col xs={12} md={5} className={hiddenXs}>
        <ImageContainer>
          <ImageWrapper>
            <AspectRatioImage url={image.url} title={personName || ""}/>
            <Copyright title={image.title}/>
          </ImageWrapper>
        </ImageContainer>
      </Col>
      <Col xs={12} md={7}>
        <CitationString>{title}</CitationString>
        <CitationText dangerouslySetInnerHTML={{
          __html: html.replace(/\n/g, "<br/>")
        }}/>
        <Person>
          {personName}
          <br/>
          {personDescription}
        </Person>
      </Col>
    </Row>
  )
};

export default Citation;
