import * as React from "react";
import {Component} from "react";
import HeaderPart from "../../domain/HeaderPart";
import {
  BillboardContainer as Billboard,
  BillboardHeadline,
  BillboardLabel,
  AspectRatio
} from "../Billboard";
// @ts-ignore
import SwipeableViews from "react-swipeable-views";
import {Grid, Row, Col} from "react-flexbox-grid";
import styled from "react-emotion";

const Container = styled("div")(({theme}: any) => ({
  height: "100%",
  display: "flex",
  alignItems: "flex-end",
  [theme.palette.breakpoints.definitions.md]: {
    alignItems: "center"
  },
  [theme.palette.breakpoints.definitions.sm]: {
    alignItems: "center"
  },
  [theme.palette.breakpoints.definitions.xs]: {
    alignItems: "center"
  }
}));

export interface Props {
  parts: HeaderPart[];
  title: string;
}

export interface State {
}

export default class LargeHeader extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    const {parts, title} = this.props;
    return (
      <div>
        {parts && parts.length > 0 && (
          <SwipeableViews
            axis={"x"}
            index={0}
            style={{maxHeight: 1080, margin: "0 auto", overflowY: "hidden"}}
          >
            {parts.filter((part, key) => part.imageWide).map((part, key) => (
              <Billboard
                key={key}
                aspect={AspectRatio.LandscapeSmall}
                imgSrc={part}
              >
                <Container>
                  <Grid>
                    <Row>
                      <Col
                        xs={12}
                        style={{
                          // padding: 0 // to keep the alignment
                          paddingBottom: "5%"
                        }}
                      >
                        <div style={{marginBottom: "1rem"}}>
                          <BillboardLabel>{part.title}</BillboardLabel>
                        </div>
                        <div>
                          <BillboardHeadline>{title}</BillboardHeadline>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                </Container>
              </Billboard>
            ))}
          </SwipeableViews>
        )}
      </div>
    );
  }
}
