import React, {Component} from "react";
import styled from "react-emotion";

import StaticTextService from "../../services/StaticTextService";

import {
  TileContainer,
  TileContent,
  TileHeadLine
} from "../../components/Tile/Tile";

const ResetHeadline = styled(TileHeadLine)({
  fontSize: 26,
  marginLeft: "0"
});

interface Props {
  onClick?: any;
}

interface TileLabelInterface {
  theme?: any;
}

const TileLabelWrapper = styled("div")(({ theme }: TileLabelInterface ) => ({
  marginLeft:'-6px',
  position: "relative",
  ":before": {
    content: '""',
    position: "absolute",
    top: "6px",
    left: 0,
    backgroundColor: theme.palette.primary.main,
    width: "6px",
    height: "100%",
    transform: "skewY(20deg)",
    zIndex: 0,
  },
}));


class FilterResetTile extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <TileContainer to={"#"} onClick={this.props.onClick}>
        <TileContent>
          <TileLabelWrapper>
            <ResetHeadline>{StaticTextService.getAllArtists()}</ResetHeadline>
          </TileLabelWrapper>
        </TileContent>
      </TileContainer>
    );
  }
}

export default FilterResetTile;
