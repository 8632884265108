import * as React from "react";
import {Component} from "react";
import Artist from "../../domain/Artist";
import {
  TileHeadLine,
  TileContainer,
  TileContent
} from "../../components/Tile/Tile";
import styled from "react-emotion";
import Copyright from "../../components/Copyright";

export interface Props {
  artist: Artist | any;
  labelComponent?: any;
  link?: any;
  selectArtist?: any;
  onClick?: any;
  style?: any;
}

interface TileLabelInterface {
  theme?: any;
}

const TileLabelWrapper = styled("div")(({theme}: TileLabelInterface) => ({
  marginLeft: '-6px',
  position: "relative",
  ":before": {
    content: '""',
    position: "absolute",
    top: "4px",
    left: 0,
    backgroundColor: theme.palette.primary.main,
    width: "6px",
    height: "100%",
    transform: "skewY(20deg)",
    zIndex: 0,
  },
}));

class ArtistGridTile extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    const {artist} = this.props;

    if (!artist) {
      //@ts-ignore
      return null;
    }

    return (
      <TileContainer
        to={this.props.link}
        id={artist.id}
        data-background-image-url={
          artist.profileImage && artist.profileImage.url
        }
        // imgsrc={artist.profileImage && artist.profileImage.url}
        title={artist.name}
        onClick={this.handleSelectArtist}
      >
        {this.props.labelComponent}

        <TileContent>
          <TileLabelWrapper>
            <TileHeadLine
              style={this.props.style}>{artist.name}</TileHeadLine>
          </TileLabelWrapper>
        </TileContent>
        <Copyright title={artist.profileImage.title}/>
      </TileContainer>
    );
  }

  public handleSelectArtist = (artist: any): void => {
    if (this.props.selectArtist) {
      this.props.selectArtist(this.props.artist);
    }
  };
}

export default ArtistGridTile;
