import {Component} from "react";
import ServiceRegistry from "../services/ServiceRegistry";
import ArtistTourEvent from "../domain/ArtistTourEvent";
import Artist from "../domain/Artist";
import * as React from "react";
import styled from "react-emotion";
import moment from "moment";
import {BackgroundLazyLoader} from "../functions/lazyLoad.js";
import ArtistGridTile from "./ArtistList/ArtistGridTile";
import {Row, Col} from "react-flexbox-grid";
import SubHeadlineWithIcon from "./../components/HeadlineWithIcon";
import {Spacer} from "./../components/Spacer";
import StaticTextService from "./../services/StaticTextService";
import ArtistTour from "src/domain/ArtistTour";
import FilterResetTile from "../components/Tile/FilterResetTile";
import {getContext} from "./../getContext";
import MonthListEventsContainer from "./MonthListEventsContainer";
import EventLoader from "./../components/EventLoader";
import PrerenderingHelper from "protofy-prerender";
import {
  isBrowser,
  isMobile
} from 'react-device-detect';

export interface Props {
  title: string;
  data: any;
}

export interface State {
  events: ArtistTourEvent[];
  tourNameMapping?: Map<string, Artist>;
  artists: Artist[];
  currentTours: any;
  tourArtists: any;
  selectedArtist: Artist | undefined;
  isLoading: boolean;
  allLoaded: boolean;
}

const Container = styled("div")((props: any) => ({
  padding: `${props.theme.spacing.unit * 6}px 0`
}));

const StyledRow = styled(Row)({
  paddingTop: 80,
  "@media(min-width: 767px)": {paddingTop: 0}
});

class UpcomingEventsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      events: [],
      artists: [],
      currentTours: [],
      tourArtists: [],
      selectedArtist: undefined,
      isLoading: true,
      allLoaded: false
    };
  }

  public componentDidMount(): void {
    PrerenderingHelper.waitFor(this);
    if (isBrowser) {
      ServiceRegistry.getContentService()
        .getUpcomingEvents(50, 0)
        .then((events) => this.setState({events}));
      ServiceRegistry.getContentService()
        .getAllArtists()
        .then((artists) => this.setState({artists, isLoading: false}));
    }

    if (isMobile) {
      ServiceRegistry.getContentService()
        .getUpcomingEvents(50, 0)
        .then((events) => this.setState({events, isLoading: false}));
    }

    ServiceRegistry.getContentService()
      .getArtistTourNameMapping()
      .then((tourNameMapping) => this.setState({tourNameMapping}));
    ServiceRegistry.getContentService()
      .getTours()
      .then((tours: any) => {
        const currentTours = tours.filter((tour: any) =>
          moment(tour.end).isAfter(moment(new Date()).add(-1, "days"))
        );

        this.setState({currentTours});
      });
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.state.events && this.state.tourNameMapping) {
      PrerenderingHelper.onNextFrameDone(this);
    }

    // if (prevState.isLoading !== this.state.isLoading) {
    //   console.log("endFilter", prevState.isLoading, this.state.isLoading);
    //   this.setState({isLoading: false})
    // }
    BackgroundLazyLoader();
  }

  public componentWillUnmount(): void {
    console.log("PrerenderingHelper");
  }

  public render(): JSX.Element {
    const {selectedArtist, isLoading, artists, currentTours} = this.state;
    const {data} = this.props;

    if (isLoading) {
      return (
        <Container>
          <EventLoader/>
        </Container>
      );
    }

    const events: ArtistTourEvent[] = !!data.data.filteredEvents.length
      ? data.data.filteredEvents
      : !data.data.isFiltered
        ? this.state.events
        : [];

    const tourNameMapping =
      this.state.tourNameMapping || new Map<string, Artist>();

    let selectedTours: any = [];
    let filteredEvents: ArtistTourEvent[] = [];
    let filteredArtists: any = [];
    const onTourArtists = artists.filter((artist) => artist.isOnTour && !artist.formerArtist);
    // Artist filter
    if (selectedArtist !== undefined) {
      //@ts-ignore
      selectedTours = selectedArtist.tours.map((tour) => tour.id);
      //show events of the selected artist
      filteredEvents = events.filter((event) =>
        selectedTours.includes(event.tourId)
      );
      //show the tile of the selected Artist
      filteredArtists = onTourArtists.filter((artist) =>
        artist.name.includes(selectedArtist.name)
      );
    } else {
      filteredEvents = events;
      filteredArtists = onTourArtists.filter((artist) => (!artist.formerArtist));
    }

    // search filter
    if (data.data.searchValue !== "" || !!data.data.filteredEvents.length) {
      // show artists that have events in searched city
      //@ts-ignore
      const filteredEventsId = filteredEvents.map((event) => event.tourId);

      const filteredSearchArtists = onTourArtists.filter((artist) =>
        artist.tours.some((tour) => filteredEventsId.includes(tour.id))
      );
      filteredArtists = filteredSearchArtists;

      if (selectedArtist !== undefined) {
        //show events of the selected artist
        filteredEvents = events.filter((event) =>
          selectedTours.includes(event.tourId)
        );

        filteredArtists = onTourArtists.filter((artist) =>
          artist.name.includes(selectedArtist.name)
        );
      }
    } else {
      filteredEvents = filteredEvents;
      filteredArtists = filteredArtists;
    }

    const mappedMonths = this.getMappedEventsMonths(data.data.events);

    const allMonths = Array.from(mappedMonths.keys());

    let monthEventsForArtist: any = [];
    // show list of events of selected month
    if (data.data.activeFilter !== null) {
      monthEventsForArtist = mappedMonths.get(data.data.activeFilter);
      if (!!monthEventsForArtist.length) {
        //@ts-ignore
        const MonthEventsIds = monthEventsForArtist.map(
          (event: any) => event.tourId
        );
        // Artists für gefilterte Tiles nach Monat
        let filteredSearchArtists;
        filteredSearchArtists = onTourArtists.filter((artist) =>
          artist.tours.some((tour) =>
            MonthEventsIds.some((event: any) => event === tour.id)
          )
        );

        if (selectedArtist !== undefined) {
          filteredSearchArtists = onTourArtists.filter(
            (artist) =>
              artist.name.includes(selectedArtist.name) &&
              artist.tours.some((tour) =>
                MonthEventsIds.some((event: any) => event === tour.id)
              )
          );
        }

        if (data.data.searchValue !== "") {
          const filteredEventsId = filteredEvents.map(
            (event: any) => event.tourId
          );

          filteredSearchArtists = onTourArtists
            .filter((artist) =>
              artist.tours.some(
                (tour) =>
                  filteredEventsId.includes(tour.id) &&
                  MonthEventsIds.some((event: any) => event === tour.id)
              )
            )
            .map((event: any) => event.tourId);
        }
        filteredArtists = filteredSearchArtists;
      }
    }

    const map = new Map();
    const res: Artist[] = [];
    currentTours.forEach((current: ArtistTour) => {
      const artist = tourNameMapping.get(current.id);
      if (artist && !map.has(artist.id)) {
        map.set(artist.id, true);
        const tourArtist = filteredArtists.find(
          //@ts-ignore
          (filtered) => filtered.id === artist.id
        );
        // @ts-ignore
        res.push(tourArtist);
      }
    });

    return (
      <Container>
        <Spacer>
          <SubHeadlineWithIcon
            filter={true}
            search={true}
            searchPlaceholder={StaticTextService.getCityPlaceholder()}
            handleSearchByCity={data.handleSearchByCity}
            multiSelectContent={allMonths}
            displayHistory={false}
            moment={true}
          >
            {StaticTextService.getUpcomingEventsText()}
          </SubHeadlineWithIcon>
          <small style={{
            position: 'absolute',
            marginTop: '-8px',
            marginLeft: '8px',
            backgroundColor: 'white',
            padding: '0 4px'
          }}>
            {isBrowser && StaticTextService.getFilterHint()}
          </small>
        </Spacer>
        <StyledRow>
          {!Boolean(events.length) ? (
            <div className={"rich-text-section"} style={{marginLeft: 7}}>
              <h3>{StaticTextService.getEmptyFilter()}</h3>
            </div>
          ) : (
            <>
              {/*{isLoading &&*/}
              {/*  <EventLoader/>*/}
              {/*}*/}
              {res.map((current: any) => {
                if (!Boolean(current)) {
                  return;
                }
                return (
                  <Col xs={12} md={3} key={current.id}>
                    <ArtistGridTile
                      artist={current}
                      link={"#"}
                      selectArtist={this.filterArtist}
                      style={{
                        fontSize: "26px",
                        outline: "none",
                        lineHeight: "36px"
                      }}
                    />
                  </Col>
                );
              })}
              {selectedArtist !== undefined && (
                <Col xs={12} md={3}>
                  <FilterResetTile onClick={this.resetFilter}/>
                </Col>
              )}
            </>
          )}
        </StyledRow>

        {Boolean(events.length) && (
          // @ts-ignore
          <MonthListEventsContainer selectedArtist={selectedArtist}/>
        )}
      </Container>
    );
  }

  private filterArtist = (artist: any) => {
    this.setState({
      selectedArtist: artist
    });
  };

  private resetFilter = () => {
    this.setState({
      selectedArtist: undefined
    });
  };

  private getMappedEventsMonths(events: ArtistTourEvent[]) {
    const eventsByMonth = new Map<string, ArtistTourEvent[]>();
    const eventMonths: string[] = events.map((event) =>
      moment(event.start).format("YYYY-MM")
    );
    const eventMonthsUnique: string[] = Array.from(new Set(eventMonths));

    eventMonthsUnique.map((month) => {
      const monthEvents = events.filter(
        (event) => moment(event.start).format("YYYY-MM") === month
      );
      eventsByMonth.set(month, monthEvents);
    });
    return eventsByMonth;
  }
}

export default getContext(UpcomingEventsPage);
