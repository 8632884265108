import Header from "./Header";
import ImageInfo from "./ImageInfo";

export default class Page {
  public readonly id: string;
  public readonly slug: string;
  public readonly navigationTitle: string;
  public readonly header: Header;
  public readonly title: string;
  public readonly text: any;
  public readonly elements: JSX.Element[];
  public readonly seoTitle: string;
  public readonly seoDescription: string;
  public readonly seoNoIndex: boolean;
  public readonly invertColors: boolean;
  public readonly disruptor?: ImageInfo;
  public readonly disruptorUrl?: string;


  constructor(
    id: string,
    slug: string,
    navigationTitle: string,
    header: Header,
    title: string,
    text: any,
    elements: JSX.Element[],
    seoTitle: string,
    seoDescription: string,
    seoNoIndex: boolean,
    invertColors: boolean,
    disruptor?: ImageInfo,
    disruptorUrl?: string
  ) {
    this.id = id;
    this.slug = slug;
    this.navigationTitle = navigationTitle;
    this.header = header;
    this.title = title;
    this.text = text;
    this.elements = elements;
    this.seoTitle = seoTitle;
    this.seoDescription = seoDescription;
    this.seoNoIndex = seoNoIndex;
    this.invertColors = invertColors;
    this.disruptor = disruptor;
    this.disruptorUrl = disruptorUrl;
  }
};
