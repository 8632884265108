import styled from "react-emotion";

interface LightTextInterface {
  theme?: any;
  gray?: string | number;
}

export const LightText = styled("span")(
  ({ theme, gray }: LightTextInterface) => ({
    ...theme.typography.light,
    fontFamily: theme.typography.lightFontFamily
  })
);
