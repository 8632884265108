import React from "react";
import RichText from "./RichText";
import { SectionHeadline } from "./Typography";
import PageElementColumn from "../domain/PageElementColumn";
import { Row, Col } from "react-flexbox-grid";
import styled from "react-emotion";

export interface Props {
  title: string;
  columns: PageElementColumn[];
}

export const Container = styled("div")((props: any) => ({
  padding: `${props.theme.spacing.unit * 10}px 0`
}));

const ClaimHeading = styled("h3")((props: any) => ({
  ...props.theme.typography.claimHeading
}));

const Claims: React.SFC<Props> = ({ title, columns }) => {
  return (
    <Container>
      <SectionHeadline>{title}</SectionHeadline>
      <Row>
        {columns.map(column => (
          <Col md={true}>
            <ClaimHeading>{column.title}</ClaimHeading>
            <RichText key={column.id} text={column.text} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Claims;
