import Artist from "../../domain/Artist";
import {Component} from "react";
import * as React from "react";
import ArtistGridTile from "./ArtistGridTile";
import Hyperlink from "../../domain/Hyperlink";
import {Row, Col} from "react-flexbox-grid";

import {LinkButton} from "../../components/Button";
import {SectionHeadline} from "src/components/Typography";
import styled from "react-emotion";
import {Section} from "src/components/Section";
import ServiceRegistry from "./../../services/ServiceRegistry";
import PrerenderingHelper from "protofy-prerender";
import {BackgroundLazyLoader} from "../../functions/lazyLoad";
import ContentService from "../../services/ContentService";
import StaticLabelService from "../../services/StaticLabelService";

export interface Props {
  title: string;
  onTourLabel: string;
  artists: Artist[];
  showMore: Hyperlink;
}

export interface State {
  artists?: Artist[];
  sortList: string[];
  artistIds: string[];
}

const CTAContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end"
});

export default class FeaturedArtists extends Component<Props, State> {

  private contentService: ContentService;

  constructor(props: Props, context: any) {
    super(props, context);
    const {artists} = props;
    const artistIdList = artists.map(item => item.id);

    this.state = {
      sortList: artistIdList,
      artistIds: [...artistIdList]
    }
    this.contentService = ServiceRegistry.getContentService();
  }

  public componentDidMount(): void {
    PrerenderingHelper.waitFor(this);
    this.contentService
      .getArtistsByIds(this.state.artistIds)
      .then((artists) => {
        artists.sort((a, b) => this.state.sortList.indexOf(a.id) - this.state.sortList.indexOf(b.id));
        this.setState({artists})
      });
  }

  public componentDidUpdate(): void {
    if (this.state.artists) {
      PrerenderingHelper.onNextFrameDone(this);
    }
    BackgroundLazyLoader();
  }


  // private sortByTourDates(artistsExtend: Artist[]) {
  //   artistsExtend.forEach((artist) => {
  //     console.log("artistSortedBydate", artist);
  //     const tour = artist.tours.filter(tour => !tour.hideTour);
  //     if (tour.length !== 0) {
  //       artist.labelConfig[0].currentTour = tour[0];
  //     } else {
  //       artist.labelConfig[0].currentTour = artist.tours[0];
  //     }
  //   });
  //   artistsExtend.sort((a, b) => {
  //     return new Date(b.labelConfig[0].currentTour.start).getTime() - new Date(a.labelConfig[0].currentTour.start).getTime();
  //   })
  //
  //   return artistsExtend;
  // }


  public render(): JSX.Element {
    const {title, showMore} = this.props;
    const artistsExtend = this.state.artists;
    const locale = ServiceRegistry.getContentService().getLocale();
    const prefixes = {
      // @todo this is very dirty and duplicated in EventItemInterface
      de: "/artists/",
      en: "/en/artists/"
    };

    if (!artistsExtend) {
      return <></>;
    }

    // const artistSortedBydate = this.sortByTourDates(artistsExtend);
    // console.log("artistSortedBydate", artistSortedBydate);

    let columnCount: number = 4;
    switch (artistsExtend.length) {
      case 3:
      case 5:
      case 6:
        columnCount = 4;
        break;
      default:
        columnCount = 3;
    }

    return (
      <Section>
        <SectionHeadline>{title}</SectionHeadline>
        <Row style={{paddingTop: "8px"}}>
          {artistsExtend.map((artist, index) => (
            <Col xs={12} md={columnCount} key={index}>
              <ArtistGridTile
                artist={artist}
                labelComponent={StaticLabelService.getTourLabel(artist)}
                link={`${prefixes[locale]}${artist.slug}`}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={true}>
            <CTAContainer>
              {showMore && showMore.href && (
                <LinkButton variant="buttonCommon" to={showMore.href}>
                  {showMore.label}
                </LinkButton>
              )}
            </CTAContainer>
          </Col>
        </Row>
      </Section>
    );
  }
}
