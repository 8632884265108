import styled from "react-emotion";

interface NavigationListItemProps {
  active?: any;
  className?: string;
  theme?: any;
  children?: any;
  key?: any;
  to?: any;
}

const NavigationListItem = styled("li")((props: NavigationListItemProps) => {
  return {
    padding: `${props.theme.spacing.unit}px ${props.theme.spacing.unit * 2}px`,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    "a": {
      transition: "opacity 0.5s"
    },
    "a:hover": {
      opacity: "0.6"
    }
  };
});

export const LanguageSwitchListItem = styled(NavigationListItem)(
  (props: NavigationListItemProps) => {
    return {
      padding: `${props.theme.spacing.unit}px`,
      cursor: "pointer"
    };
  }
);

export const LanguageSwitchDivider = styled(NavigationListItem)(
  (props: NavigationListItemProps) => {
    return {
      color: props.theme.palette.common.black,
      padding: `${props.theme.spacing.unit}px 0`
    };
  }
);

export default NavigationListItem;
