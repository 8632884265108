import styled from "react-emotion";

interface TileLabelInterface {
  theme?: any;
  children?: any;
  invertColor?: boolean;
}

export const TileLabel = styled("div")(
  ({theme, invertColor}: TileLabelInterface) => {
    const {md} = theme.palette.breakpoints.definitions;
    return {
      fontFamily: theme.typography.headlineFontFamily,
      fontWeight: "normal",
      fontSize: "0.875rem",
      position: "relative",
      marginLeft: "-6px",
      marginBottom: "6px",
      display: "inline-flex",
      width: "fit-content",
      textTransform:"uppercase",
      top: theme.spacing.unit * 2,
      color: invertColor
        ? theme.palette.common.black
        : theme.palette.common.white,
      backgroundColor: invertColor
        ? theme.palette.common.white
        : theme.palette.common.black,
      padding: theme.spacing.unit * 0.5,
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      boxShadow: "5px 2px 9px 0px rgba(0, 0, 0, 0.2)",
      ":before": {
        content: '""',
        position: "absolute",
        top: "2px",
        left: "0",
        backgroundColor: theme.palette.primary.main,
        width: "10px",
        height: "100%",
        transform: "skewY(20deg)",
        zIndex: -1,
      },


      // [xs]: {
      //   fontSize: theme.typography.fontSize
      // },
      [md]: {
        fontSize: "0.875rem"
      }
      // [lg]: {
      //   fontSize: theme.typography.fontSize
      // }
    };


  }
);
