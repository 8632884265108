import * as React from "react";
import { Component } from "react";
import Helmet from "react-helmet";
import { Location } from "history";
import Header from "../domain/Header";
import PathHelper from "../services/PathHelper";
import Artist from "../domain/Artist";
import News from "../domain/News";
import RouteRegistry from "../services/RouteRegistry";
import ServiceRegistry from "../services/ServiceRegistry";

interface Props {
  location: Location;
  title: string;
  desc: string;
  noIndex: boolean;
  artist?: Artist;
  news?: News;
  header?: Header;
}

interface State {}

export default class SeoTags extends Component<Props, State> {
  private routeRegistry: RouteRegistry;
  private locales: string[];

  constructor(props: Props, context: any) {
    super(props, context);

    this.routeRegistry = ServiceRegistry.getRouteRegistry();
    this.locales = this.routeRegistry.getLocales();
  }

  public render(): JSX.Element {
    const canonicalPath = PathHelper.canonicalPath(this.props.location);
    const { title, desc, header, noIndex, artist } = this.props;
    const image = !header
      ? artist && artist.profileImage.url
      : (header.parts || [])
          .map(p => (!p.imageWide ? undefined : p.imageWide.url))
          .filter(url => !!url)[0];

    const alternates = this.getAlternateLinks(this.props.location);

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />

          {!noIndex && <link rel="canonical" href={canonicalPath} />}

          {desc && desc.trim() !== "" && (
            <meta name="description" content={desc} />
          )}
          {desc && desc.trim() !== "" && (
            <meta property="og:description" content={desc} />
          )}
          {image && <meta property="og:image" content={`https:${image}`} />}
          {artist && (
            <meta
              property="og:image"
              content={`https:${artist.profileImage.url}`}
            />
          )}

          <meta property="og:type" content={this.getSiteType()} />
          <meta property="og:site_name" content="Neuland Concerts GmbH" />

          {canonicalPath.indexOf("404") !== -1 && (
            <meta name="prerender-status-code" content="404" />
          )}

          {noIndex ? (
            <meta name="robots" content="noindex,follow" />
          ) : (
            <meta name="robots" content="index,follow" />
          )}
          {alternates && alternates}
        </Helmet>
        {this.props.news && this.renderNews(this.props.news)}
        {this.props.artist && this.renderArtist(this.props.artist)}
      </>
    );
  }

  private getAlternateLinks(location: Location): JSX.Element[] {
    const path = location.pathname;
    const alternates: JSX.Element[] = [];

    this.locales.forEach(locale => {
      const localePath = this.routeRegistry.translatePath(path, locale);
      if (localePath) {
        if (locale === "en") {
          alternates.push(
            <link
              key="x-default"
              rel="alternate"
              hrefLang="x-default"
              href={PathHelper.canonicalPathOfPath(localePath)}
            />
          );
        }
        alternates.push(
          <link
            key={locale}
            rel="alternate"
            hrefLang={locale}
            href={PathHelper.canonicalPathOfPath(localePath)}
          />
        );
      }
    });
    return alternates;
  }

  private getSiteType(): string {
    if (this.props.artist) {
      return "profile";
    }
    if (this.props.news) {
      return "article";
    }
    return "website";
  }

  private renderArtist(artist: Artist): JSX.Element {
    return (
      <Helmet>
        {artist.seoFacebookProfileId && (
          <meta
            property="fb:profile_id"
            content={artist.seoFacebookProfileId}
          />
        )}
        {artist.seoUsername && (
          <meta property="profile:username" content={artist.seoUsername} />
        )}
        {artist.seoFirstName && (
          <meta property="profile:first_name" content={artist.seoFirstName} />
        )}
        {artist.seoLastName && (
          <meta property="profile:last_name" content={artist.seoLastName} />
        )}
        {artist.seoGender && artist.seoGender !== "other" && (
          <meta property="profile:gender" content={artist.seoGender} />
        )}
      </Helmet>
    );
  }

  private renderNews(news: News): JSX.Element {
    return (
      <Helmet>
        {news.seoAuthors &&
          news.seoAuthors.map((author, i) => (
            <meta key={i} property="article:author" content={author} />
          ))}

        <meta property="article:content_tier" content="free" />

        {news.seoPublishedTime && (
          <meta
            property="article:published_time"
            content={news.seoPublishedTime.toISOString()}
          />
        )}

        {news.seoModifiedTime && (
          <meta
            property="article:modified_time"
            content={news.seoModifiedTime.toISOString()}
          />
        )}

        {news.seoPublisher && (
          <meta property="article:publisher" content={news.seoPublisher} />
        )}

        {news.seoSection && (
          <meta property="article:section" content={news.seoSection} />
        )}
        {news.seoTags &&
          news.seoTags.map((tag, i) => (
            <meta key={i} property="article:tag" content={tag} />
          ))}
      </Helmet>
    );
  }
}
