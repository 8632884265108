import * as React from "react";
import {Component} from "react";
import styled from "react-emotion";
import {compose} from "recompose";

import {Headline, SubHeadline} from "./Typography";
import getIconComponent from "./icons/index";
import Filter from "../components/artist-filter/filter";
import {Context} from "./../FilterContext";
import SearchButton from "../components/search/searchButton";
import {withRouter} from "react-router";
import {getContext} from "src/getContext";

const Container = styled("div")((props: any) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  width: "100%",
  paddingTop: `${props.theme.spacing.unit}px`,
  paddingBottom: `${props.theme.spacing.unit}px`,
  borderBottom: `1px solid ${props.theme.palette.common.black}`,
  position: "relative"
}));

const Actions = styled("div")({
  display: "flex",
  alignItems: "flex-end",
  flexDirection: "row-reverse",
  marginLeft: "auto",
  padding: '8px',
  position: "relative",
  width: "100%",
  justifyContent: "space-between",
  "@media(min-width: 767px)": {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 0,
    width: "auto",
  }
});

const ActionIcon = styled("a")({
  display: "inline-flex",
  marginRight: 18,
  ":last-of-type": {
    marginRight: 0
  }
});

export interface Props {
  id?: any;
  text?: string;
  email?: string;
  filter?: boolean;
  phone?: string;
  socials?: any;
  search?: boolean;
  searchPlaceholder?: any;
  handleSearchByCity?: any;
  location?: any;
  data?: any;
  multiSelectContent?: any;
  displayHistory?: boolean;
  moment?: boolean;
}

interface State {
}

export const HeadlineWithIcon = (props: any) => (
  <Container>
    <Headline>{props.children}</Headline>
    <Actions>
      {props.actions && (
        <>
          <ActionIcon>{props.actions.map((item: any) => item)}</ActionIcon>
        </>
      )}
    </Actions>
  </Container>
);

class SubHeadlineWithIcon extends Component<Props, State> {
  public state = {};

  constructor(props: any) {
    super(props);
  }

  public getSearchAction = () => {
    const path = this.props.location.pathname;
    if (path === "/termine-tickets" || path === "/en/current-tours") {
      return this.props.data.handleSearchByCity;
    }
    return false;
  };

  public render(): JSX.Element {
    const {id, children, email, phone, socials, filter, search} = this.props;

    return (
      <Container id={id}>
        <SubHeadline>{children}</SubHeadline>
        <Actions>
          {email && (
            <>
              <ActionIcon href={`mailto:${email}`}>
                {getIconComponent("mail")}
              </ActionIcon>
            </>
          )}
          {phone && (
            <>
              <ActionIcon href={`tel:${phone}`}>
                {getIconComponent("phone")}
              </ActionIcon>
            </>
          )}
          {socials && (
            <>
              {Object.keys(socials).map((key: string, index: number) => {
                if (!socials[key]) {
                  return null;
                }
                return (
                  <ActionIcon key={index} href={socials[key]} target={'_blank'}>
                    {getIconComponent(key)}
                  </ActionIcon>
                );
              })}
            </>
          )}
          {search && (
            <>
              <Context.Consumer>
                {context =>
                  context && (
                    <SearchButton
                      data={context.data}
                      handleSearch={
                        this.getSearchAction() || context.handleSearch
                      }
                      placeholder={this.props.searchPlaceholder}
                    />
                  )
                }
              </Context.Consumer>
            </>
          )}
          {filter && (
            <>
              {/*
            //@ts-ignore*/}
              <Filter
                //@ts-ignore
                multiSelectContent={this.props.multiSelectContent}
                displayHistory={this.props.displayHistory}
                moment={this.props.moment}
              />
            </>
          )}
        </Actions>
      </Container>
    );
  }
}

export default compose<Props, Props>(
  getContext,
  withRouter
)(SubHeadlineWithIcon);
