import styled from "react-emotion";
import React, { Component } from "react";

import FilterIcon from "./filterIcon";
import FilterDropdown from "./filterDropdown";
import { Row, Col } from "react-flexbox-grid";
import FilterTag from "./tag";
import StaticTextService from "src/services/StaticTextService";
import { Context } from "src/FilterContext";
import { getContext } from "src/getContext";
import checkBrowser from "../../functions/navigator";

interface FilterInterface {
  theme?: any;
  children?: any;
}

interface Props {
  multiSelectContent?: any;
  displayHistory?: boolean;
  moment?: boolean;
  data: any;
}

interface State {
  filterDropdownActive: boolean;
  activeFilter: any;
  historyActive: boolean;
  countFilter: number;
  passedFilter: any;
  mousedDown?: boolean;
  isIE: boolean;
}

const FilterWrapper = styled("div")(({ theme }: FilterInterface) => {
  return {
    width: "auto",
    fontFamily: "Roboto",
    fontSize: "26px",
    display: "flex",
    position: "relative",
    userSelect: "none",
    outline: 0,
    top: 0,
    pointerEvents: "auto",
    "@media(min-width: 767px)": {
      top: 0,
      position: "relative"
    }
  };
});

const FilterTitleWrapper = styled("div")({
  cursor: "pointer",
  display: "flex",
  margin: "4px 3px 2px 0",
  lineHeight: "26px",
  "@media(min-width: 576px)": { margin: "8px 3px 2px 0" },
  "@media(min-width: 767px)": { margin: "2px 3px 2px 0" }
});

class Filter extends Component<Props, State> {
  public state = {
    filterDropdownActive: false,
    activeFilter: null,
    historyActive: false,
    countFilter: 0,
    passedFilter: null,
    isIE: false
  };

  constructor(props: Props, context: any) {
    super(props, context);
  }

  public componentDidMount() {
    const browser = checkBrowser();
    this.setState({
      isIE: browser.includes("IE")
    });
  }

  public toggleDropdownActive = () => {
    this.setState({
      filterDropdownActive: !this.state.filterDropdownActive
    });
  };

  public closeDropdown = () => {
    this.setState({
      filterDropdownActive: false
    });
  };

  public renderTagTitle = () => {
    if (this.state.activeFilter !== null) {
      return this.state.activeFilter;
    } else if (this.state.historyActive === true) {
      return StaticTextService.getHistoryFilter();
    } else {
      return "";
    }
  };

  public handleChange = (filters: any) => {
    this.setState(filters);
    this.props.data.handleChange(
      filters.activeFilter,
      filters.historyActive,
      filters.countFilter,
      filters.passedFilter
    );
  };

  public render() {
    let tag;

    if (this.state.activeFilter !== null || this.state.historyActive === true) {
      tag = (
        <FilterTag
          title={this.renderTagTitle()}
          handleChange={this.handleChange}
          filters={this.state}
          moment={this.props.moment}
        />
      );
    } else {
      tag = "";
    }
    let smallTag;

    if (this.state.countFilter >= 1) {
      smallTag = (
        <FilterTag
          title={this.state.countFilter}
          handleChange={this.handleChange}
          filters={this.state}
        />
      );
    }

    const onBlurFunction = this.state.isIE ? undefined : this.closeDropdown;

    return (
      <FilterWrapper onBlur={onBlurFunction} tabIndex={0}>
        <Col>
          <Row style={{ flexWrap: "nowrap", fontSize: "20px" }}>
            <div className={"hidden-xs hidden-sm hidden-md"}>{tag}</div>
            <div className={"hidden-lg hidden-xl"}>{smallTag}</div>
            <FilterTitleWrapper onClick={this.toggleDropdownActive}>
              <div className={"hidden-xs hidden-sm hidden-md"}>
                {StaticTextService.getFilterName()}
              </div>
              <FilterIcon />
            </FilterTitleWrapper>
          </Row>
          <Context.Consumer>
            {context =>
              context && (
                <FilterDropdown
                  active={this.state.filterDropdownActive}
                  handleChange={this.handleChange}
                  filters={this.state}
                  data={context}
                  multiSelectContent={this.props.multiSelectContent}
                  displayHistory={this.props.displayHistory}
                  moment={this.props.moment}
                />
              )
            }
          </Context.Consumer>
        </Col>
      </FilterWrapper>
    );
  }
}

export default getContext(Filter);
