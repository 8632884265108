import styled from "react-emotion";
const MobileNavigationList = styled("ul")(({ theme, active = false }: any) => {
  const width = "60vw";
  return {
    listStyle: "none",
    backgroundColor: theme.palette.common.white,
    padding: 0,
    position: "fixed",
    left: `-${width}`,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "stretch",
    width,
    boxShadow: active ? theme.shadows[5] : "none",
    zIndex: theme.transitions.zIndex.appBar,
    transitionDuration: `${theme.transitions.duration.shortest}ms`,
    transform: active ? `translateX(${width})` : `translateX(-${width})`
  };
});
export default MobileNavigationList;
