import * as React from "react";
import {Component} from "react";
import HeaderPart from "../../domain/HeaderPart";
import {
  BillboardContainer as Billboard,
  AspectRatio
} from "../Billboard";

// @ts-ignore
import SwipeableViews from "react-swipeable-views";
import {Grid, Row, Col} from "react-flexbox-grid";
import styled from "react-emotion";

export interface Props {
  parts: HeaderPart[];
  headlines?: string[];
}

interface DotsProps {
  active: boolean;
  theme?: any;
}

const DotsContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  paddingTop: 16,
  paddingBottom: 16
});

const Container = styled("div")({
  position: "relative"
});

const Headlines = styled("div")({
  position: "absolute",
  bottom: "10%",
  zIndex: 1,
  display: "flex",
  flexFlow: "column"
});

const Headline = styled("span")((props: any) => ({
  display: "inline-block",
  fontFamily: "Roboto",
  fontSize: props.theme.typography.fontSize * 1.2,
  color: props.theme.palette.common.white,
  textTransform: "uppercase",
  paddingLeft: props.theme.spacing.unit,
  paddingRight: props.theme.spacing.unit,
  backgroundColor: props.theme.palette.common.black,
  margin: `0 0 ${props.theme.spacing.unit}px 0`,
  width: "fit-content",
  "@media only screen and (min-width: 768px)": {
    fontSize: props.theme.typography.fontSize * 2
  },
  "@media only screen and (min-width: 1280px)": {
    fontSize: props.theme.typography.fontSize * 2.5
  }
}));

const Dot = styled("div")((props: DotsProps) => ({
  width: 7,
  height: 7,
  borderRadius: "50%",
  backgroundColor: props.active
    ? props.theme.palette.common.black
    : props.theme.palette.grey[500],
  marginRight: 9,
  cursor: "pointer"
}));

export interface State {
  index: number;
}

export default class MediumHeader extends Component<Props, State> {
  public state = {
    index: 0
  };
  // @ts-ignore
  public slideTimeout: NodeJS.Timeout = undefined;
  public slideIntervalInMs = 5000;

  constructor(props: Props) {
    super(props);
    if (this.props.parts.length > 1) {
      this.activateSlideInterval();
    }
  }

  public activateSlideInterval() {
    this.slideTimeout = setTimeout(() => {
      const numParts = this.props.parts.length;
      const currentIndex = this.state.index;
      const nextIndex = currentIndex <= numParts - 2 ? currentIndex + 1 : 0;
      this.setState({index: nextIndex});
      this.activateSlideInterval();
    }, this.slideIntervalInMs);
  }

  public deactivateSlideInterval() {
    clearTimeout(this.slideTimeout);
  }

  public setIndex = (index: number) => {
    this.setState({index});
  };

  public onClickSlideControlElement(index: number) {
    this.deactivateSlideInterval();
    this.setIndex(index);
  }

  public createSplittedHeadline(headlines: string[] = []) {
    return headlines.map((headline, index) => {
      if (headline.indexOf(':') !== -1) {
        const part = headline.split(':');
        return part.map((hl, index) => {
          return (index < part.length - 1) ?
            <Headline key={index}>{hl.trim() + ': '}</Headline>
            :
            <Headline key={index}>{hl.trim()}</Headline>
        })
      }
      return <Headline key={index}>{headline}</Headline>
    })
  }

  public render(): JSX.Element {
    const {parts, headlines} = this.props;
    return (
      parts && parts.length > 0 ? (
          <Container>
            <Grid>
              <Row>
                <Col>
                  <Headlines>
                    {this.createSplittedHeadline(headlines)}
                  </Headlines>
                </Col>
              </Row>
            </Grid>
            <SwipeableViews
              axis={"x"}
              index={this.state.index}
              onChangeIndex={this.setIndex}
              enableMouseEvents={true}
            >
              {parts.filter((part, key) => part.imageWide).map((part, key) => (
                <Billboard
                  key={key}
                  aspect={AspectRatio.LandscapeSmall}
                  imgSrc={part}
                >
                  <></>
                </Billboard>
              ))}
            </SwipeableViews>
            {parts.length > 1 && (
              <DotsContainer>
                {parts.map((part, index) => (
                  <Dot
                    key={part.title}
                    active={this.state.index === index}
                    onClick={() => this.onClickSlideControlElement(index)}
                  />
                ))}
              </DotsContainer>
            )}
          </Container>
        ) :
        <></>
    );
  }
}
