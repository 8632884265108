import {Component} from "react";
import ServiceRegistry from "../services/ServiceRegistry";
import PageElement from "../domain/PageElement";
import News from "../domain/News";
import * as React from "react";
import NewsWidgetItem from "./NewsWidgetItem";

import PrerenderingHelper from "protofy-prerender";
import {SectionHeadline} from "./Typography";
import {Row, Col} from "react-flexbox-grid";
import {AnchorButton} from "./Button";
import {Section} from "./Section";
import {EqualHeight} from "react-equal-height";

export interface Props {
  title: string;
}

export interface State {
  news: News[];
  newsCount: number;
  hasMore: boolean;
  rowCount: number;
}

export default class NewsWidget extends Component<Props, State>
  implements PageElement {
  public state: State = {news: [], newsCount: 0, hasMore: true, rowCount: 1};

  constructor(props: Props) {
    super(props);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  public componentDidMount(): void {
    PrerenderingHelper.waitFor(this);
    this.handleLoadMore();
  }

  public componentDidUpdate(): void {
    if (this.state.news) {
      PrerenderingHelper.onNextFrameDone(this);
    }
  }

  public handleLoadMore(): void {
    const requestedNewsCount = this.state.newsCount + 4;
    ServiceRegistry.getContentService()
      .getNews(requestedNewsCount, 0)
      .then((news) =>
        this.setState({
          news,
          newsCount: news.length,
          hasMore: requestedNewsCount === news.length,
          rowCount: this.state.rowCount + 1
        })
      )
      .catch((e) => this.setState({news: []}));
  }

  public render(): JSX.Element {
    const {news, hasMore} = this.state;
    if (!news) {
      return <div/>;
    }

    const columnCount = news.length <= 3 ? 4 : 3;

    let rowCounter = 1;
    let count = 1;
    const nameList: string[] = [];
    news.forEach(() => {
      const rowName = "name-" + rowCounter
      if (count === 4) {
        rowCounter += 1;
        count = 0;
      }
      nameList.push(rowName);
      count++;
    })
    return (
      <Section>
        <Row>
          <Col xs={true}>
            <SectionHeadline>News</SectionHeadline>
          </Col>
        </Row>
        <Row>
          <EqualHeight>
            {news.map((item, index) => (
              <Col xs={12} sm={6} lg={columnCount} key={item.id}>

                <NewsWidgetItem key={index} news={item}
                                rowCount={nameList[index]}/>
              </Col>
            ))}
          </EqualHeight>
        </Row>
        {hasMore && (
          <Row>
            <Col xs={true}>
              <div style={{display: "flex", justifyContent: "flex-end"}}>
                <AnchorButton state={"active"} onClick={this.handleLoadMore}>
                  {"Weitere News anzeigen"}
                </AnchorButton>
              </div>
            </Col>
          </Row>
        )}
      </Section>
    );
  }
}
