import styled, {css} from "react-emotion";

const headlineBaseStyle = (props: any) => ({
  margin: 0,
  ...props.theme.typography.citation
});

const subHeadlineBaseStyle = (props: any) =>
  css({
    margin: 0,
    fontFamily: props.theme.typography.fontFamilyCondensedBold,
    fontSize: "1.75rem",
    fontWeight: props.bold,
    color: props.theme.palette.common.black
  });

const paragraphBaseStyle = (props: any) =>
  css({
    margin: 0,
    fontWeight: props.bold
      ? props.theme.typography.fontWeightBold
      : props.theme.typography.fontWeightRegular,
    color: props.theme.palette.common.black
  });

export const Headline = styled("h1")(headlineBaseStyle);
export const SectionHeadline = styled("h2")(headlineBaseStyle, (props: any) => {
  const {xs, sm} = props.theme.palette.breakpoints.definitions;
  return {
    fontWeight: props.bold,
    fontSize: "3.5rem",
    marginLeft: "-3px",
    marginBottom: "3.3rem",
    color: props.theme.palette.common.black,
    display: "inline-block",
    position: "relative",
    isolation: "isolate",
    width: "fit-content",
    [xs]: {
      fontSize: "3.5rem",
      width: "min-content"
    },
    [sm]: {
      fontSize: "3.5rem",
      width: "min-content"
    },
    ":after": {
      content: '""',
      position: "absolute",
      bottom: "-4px",
      left: "0",
      border: "6px solid",
      borderColor: props.theme.palette.common.black,
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      width: "100%",
      height: "30%",
      zIndex: -1,
    },
  };
});
export const SubHeadline = styled("h3")(subHeadlineBaseStyle);
export const Paragraph = styled("p")(paragraphBaseStyle);
