import React from "react";
// @ts-ignore
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import styled from "react-emotion";
import ServiceRegistry from "../services/ServiceRegistry";

export interface Props {
  text: any;
  centered?: boolean;
  lineHeight?: string;
}

const Root = styled("div")((props: any) => ({
  lineHeight: 1.5,
  color: props.theme.palette.common.black,
  // a:{
  //   color: props.theme.palette.common.black,
  // }
}));

const renderEntryHyperlink = (node: any) => {
  if (node && node.data && node.data.target && node.data.target.fields) {
    const slug = node.data.target.fields.slug || ""; // already current locale
    const localePath =
      ServiceRegistry.getContentService().getLocale() === "de" ? "" : "/en";
    const path = localePath + (slug.startsWith("/") ? slug : `/${slug}`);
    const body = documentToHtmlString(
      {
        nodeType: "document",
        data: {},
        content: node.content
      },
      options
    );
    return `<a href="${path}" onClick="event.preventDefault(); window.routerHistory.push('${path}')">${body}</a>`;
  } else {
    console.error("Entry link is broken! " + JSON.stringify(node));
    return "";
  }
};

const options = {
  renderNode: {
    ["entry-hyperlink"]: renderEntryHyperlink
  }
};

const RichText: React.SFC<Props> = ({ text, centered }) => {
  let html;
  if (!text) {
    return null;
  }
  try {
    html = documentToHtmlString(text, options);
    html = html.split("<a ").join("<a target=\"_blank\"");
    return (
      <Root
        className="rich-text-section"
        centered={centered}
        dangerouslySetInnerHTML={{
          __html: html.replace(/\n/g, "<br/>")
        }}
      />
    );
  } catch (err) {
    console.warn("Cannot render rich text:", err);
    return null;
  }
};

export default RichText;
