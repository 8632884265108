import React, {Component, SyntheticEvent} from "react";
import styled from "react-emotion";
import SearchInputfield from "./searchInputfield";
import ClearButton from "./clearButton";
import {Row} from "react-flexbox-grid";

interface Props {
  data: any;
  handleSearch: any;
  placeholder?: any;
}

interface State {
  selectedSearch: boolean;
  isAnimationDone: boolean;
  searchValue: any;
}

const SearchWrapper = styled("div")(({active = false}: any) => {
  return {
    backgroundColor: active ? "#eeeeee" : "#ffffff",
    height: 30,
    padding: "8px",
    position: "relative",
    width: 30,
    transition: "backgroundColor 500ms",
    marginRight: 9,
    "@media(min-width: 767px)": {
      marginRight: 30
    }
  };
});

const LoupeCircle = styled("div")({
  display: "block",
  backgroundColor: "transparent",
  border: "3px solid black",
  height: "20px",
  width: "20px",
  borderRadius: "50%",
  position: "absolute",
  top: "2px",
  left: "2px"
});

const LoupeStick = styled("hr")({
  height: "3px",
  backgroundColor: "#000",
  transform: "rotate(45deg)",
  position: "absolute",
  width: "14px",
  top: "-27px",
  left: "15px",
  border: "none"
});

const Wrapper = styled("div")(({active = false}: any) => {
  return {
    outline: "none",
    display: "flex",
    width: active ? 282 : 0,
    transition: "width 500ms"
  };
});

const StyledRow = styled(Row)({
  order: 1,
  position: "relative",
  top: 0,
  width: 293,
  justifyContent: "flex-end",
  flexWrap: "nowrap",
  "@media(min-width: 767px)": {
    order: 0,
    position: "relative",
    top: 0,
    right: 0,
    width: "auto"
  }
});

export default class SearchButton extends Component<Props, State> {
  public textInput: any;

  // TODO: refactor searchComponent if budget available
  public state = {
    selectedSearch: true,
    searchValue: "",
    isAnimationDone: true
  };

  constructor(props: any) {
    super(props);
    this.textInput = React.createRef();
  }

  public componentDidMount = () => {
    this.setState({searchValue: this.props.data.searchValue});
  };

  public componentWillUnmount = () => {
    this.props.handleSearch({searchValue: ""});
  };

  public openSearch = () => {
    if (this.state.isAnimationDone) {
      this.setState({selectedSearch: true, isAnimationDone: false});
    }
    if (this.state.selectedSearch) {
      // this.textInput.current.focusTextInput();
    }
  };

  public closeSearch = (event: SyntheticEvent) => {
    if (this.state.searchValue === "") {
      this.setState({selectedSearch: true});
      // this.setState({ selectedSearch: true }, () =>
      //   // setTimeout(() => this.setState({ isAnimationDone: true }), 500)
      // );
    }
  };

  public handleChange = (event: { target: { value: any } }) => {
    const target = event.target.value;
    this.setState({
      searchValue: target
    });
    if (target.length >= 3) {
      setTimeout(this.props.handleSearch({searchValue: target}), 500);
    } else {
      setTimeout(this.props.handleSearch({searchValue: target}), 500);
    }
  };

  public resetValue = () => {
    this.setState({
      searchValue: ""
    });

    this.props.handleSearch({searchValue: ""});
  };

  public render(): JSX.Element {
    if (this.state.selectedSearch) {
      // this.textInput.current.focusTextInput();
    }

    const openFunction = this.state.selectedSearch
      ? undefined
      : this.openSearch;
    return (
      <StyledRow>
        <Wrapper active={this.state.selectedSearch} tabIndex={0}>
          <SearchInputfield
            active={this.state.selectedSearch}
            value={this.state.searchValue}
            onChange={this.handleChange}
            placeholder={this.props.placeholder}
            onBlur={this.closeSearch}
            ref={this.textInput}
          />
          <ClearButton
            active={this.state.selectedSearch}
            onClick={this.resetValue}
          />
        </Wrapper>
        <SearchWrapper
          active={this.state.selectedSearch}
          onClick={openFunction}
        >
          <LoupeCircle/>
          <LoupeStick/>
        </SearchWrapper>
      </StyledRow>
    );
  }
}
