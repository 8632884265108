import React from "react";

const mail = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icons/mail</title>
    <desc>Created with Sketch.</desc>
    <g
      id="icons/mail"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="email"
        transform="translate(0.000000, 3.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <path
          d="M21.6,4.8 L12,10.8 L2.4,4.8 L2.4,2.4 L12,8.4 L21.6,2.4 L21.6,4.8 Z M21.6,0 L2.4,0 C1.068,0 0,1.068 0,2.4 L0,16.8 C0,18.1254834 1.0745166,19.2 2.4,19.2 L21.6,19.2 C22.9254834,19.2 24,18.1254834 24,16.8 L24,2.4 C24,1.068 22.92,0 21.6,0 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default mail;
