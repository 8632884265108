import React from "react";

const facebook = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icons/facebook</title>
    <desc>Created with Sketch.</desc>
    <g
      id="icons/facebook"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="facebook-logo-svgrepo-com"
        transform="translate(6.000000, 0.000000)"
        fill="#000000"
      >
        <path
          d="M12.213283,0.00494845361 L9.17392163,0 C5.75930334,0 3.552627,2.29731959 3.552627,5.85303093 L3.552627,8.5516701 L0.496685051,8.5516701 C0.232615385,8.5516701 0.0187750363,8.76890722 0.0187750363,9.03686598 L0.0187750363,12.9468866 C0.0187750363,13.2148454 0.232859216,13.4318351 0.496685051,13.4318351 L3.552627,13.4318351 L3.552627,23.2980619 C3.552627,23.5660206 3.76646734,23.7830103 4.03053701,23.7830103 L8.01767199,23.7830103 C8.28174165,23.7830103 8.495582,23.5657732 8.495582,23.2980619 L8.495582,13.4318351 L12.0686909,13.4318351 C12.3327605,13.4318351 12.5466009,13.2148454 12.5466009,12.9468866 L12.5480639,9.03686598 C12.5480639,8.90820619 12.4975907,8.78498969 12.4081045,8.69393814 C12.3186183,8.6028866 12.1967025,8.5516701 12.06991,8.5516701 L8.495582,8.5516701 L8.495582,6.264 C8.495582,5.16445361 8.75379971,4.60626804 10.1653411,4.60626804 L12.2127954,4.60552577 C12.4766212,4.60552577 12.6904615,4.38828866 12.6904615,4.12057732 L12.6904615,0.489896907 C12.6904615,0.22243299 12.476865,0.00544329897 12.213283,0.00494845361 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

export default facebook;
