import styled from "react-emotion";
export interface TileHeadlineInterface {
  theme?: any;
  style?: any;
}
export const TileHeadLine = styled("h2")(({ theme }: TileHeadlineInterface) => {
  const { md, sm} = theme.palette.breakpoints.definitions;
  // const boxShadow = `${theme.spacing.unit}px 0 0 ${
  //   theme.palette.common.black
  // }, -${theme.spacing.unit}px 0 0 ${theme.palette.common.black}`;
  return {
    fontFamily: theme.typography.fontFamilyCondensedBold,
    fontWeight: 700,
    fontSize: theme.typography.fontSize * 1.5,
    letterSpacing: 0,
    lineHeight:1.25,
    marginLeft:0,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    textDecoration: "none",
    paddingTop: theme.spacing.unit/2,
    paddingBottom: theme.spacing.unit/2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    position: "inherit",
    boxShadow: "5px 2px 9px 0px rgb(0 0 0 / 20%)",

    // paddingBottom: theme.spacing.unit,
    display: "inline",

    boxDecorationBreak: "clone", // fixes firebox rendering bug
    [md]: {
      fontSize: theme.typography.fontSize
    },
    [sm]: {
      fontSize: theme.typography.fontSize *2
    }
  };
});
