import styled from "react-emotion";
export interface TileInterface {
  imgsrc?: string;
  theme?: any;
  onClick?: any;
}
export const TileContainerNoLink = styled("div")((props: TileInterface) => ({
  // @todo: Images should scale to their device size
  // backgroundImage: `url(${props.imgsrc})`,
  backgroundColor: props.theme.palette.grey["300"],
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
  paddingTop: "100%",
  position: "relative",
  display: "block",
  marginBottom: props.theme.spacing.unit * 2
}));
