import {History, Location} from "history";
import * as React from "react";
import {Component} from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Router,
  Switch
} from "react-router-dom";
import {createBrowserHistory} from "history";
import {ThemeProvider} from "emotion-theming";

import ServiceRegistry from "./services/ServiceRegistry";
import GenericPage from "./pages/GenericPage";
import Footer from "./components/Footer";
import ArtistDetailsPage from "./pages/ArtistDetail/ArtistDetail";
import NewsDetailsPage from "./pages/NewsDetailsPage";

import theme from "./neuland-concerts-theme";
import darktheme from "./neuland-concerts-dark-theme";

import AppBar from "./components/AppBar";
import PrerenderingHelper from "protofy-prerender";
import {PageWrapper} from "./components/PageWrapper";
import {ContentWrapper} from "./components/ContentWrapper";
import FilterContext from "./FilterContext";
import CorporateNewsletter from "./pages/CorporateNewsletter";
import NewsLetterPage from "./pages/NewsLetterPage";
import ReactCookieFirst from "react-cookiefirst";
import CreationsDetailsPage from "./pages/Creations/CreationsDetail";

export interface State {
  initialized: boolean;
  locale: string;
  theme: any;
  isDarkTheme: boolean;
}

export interface Props {
}

export default class App extends Component<Props, State> {
  private readonly history: History;
  private readonly ga: any;
  private readonly dataLayer: any;

  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      initialized: false,
      locale: "de",
      theme: theme,
      isDarkTheme: false
    };

    this.history = createBrowserHistory({});
    //@ts-ignore
    window.routerHistory = this.history;
    /* tslint:disable */
    this.ga = window["ga"];
    this.dataLayer = window["dataLayer"];
    /* tslint:enable */
    this.history.listen(this.pageview.bind(this)); // only tracks future events


    this.onLocaleChange = this.onLocaleChange.bind(this);
  }

  public pageview(location: Location): void {
    const page = location.pathname + location.search + location.hash;

    this.themeColorHandler(window.location.pathname);


    if (page.startsWith("/analytics-opt-out")) {
      /* tslint:disable */
      const gaOptout = window["gaOptout"];
      /* tslint:enable */
      gaOptout();
      return;
    }

    if (this.dataLayer) {
      this.dataLayer.push({
        event: "pageview"
      });
    }
    if (this.ga) {
      this.ga("set", "page", page);
      this.ga("send", "pageview");
    }
  }

  public componentDidMount() {
    const path = window.location.pathname;
    this.themeColorHandler(window.location.pathname);
    let locale = "de";
    if (path === "/") {
      // @todo get by browser
    } else if (path === "/en" || path.startsWith("/en/")) {
      locale = "en";
    }
    ServiceRegistry.init(locale);
    PrerenderingHelper.init();
    const routeRegistry = ServiceRegistry.getRouteRegistry();
    ServiceRegistry.getContentService()
      .getAllRoutes()
      .then((routes) => {
        routeRegistry.init(routes);
        this.setState({initialized: true, locale});
      });

    this.pageview(this.history.location); // tracks the current load
  }

  public onLocaleChange(locale: string, path: string): void {
    ServiceRegistry.setLocale(locale);
    this.setState({locale});
    window.location.href = path;
    // @todo get current page and redirect to its equivalent in the new locale
  }


  public themeColorHandler(pathname: string): void {
    if (pathname.indexOf('/creations') !== -1) {
      this.setState({
        theme: darktheme,
        isDarkTheme: true
      })
    } else {
      this.setState({
        theme: theme,
        isDarkTheme: false
      })
    }


  }

  public render(): JSX.Element {
    if (!this.state.initialized) {
      return <div/>; // @todo loading indicator
    }

    const {locale} = this.state;
    const routeRegistry = ServiceRegistry.getRouteRegistry();
    const locales = routeRegistry.getLocales();
    const page404 = routeRegistry.getPageAndLocaleByPath("/404");
    const path404 = routeRegistry.getPathByPageIdAndLocale(
      page404.pageId,
      locale
    );
    const routes = routeRegistry.getAllRoutes();

    const render404Redirect = () => (
      <Redirect to={{pathname: path404, state: {notFound: true}}}/>
    );

    return (
      <Router history={this.history}>
        <ThemeProvider
          theme={this.state.theme}>
          <FilterContext>
            <PageWrapper>
              <div>
                <AppBar
                  locales={locales}
                  selected={locale}
                  isDarkTheme={this.state.isDarkTheme}
                  onChange={this.onLocaleChange}
                />
              </div>
              <ReactCookieFirst apiKey="d0156320-24db-492b-8fb6-26e1c0057d5c">
                <ContentWrapper>
                  <Switch>
                    <Route
                      path="/index.html"
                      render={() => <Redirect to={""}/>}
                    />
                    {routes.map((route, index) => (
                      <Route
                        key={route.path || "index"}
                        exact={true}
                        path={route.path ? route.path : "/"}
                        render={(routerProps) => (
                          <GenericPage
                            {...routerProps}
                            pageId={route.pageId}
                            locale={route.locale}
                          />
                        )}
                      />
                    ))}
                    <Route
                      path="/newsletter"
                      exact={true}
                      render={(routerProps) => (
                        <NewsLetterPage
                          {...routerProps}
                          locale={"de"}
                          render404Redirect={render404Redirect}
                        />
                      )}
                    />
                    <Route
                      path="/en/artists/:slug"
                      render={(routerProps) => (
                        <ArtistDetailsPage
                          {...routerProps}
                          locale="en"
                          render404Redirect={render404Redirect}
                        />
                      )}
                    />
                    <Route
                      path="/artists/:slug"
                      render={(routerProps) => (
                        <ArtistDetailsPage
                          {...routerProps}
                          locale="de"
                          render404Redirect={render404Redirect}
                        />
                      )}
                    />
                    <Route
                      path="/en/news/:slug"
                      render={(routerProps) => (
                        <NewsDetailsPage
                          {...routerProps}
                          locale="en"
                          render404Redirect={render404Redirect}
                        />
                      )}
                    />
                    <Route
                      path="/news/:slug"
                      render={(routerProps) => (
                        <NewsDetailsPage
                          {...routerProps}
                          locale="de"
                          render404Redirect={render404Redirect}
                        />
                      )}
                    />
                    <Route
                      path="/en/creations/:slug"
                      render={(routerProps) => (
                        <CreationsDetailsPage
                          {...routerProps}
                          locale="en"
                          render404Redirect={render404Redirect}
                        />
                      )}
                    />
                    <Route
                      path="/creations/:slug"
                      render={(routerProps) => (
                        <CreationsDetailsPage
                          {...routerProps}
                          locale="de"
                          render404Redirect={render404Redirect}
                        />
                      )}
                    />

                    <Route
                      path="/corporate/newsletter"
                      render={(routerProps) => (
                        <CorporateNewsletter
                          {...routerProps}
                          locale="de"
                          render404Redirect={render404Redirect}
                        />
                      )}
                    />


                    {render404Redirect()}
                  </Switch>
                </ContentWrapper>
                <Footer/>

              </ReactCookieFirst>
            </PageWrapper>
          </FilterContext>
        </ThemeProvider>
      </Router>
    );
  }
}
