import React, { Component } from "react";
import styled from "react-emotion";

interface Props {
  onClick: any;
  active?: boolean;
}

interface State {}

const DeleteWrapper = styled("div")(({ active = false }: any) => {
  return {
    backgroundColor: active ? "#eee" : "transparent",
    height: 30,
    width: 25,
    position: "relative",
    padding: "5px 0",
    display: active ? "block" : "none"
  };
});

const FirstCrossLine = styled("hr")(({ active = false }: any) => {
  return {
    transform: "rotate(45deg)",
    margin: "9px 0 0",
    backgroundColor: "black",
    height: "1.5px",
    width: "18px",
    border: "none",
    position: "absolute",
    borderRadius: "5px",
    display: active ? "block" : "none"
  };
});

const SecondCrossLine = styled("hr")(({ active = false }: any) => {
  return {
    transform: "rotate(-45deg)",
    top: "14px",
    backgroundColor: "black",
    height: "1.5px",
    width: "18px",
    border: "none",
    position: "absolute",
    borderRadius: "5px",
    margin: "0",
    display: active ? "block" : "none"
  };
});

export default class ClearButton extends Component<Props, State> {
  public render(): JSX.Element {
    return (
      <DeleteWrapper onClick={this.props.onClick} active={this.props.active}>
        <FirstCrossLine active={this.props.active} />
        <SecondCrossLine active={this.props.active} />
      </DeleteWrapper>
    );
  }
}
