import ArtistTour from "./ArtistTour";
import ImageInfo from "./ImageInfo";
import HeaderPart from "./HeaderPart";

export default class Artist {
  public readonly id: string;
  public readonly name: string;
  public readonly slug: string;
  public readonly description: string;
  public readonly formerArtist: boolean;
  public readonly profileImage: ImageInfo;
  public readonly forceBookingButton: boolean;
  public readonly contact: string;
  public readonly headerParts: HeaderPart[];
  public readonly website: string;
  public readonly facebook: string;
  public readonly socials: any;
  public readonly twitter: string;
  public readonly instagram: string;
  public readonly tiktok: string;
  public readonly spotify: string;
  public readonly youtubeLink: string;
  public readonly vimeoLink: string;
  public readonly tours: ArtistTour[];
  public readonly isOnTour: boolean | any;

  public readonly seoTitle: string;
  public readonly seoDescription: string;
  public readonly seoNoIndex: boolean;

  public readonly seoFacebookProfileId: string;
  public readonly seoUsername: string;
  public readonly seoFirstName: string;
  public readonly seoLastName: string;
  public readonly seoGender: string;
  public labelConfig: any[];

  constructor(
    id: string,
    name: string,
    slug: string,
    description: string,
    formerArtist: boolean,
    profileImage: any,
    forceBookingButton: boolean,
    contact: string,
    headerParts: any,
    website: string,
    facebook: string,
    twitter: string,
    instagram: string,
    tiktok: string,
    spotify: string,
    youtubeLink: string,
    vimeoLink: string,
    tours: ArtistTour[],
    isOnTour: boolean | any,
    seoTitle: string,
    seoDescription: string,
    seoNoIndex: boolean,
    seoFacebookProfileId: string,
    seoUsername: string,
    seoFirstName: string,
    seoLastName: string,
    seoGender: string,
    labelConfig: any[]
  ) {
    this.id = id;
    this.name = name;
    this.slug = slug;
    this.description = description;
    this.formerArtist = formerArtist;
    this.profileImage = profileImage;
    this.forceBookingButton = forceBookingButton;
    this.contact = contact;
    this.headerParts = headerParts;
    this.website = website;
    this.facebook = facebook;
    this.twitter = twitter;
    this.instagram = instagram;
    this.tiktok = tiktok;
    this.spotify = spotify;
    this.youtubeLink = youtubeLink;
    this.vimeoLink = vimeoLink;
    this.tours = tours;
    this.isOnTour = isOnTour;
    this.seoTitle = seoTitle;
    this.seoDescription = seoDescription;
    this.seoNoIndex = seoNoIndex;
    this.seoFacebookProfileId = seoFacebookProfileId;
    this.seoUsername = seoUsername;
    this.seoFirstName = seoFirstName;
    this.seoLastName = seoLastName;
    this.seoGender = seoGender;
    this.socials = { website, twitter, facebook, instagram, tiktok, spotify };
    this.labelConfig = labelConfig;
  }
}
