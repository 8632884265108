import styled from "react-emotion";
/*
this is not really a wrapper more a background that catches
click events to perform actions
*/
const MobileNavigationWrapper = styled("div")(
  ({ theme, active = false }: any) => {
    return {
      backgroundColor: "transparent",
      padding: 0,
      position: "fixed",
      left: 0,
      top: 0,
      margin: 0,
      width: active ? "100%" : 0,
      height: active ? "100%" : 0,
      zIndex: theme.transitions.zIndex.appBar
    };
  }
);
export default MobileNavigationWrapper;
