import * as React from "react";
import {Component} from "react";
import NavigationData from "../../domain/Navigation";
import ServiceRegistry from "../../services/ServiceRegistry";

import MobileNavigationList from "./MobileNavigationList";
import MobileNavigationListItem from "./MobileNavigationListItem";
import NavLink from "./NavLink";

// @ts-ignore
import * as logo from "../../assets/images/logo.png";
// @ts-ignore
import * as logo2x from "../../assets/images/logo@2x.png";
// @ts-ignore
import * as logoWhite from "../../assets/images/logoWhite.png";
// @ts-ignore
import * as logoWhite2x from "../../assets/images/logoWhite@2x.webp";

import MobileNavigationWrapper from "./MobileNavigationWrapper";

interface Props {
  isDarkTheme: boolean,
  active: boolean;
  onItemClick: () => void;
}

export interface State {
  navigation: NavigationData;
}

export default class MobileNavigation extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      navigation: new NavigationData()
    };
  }

  public componentDidMount(): void {
    ServiceRegistry.getContentService()
      .getNavigation()
      .then(navigation => {
        this.setState({navigation});
      });
  }

  public render(): JSX.Element {
    const props = this.props;
    const {isDarkTheme} = props;
    const themedLogo = isDarkTheme ? logoWhite : logo;
    const themedLogo2X = isDarkTheme ? logoWhite2x : logo2x;
    return (
      <MobileNavigationWrapper
        active={this.props.active}
        onClick={this.props.onItemClick}
      >
        <MobileNavigationList active={this.props.active}>
          <MobileNavigationListItem>
            <img
              src={themedLogo}
              srcSet={`${themedLogo} 1x, ${themedLogo2X} 2x`}
              alt="Neuland Concerts"
              width={this.props.isDarkTheme ? '200px' : '165px'}
              height={'68px'}
            />
          </MobileNavigationListItem>
          {this.state.navigation.items &&
            this.state.navigation.items.map(item => (
              <NavLink
                style={{padding: "8px 0", width: "100%"}}
                exact={true}
                key={item.label}
                to={item.link}
                onClick={this.props.onItemClick}
              >
                {item.label}
              </NavLink>
            ))}
        </MobileNavigationList>
      </MobileNavigationWrapper>
    );
  }
}
