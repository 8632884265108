import Header from "./Header";

export default class News {
  public readonly id: string;
  public readonly title: string;
  public readonly header: Header;
  public readonly slug: string;
  public readonly abstract: any;
  public readonly text: any;
  public readonly linkInternal: any;
  public readonly linkExternal: string;
  public readonly readNewsLabel: string;
  public readonly seoTitle: string;
  public readonly seoDescription: string;
  public readonly seoNoIndex: boolean;
  public readonly seoPublishedTime: Date;
  public readonly seoModifiedTime: Date;
  public readonly seoAuthors: string[];
  public readonly seoPublisher: string;
  public readonly seoSection: string;
  public readonly seoTags: string[];

  constructor(
    id: string,
    title: string,
    header: Header,
    slug: string,
    abstract: any,
    text: any,
    linkInternal: any,
    linkExternal: string,
    readNewsLabel: string,
    seoTitle: string,
    seoDescription: string,
    seoNoIndex: boolean,
    seoPublishedTime: Date,
    seoModifiedTime: Date,
    seoAuthors: string[],
    seoPublisher: string,
    seoSection: string,
    seoTags: string[]
  ) {
    this.id = id;
    this.title = title;
    this.header = header;
    this.slug = slug;
    this.abstract = abstract;
    this.text = text;
    this.linkInternal = linkInternal;
    this.linkExternal = linkExternal;
    this.readNewsLabel = readNewsLabel;
    this.seoTitle = seoTitle;
    this.seoDescription = seoDescription;
    this.seoNoIndex = seoNoIndex;
    this.seoPublishedTime = seoPublishedTime;
    this.seoModifiedTime = seoModifiedTime;
    this.seoAuthors = seoAuthors;
    this.seoPublisher = seoPublisher;
    this.seoSection = seoSection;
    this.seoTags = seoTags;
  }
}
