import NavigationItem from "./NavigationItem";

export default class Navigation {
  public readonly id: string;
  public readonly items: NavigationItem[];

  constructor(id?: string, items?: NavigationItem[]) {
    this.id = id || "";
    this.items = items || [];
  }
}
