import React, { Component } from "react";
import styled from "react-emotion";

interface Props {
  active?: boolean;
  value?: any;
  onChange?: any;
  onFocus?: any;
  onBlur?: any;
  placeholder?: string;
  ref?: any;
}

const InputWrapper = styled("input")(({ active = false }: any) => {
  return {
    backgroundColor: "#eeeeee",
    height: "30px",
    border: "none",
    outline: "none",
    width: active ? "230px" : "0px",
    padding: active ? "0 0 0 8px" : 0,
    transition: "width 500ms",
    "@media(min-width: 767px)": {
      width: active ? "257px" : "0px"
    },
    "&::-ms-clear": {
      height: 0,
      width: 0
    }
  };
});

export default class SearchInputfield extends Component<Props> {
  public textInput: any;

  constructor(props: any) {
    super(props);
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }

  public focusTextInput() {
    this.textInput.current.focus();
  }

  public render(): JSX.Element {
    const inputValue =
      typeof this.props.value === "string"
        ? this.props.value
        : this.props.value.searchValue;

    return (
      <InputWrapper
        active={this.props.active}
        value={inputValue}
        onChange={this.props.onChange}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        placeholder={this.props.placeholder}
        innerRef={this.textInput}
      />
    );
  }
}
