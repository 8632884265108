import * as React from "react";
import {Component} from "react";
import ServiceRegistry from "../services/ServiceRegistry";
import RouteRegistry from "../services/RouteRegistry";
import styled from "react-emotion";
import NavigationList from "./Navigation/NavigationList";
import {
  LanguageSwitchDivider,
  LanguageSwitchListItem
} from "./Navigation/NavigationListItem";

export interface Props {
  locales: string[];
  selected: string;
  style: any;
  onChange: (locale: string, newPath: string) => void;
}

export interface State {
  selected: string;
}

interface StyledLinkInterface {
  theme?: any;
  active?: string;
}

const StyledLink = styled("a")((props: StyledLinkInterface) => ({
  textDecoration: "none",
  color: props.theme.palette.common.black,
  borderBottom: `2px solid ${
    props.active === "true" ? props.theme.palette.common.black : "transparent"
  }`,
  textTransform: "uppercase"
}));

class LanguageSwitch extends Component<Props, State> {
  private readonly routeRegistry: RouteRegistry;

  constructor(props: Props) {
    super(props);

    this.routeRegistry = ServiceRegistry.getRouteRegistry();

    this.state = {
      selected: props.selected
    };

    this.onChange = this.onChange.bind(this);
  }

  public render(): JSX.Element {
    const {locales, style} = this.props;
    const {selected} = this.state;

    const choices = locales.map((locale, key) => (
      <React.Fragment key={locale}>
        <LanguageSwitchListItem onClick={() => this.onChange(locale)}>
          <StyledLink active={String(selected === locale)}>{locale}</StyledLink>
        </LanguageSwitchListItem>
        {!(locales.length - 1 === key) && (
          <LanguageSwitchDivider>|</LanguageSwitchDivider>
        )}
      </React.Fragment>
    ));
    return <NavigationList style={style}>{choices}</NavigationList>;
  }

  private onChange(selected: string): void {
    if (this.state.selected === selected) {
      return;
    }
    let newPath = this.routeRegistry.translatePath(
      window.location.pathname,
      selected
    );
    if (!newPath) {
      newPath = selected === "de" ? "/" : `/${selected}/`; // @todo configure by content?
    }

    if (
      !newPath ||
      newPath === "" ||
      newPath[0] !== "/" ||
      newPath.indexOf("news") !== -1
    ) {
      // fallback, for any not found alternative path and for news we redirect to start
      newPath = this.props.selected === "de" ? "/en/" : "/";
    }

    this.setState({selected});
    this.props.onChange(selected, newPath);
  }
}

export default LanguageSwitch;
