import ArtistTour from "./ArtistTour";

export default class ArtistTourEvent {
  public readonly id: string;
  public readonly tourId: string;
  public readonly tourName: string;
  public readonly tour: ArtistTour;
  public readonly ticketmasterEvent: any;
  public readonly context?: string;
  public readonly city: string;
  public readonly comment?: string;
  public readonly venue: string;
  public readonly start: string;
  public readonly end: string;
  public readonly hideTime: boolean;
  public readonly buttonLabel: string;
  public readonly regularAvailability: string;
  public readonly regularPrice: string;
  public readonly regularLink: string;
  public readonly vipTicketmasterEvent: any;
  public readonly vipAvailability: string;
  public readonly vipPrice: string;
  public readonly vipLink: string;

  constructor(
    id: string,
    tourId: string,
    tourName: string,
    tour: ArtistTour,
    ticketmasterEvent: any,
    context: string | undefined,
    city: string,
    comment: string | undefined,
    venue: string,
    start: string,
    end: string,
    hideTime: boolean,
    buttonLabel: string,
    regularAvailability: string,
    regularPrice: string,
    regularLink: string,
    vipTicketmasterEvent: any,
    vipAvailability: string,
    vipPrice: string,
    vipLink: string
  ) {
    this.id = id;
    this.tourId = tourId;
    this.tourName = tourName;
    this.tour = tour;
    this.ticketmasterEvent = ticketmasterEvent;
    this.context = context;
    this.city = city;
    this.comment = comment;
    this.venue = venue;
    this.start = start;
    this.end = end;
    this.hideTime = !!hideTime;
    this.buttonLabel = buttonLabel;
    this.regularAvailability = regularAvailability;
    this.regularPrice = regularPrice;
    this.regularLink = regularLink;
    this.vipTicketmasterEvent = vipTicketmasterEvent;
    this.vipAvailability = vipAvailability;
    this.vipPrice = vipPrice;
    this.vipLink = vipLink;
  }
}
