import * as React from "react";
import {Component} from "react";
import {RouteComponentProps} from "react-router-dom";
import SeoTags from "../../components/SeoTags";
import Header, {HeaderType} from "../../domain/Header";
import PrerenderingHelper from "protofy-prerender";
import ServiceRegistry from "../../services/ServiceRegistry";
import FullHeightHeader from "../../components/header/FullHeightHeader";
import {Grid, Row, Col} from "react-flexbox-grid";
import {FlexRow} from "../../components/FlexRow";
import styled from "react-emotion";
import RichText from "../../components/RichText";
import StaticTextService from "../../services/StaticTextService";
import {Section} from "../../components/Section";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import VimeoEmbed from "../../components/VimeoEmbed";
import Creation from "../../domain/Creation";
import GridGallery from "../../components/GridGallery";
import Copyright from "../../components/Copyright";

interface Params {
  slug: string;
}

export interface Props extends RouteComponentProps<Params> {
  locale: string;
  render404Redirect: () => JSX.Element;
}

export interface State {
  notFound?: boolean;
  creation?: Creation;
}

interface ColImgInterface {
  theme?: any;
  src: string;
}

const ColImg = styled(Col)((props: ColImgInterface) => ({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundImage: `url('${props.src}')`,
  backgroundColor: "black",
  paddingTop: "100%",
  position: "relative"
}));

interface ContentWrapperInterface {
  theme?: any;
}

const ContentWrapper = styled("div")(({theme}: ContentWrapperInterface) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.contrastColor.default,
  paddingBottom: "2rem"
}));

interface CssColumnsInterface {
  theme?: any;
}

const CssColumns = styled("div")(({theme}: CssColumnsInterface) => ({
  columnCount: 1,
  "@media(min-width: 441px)": {
    columnCount: 2,
  },
  "@media(min-width: 767px)": {
    columnCount: 3,
  },
  "@media(min-width: 991px)": {
    columnCount: 4,
  },

  columnGap: "2rem",
  paddingTop: "4rem",
  paddingBottom: "4rem"
}));

interface StyledLinkInterface {
  theme?: any;
  active?: string;
}

const StyledLink = styled("span")((props: StyledLinkInterface) => ({
  color: props.theme.palette.common.black,
  textDecoration: "underline",
  display: "block",
  margin: "1rem 0",
  ":hover": {
    textDecoration: "none"
  }
}));

export default class CreationsDetailsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      creation: undefined,
    };
  }

  public componentDidMount(): void {
    PrerenderingHelper.waitFor(this);
    this.loadCreationFromSlug(this.props.match.params.slug);
  }

  public componentDidUpdate(prevProps: Props): void {
    const currentSlug = this.props.match.params.slug;
    if (currentSlug !== prevProps.match.params.slug) {
      this.loadCreationFromSlug(currentSlug);
    }
    if (this.state.creation) {
      PrerenderingHelper.onNextFrameDone(this);
    }
  }

  public render(): JSX.Element {
    const {creation, notFound} = this.state;
    if (creation) {
      const header = (creation || {}).headerParts;
      // @ts-ignore
      // @ts-ignore
      return (
        <>
          {/* Meta Data */}
          <SeoTags
            location={this.props.location}
            title={(creation.seoTitle || "{{creations}} - Neuland Concerts")
              .replace("{{creations}}", creation.name || "")}
            desc={
              creation.seoDescription || StaticTextService.getDefaultDescription()
            }
            noIndex={creation.seoNoIndex}
            creations={creation}
            header={new Header(HeaderType.Medium, header)}
          />
          <ContentWrapper>
            <Section>{header && <FullHeightHeader parts={header}/>}</Section>
            <Section>
              <Grid>
                <Row>
                  <Col xs={12} md={4}>
                    <FlexRow>
                      <ColImg
                        src={creation.profileImage.url}
                        title={creation.profileImage.title}
                        xs={12}
                      >
                        <Copyright title={creation.profileImage.title}/>

                      </ColImg>

                    </FlexRow>
                  </Col>
                  <Col xs={12} md={8}>
                    <FlexRow>
                      <Col xs={12}>
                        <RichText text={creation.introduction}/>

                        {creation.website &&
                          <a href={creation.website} target={'_blank'}>
                            <StyledLink>{creation.website}</StyledLink>
                          </a>
                        }
                      </Col>
                    </FlexRow>
                  </Col>
                  <Col xs={12}>
                    <FlexRow>
                      <CssColumns>
                        <RichText text={creation.description}/>
                      </CssColumns>
                    </FlexRow>
                  </Col>
                </Row>
                {creation.imageGallery.length !== 0 &&
                  <Row>
                    <Col xs={12}>
                      <FlexRow>
                        <h2>Impressionen</h2>
                        <GridGallery images={creation.imageGallery}/>
                      </FlexRow>
                      <br/>
                      <br/>
                    </Col>
                  </Row>
                }
                {(!!creation.youtubeLink || !!creation.vimeoLink) &&
                  <Row>
                    <Col xs={12}>
                      <h2>Videos</h2>
                    </Col>
                    <Col xs={12} md={creation.videoDescription ? 8 : 12}>
                      {creation.youtubeLink && (
                        <YoutubeEmbed url={creation.youtubeLink}/>
                      )}
                      {creation.vimeoLink && (
                        <VimeoEmbed url={creation.vimeoLink}/>
                      )}
                    </Col>
                  </Row>
                }
              </Grid>
            </Section>
          </ContentWrapper>

        </>
      );
    } else if (notFound) {
      return this.props.render404Redirect();
    } else {
      return <div/>; // loading?
    }
  }


  private loadCreationFromSlug(slug: string) {
    const contentService = ServiceRegistry.getContentService();
    contentService
      .getCreationBySlug(slug)
      .then((creation) => {
        this.setState({creation});
      })
      .catch((e) => {
        console.log(e.sys || e);
        this.setState({notFound: true});
      });
  }

}
