import ContentService from "./ContentService";

import ContentfulContentServiceBuilder from "./contentful/ContentfulContentServiceBuilder";
import RouteRegistry from "./RouteRegistry";
import CachedContentService from "./CachedContentService";

class ServiceContainer {
  private contentService: ContentService;
  private routeRegistry: RouteRegistry;

  public init(locale: string): void {
    const contentful =  new ContentfulContentServiceBuilder(
        process.env.REACT_APP_SPACE_ID || '',
        process.env.REACT_APP_CONTENT_DELIVERY_TOKEN || '')
      .withHost(process.env.REACT_APP_CONTENT_DELIERY_HOST || '')
      .withLocale(locale)
      .build();

    if (process.env.REACT_APP_CONTENT_CACHE_ENABLED === 'true') {
      const ttl = parseInt(process.env.REACT_APP_CONTENT_CACHE_TTL_MS || "300000", 10);
      this.contentService = new CachedContentService(contentful, ttl)
    } else {
      this.contentService = contentful;
    }

    this.routeRegistry = new RouteRegistry();
  }

  public getContentService(): ContentService {
    return this.contentService;
  }

  public getRouteRegistry(): RouteRegistry {
    return this.routeRegistry;
  }

  public setLocale(locale: string): void {
    this.contentService.setLocale(locale);
  }
}

const ServiceRegistry: ServiceContainer = new ServiceContainer();

export default ServiceRegistry;