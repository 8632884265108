import styled from "react-emotion";
export interface SectionInterface {
  theme?: any;
  large?: boolean;
}

export const Spacer = styled("div")(({ theme, large }: SectionInterface) => ({
  marginBottom: large ? theme.spacing.unit * 6 : theme.spacing.unit * 3
}));

export const SpacerTopBottom = styled("div")(({ theme }: SectionInterface) => ({
  marginBottom: theme.spacing.unit * 4,
  marginTop: theme.spacing.unit * 4
}));
