import ImageInfo from "./ImageInfo";

export default class CrewMember {
  public readonly id: string;
  public readonly name: string;
  public readonly position: string;
  public readonly profileImage: ImageInfo;
  public readonly contact?: string;

  constructor(
    id: string,
    name: string,
    position: string,
    profileImage: any,
    contact?: string,
  ) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.profileImage = profileImage;
    this.contact = contact;
  }
}
