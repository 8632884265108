import RouteInfo from "../domain/RouteInfo";

export default class RouteRegistry {
  private readonly byPath: { [path: string]: { pageId: string, locale: string } };
  private readonly byPageId: { [pageId: string]: { [locale: string]: string } };
  private readonly byLocale: { [locale: string]: { [path: string]: string } };
  private readonly pathAndPageIds: RouteInfo[];
  private readonly locales: string[];

  constructor() {
    this.byPath = {};
    this.byPageId = {};
    this.byLocale = {};
    this.pathAndPageIds = [];
    this.locales = [];
  }

  public init(routeInfos: RouteInfo[]): void {
    const locales = {};
    for (const routeInfo of routeInfos) {
      const {locale, pageId, path}  = routeInfo;

      this.byPath[path] = {pageId, locale};

      if (!this.byPageId[pageId]) {
        this.byPageId[pageId] = {};
      }
      this.byPageId[pageId][locale] = path;

      if (!this.byLocale[locale]) {
        this.byLocale[locale] = { path: pageId };
      } else {
        this.byLocale[locale][path] = pageId;
      }

      this.pathAndPageIds.push(routeInfo);

      if (!this.locales[locale]) {
        locales[locale] = true;
      }
    }
    Object.keys(locales).forEach(locale => this.locales.push(locale));
  }

  public getRoutesByLocale(locale: string): { [path: string]: string } {
    return this.byLocale[locale];
  }

  public getPathByPageIdAndLocale(pageId: string, locale: string): string|undefined {
    const paths = this.byPageId[pageId];
    return (paths) ? paths[locale] : undefined;
  }

  public getPageAndLocaleByPath(path: string): { pageId: string, locale: string } {
    return this.byPath[path];
  }

  public getAllRoutes(): RouteInfo[] {
    return this.pathAndPageIds
  }

  public getLocales(): string[] {
    return this.locales;
  }

  /**
   * Try to find the equivalent for the current path in the new locale.
   *
   * We will try to find the PageAndLocale of the current path exactly.
   * If we find it, we will look up the path with the same pageId and the
   * new locale.
   *
   * If we don't find it exactly, we will split the path into prefix and
   * suffix. We will then try to find the PageAndLocale of the prefix. We
   * will continue to split the prefix into prefix and suffix until the
   * prefix is empty or we find a match.
   * Afterwards we will return new path + suffix.
   *
   * /en/artists/john-doe -> not match
   * /en/artists , john-doe
   *   -> prefix matches, we find /artists for the same pageId
   *   -> new path is /artists/john-doe
   */
  public translatePath(sourcePath: string, targetLocale: string): string|undefined {
    let targetPath;
    let prefix = sourcePath;
    let suffix = "";
    while (!targetPath && prefix && prefix.length > 0) {
      const prefixPageAndLocale = this.getPageAndLocaleByPath(prefix);
      if (prefixPageAndLocale) {
        targetPath = this.getPathByPageIdAndLocale(
          prefixPageAndLocale.pageId,
          targetLocale);
      }
      if (!targetPath) {
        const matches = /^(.*)\/([^\/]+)$/.exec(prefix);
        if (matches && matches.length > 2) {
          prefix = matches[1];
          suffix = suffix ? `${matches[2]}/${suffix}` : matches[2];
        }
      } else {
        if (suffix) {
          targetPath = `${targetPath}/${suffix}`;
        }
      }
    }
    return targetPath;
  }
}
