import React, {Component} from "react";
import {Col, Row} from "react-flexbox-grid";
import ImageInfo from "../domain/ImageInfo";

import {FlexRow} from "./FlexRow";
import HeadlineWithIcon from "./HeadlineWithIcon";
import RichText from "./RichText";
import ImageCaption from "./ImageCaption";

import PageElement from "../domain/PageElement";
import {AnchorButton} from "./Button";
import AspectRatioImage from "./AspectRatioImage";
import {hiddenXs} from "src/constants/flexBoxGrid";
import {Section} from "./Section";
import {Spacer} from "./Spacer";
import Copyright from "./Copyright";
import styled from "react-emotion";

export interface Props {
  id: string;
  actionLabel?: string;
  actionLink?: string;
  contactMail?: string;
  contactPhone?: string;
  image?: ImageInfo;
  personDescription?: string;
  personName?: string;
  text?: any;
  title?: string;
}

const AspectRatioImageWrapper = styled("div")({
  position:"relative",
  width: "100%",
  height: "100%"
});

export interface State {
}

export default class JobsProfile extends Component<Props, State>
  implements PageElement {
  public render(): JSX.Element {
    const {title, text} = this.props;

    return (
      <Section>
        <Spacer>
          <HeadlineWithIcon
            id={this.props.id}
            email={this.props.contactMail}
            phone={this.props.contactPhone}
          >
            {title}
          </HeadlineWithIcon>
        </Spacer>
        <Row>
          <Col xs={12} sm={8}>
            <FlexRow>
              <RichText text={text}/>
            </FlexRow>
            {this.props.actionLink && (
              <AnchorButton href={`${this.props.actionLink}`}>
                {this.props.actionLabel}
              </AnchorButton>
            )}
          </Col>
          <Col sm={4} className={hiddenXs}>
            {this.props.image && (
              <FlexRow>
                <AspectRatioImageWrapper>
                  <AspectRatioImage url={this.props.image.url} title={title}/>
                  <Copyright title={this.props.image.title}/>
                </AspectRatioImageWrapper>
              </FlexRow>
            )}
            <FlexRow>
              <Col>
                <ImageCaption
                  name={this.props.personName}
                  description={this.props.personDescription}
                />
              </Col>
            </FlexRow>
          </Col>
        </Row>
      </Section>
    );
  }
}
