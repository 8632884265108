export default class LabelConfig {
  public readonly name: string
  public readonly tourTyp: string
  public readonly tourLocation: string
  public readonly hideTour: boolean
  public readonly isOnTour: boolean

  constructor(
    name: string,
    tourTyp: string,
    tourLocation: string,
    hideTour: boolean,
    isOnTour: boolean,
  ) {
    this.name = name;
    this.tourTyp = tourTyp;
    this.tourLocation = tourLocation;
    this.hideTour = hideTour
    this.isOnTour = isOnTour
  }
}
