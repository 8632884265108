import React from "react";
import styled from "react-emotion";
import {AspectRatio} from "./Billboard";
import IframeBlocker from "./IframeBlocker";

interface Props {
  url: string;
}

const Root = styled("div")({
  position: "relative",
  paddingBottom: AspectRatio.a16to9,
  paddingTop: 24,
  height: 0,
  "& iframe": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }
});

const getId = (url: string) => {
  const id = url.split("com/")[1];
  return id;
};

const VimeoEmbed = ({url}: Props) => {
  const id = getId(url);

  return (
    <Root>
      <IframeBlocker type={'vimeo'} url={url}>
      <iframe
        src={`https://player.vimeo.com/video/${id}?h=0711b98014&color=cccccc&portrait=0`}
        width="640" height="360" frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen>
      </iframe>
      </IframeBlocker>
    </Root>
  );
};

export default VimeoEmbed;
