import React, {Component} from "react";
import "intersection-observer";
import styled, {keyframes} from "react-emotion";

interface State {
  isVisible: boolean;
}

interface Props {
  handleChange?: any;
}

const Loader = styled("div")(({active}: any) => {
  return {
    color: "#9e9e9e",
    display: active ? "flex" : "none",
    justifyContent: "center"
  };
});

const spin = keyframes`
  0% { transform: rotate(0deg)}
  100% { transform: rotate(360deg)}
`;

const Spinner = styled("div")({
  width: 80,
  height: 80,
  border: "2px solid #f3f3f3",
  borderTop: "4px solid #9e9e9e",
  borderRadius: "100%",
  animationName: spin,
  animationDuration: "1s",
  animationIterationCount: "infinite",
  animationTimingFunction: "linear"
});

class EventLoader extends Component<Props, State> {
  public observer: IntersectionObserver;
  public reference: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      isVisible: false
    };

    this.reference = React.createRef();
    this.observer = new IntersectionObserver(this.setVisibility);
  }

  public componentDidMount() {
    //@ts-ignore
    this.observer.observe(this.reference.current);
  }

  public componentWillUnmount() {
    this.observer.disconnect();
  }


  public setVisibility = (entries: any) => {
    if (entries[0].isIntersecting && !!this.props.handleChange) {
      this.props.handleChange();
      this.setState({
        isVisible: true
      });
    } else if (!entries[0].isIntersecting) {
      this.setState({
        isVisible: false
      });
    }
  };

  public render(): JSX.Element {
    return (
      <div ref={this.reference}>
        <Loader active={!this.state.isVisible}>
          <Spinner/>
        </Loader>
      </div>
    );
  }
}

export default EventLoader;
