import * as React from "react";
import {Component} from "react";
import {
  TileContent,
  TileContainerNoLink
} from "../../components/Tile/Tile";
import CrewMember from "../../domain/CrewMember";
import styled from "react-emotion";
import getIconComponent from "../../components/icons";
import {TileCrewHeadLine} from "../../components/Tile/TileCrewHeadLine";

interface TileLabelInterface {
  theme?: any;
}


const TileLabelWrapper = styled("div")(({theme}: TileLabelInterface) => ({
  marginLeft: '-6px',
  marginBottom: '-10px',
  position: "relative",
  ":before": {
    content: '""',
    position: "absolute",
    top: "4px",
    left: 0,
    backgroundColor: theme.palette.primary.main,
    width: "6px",
    height: "100%",
    transform: "skewY(20deg)",
    zIndex: 0,
  },
}));

const ActionIcon = styled("a")({
  display: "inline-flex",
  marginLeft: "8px",
  marginTop: "1px",
  svg:{
    position: "relative",
    top: "2px"
  }
});


export interface Props {
  person: CrewMember | any;
  labelComponent?: any;
  link?: any;
  style?: any;
}

class CrewGridTile extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    const {person} = this.props;

    if (!person) {
      //@ts-ignore
      return null;
    }
    return (
      <TileContainerNoLink
        id={person.id}
        data-background-image-url={
          person.profileImage && person.profileImage.url
        }
        title={person.name}
      >

        <TileContent>
          <TileLabelWrapper>
            <TileCrewHeadLine>
              {person.name}
              {person.contact && <ActionIcon href={`mailto:${person.contact}`}>
                {getIconComponent("mailContact")}
              </ActionIcon>}
            </TileCrewHeadLine>
          </TileLabelWrapper>
          {this.props.labelComponent}
        </TileContent>
      </TileContainerNoLink>
    );
  }
}

export default CrewGridTile;
