import * as React from "react";
import { Component } from "react";
import NavigationData from "../../domain/Navigation";
import ServiceRegistry from "../../services/ServiceRegistry";

import NavigationListItem from "./NavLink";
import NavigationList from "./NavigationList";

interface Props {}
export interface State {
  navigation: NavigationData;
}

export default class Navigation extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = { navigation: new NavigationData() };
  }

  public componentDidMount(): void {
    ServiceRegistry.getContentService()
      .getNavigation()
      .then(navigation => {
        this.setState({ navigation });
      });
  }

  public render(): JSX.Element {
    return (
      <NavigationList>
        {this.state.navigation.items &&
          this.state.navigation.items.map(item => (
            <NavigationListItem exact={true} key={item.label} to={item.link}>
              {item.label}
            </NavigationListItem>
          ))}
      </NavigationList>
    );
  }
}
