import * as React from "react";

export default () => (
  <span style={{
     marginTop: '2px',
     marginLeft: '4px'
  }}>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 24 24"
  >
    <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/>
  </svg>
  </span>
);
