import ImageInfo from "./ImageInfo";

export default class PageElementColumn {
  public readonly id: string;
  public readonly title: string;
  public readonly text: any;
  public readonly image?: ImageInfo;
  public readonly actionLabel: string;
  public readonly actionLink: string;

  constructor(
    id: string,
    title: string,
    text: any,
    actionLabel: string,
    actionLink: string,
    image?: ImageInfo
  ) {
    this.id = id;
    this.title = title;
    this.text = text;
    this.actionLabel = actionLabel;
    this.actionLink = actionLink;
    this.image = image;
  }
}
