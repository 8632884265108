import React from "react";

const facebookBoxed = () => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="footer"
        transform="translate(-340.000000, -376.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g
          id="facebook-svgrepo-com-3"
          transform="translate(340.000000, 376.000000)"
        >
          <path
            d="M29.968254,0 L10,0 C4.50793651,0 0,4.50793651 0,10.031746 L0,30 C0,35.4920635 4.50793651,40 10,40 L29.968254,40 C35.4920635,40 40,35.4920635 40,29.968254 L40,10.031746 C40,4.50793651 35.4920635,0 29.968254,0 Z M25.3650794,20 L21.5873016,20 L21.5873016,32.3809524 L16.8253968,32.3809524 L16.8253968,20 L14.2857143,20 L14.2857143,14.9206349 L16.5079365,14.9206349 L16.5079365,12.7619048 C16.5079365,10.7301587 17.5238095,7.52380952 21.8095238,7.52380952 L25.7142857,7.52380952 L25.7142857,11.7460317 L22.952381,11.7460317 C22.5079365,11.7460317 21.9047619,12.031746 21.9047619,13.015873 L21.9047619,14.9206349 L25.8095238,14.9206349 L25.3650794,20 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default facebookBoxed;
