import * as React from "react";
import { Component } from "react";
import Artist from "../../domain/Artist";
import ContentService from "../../services/ContentService";
import ServiceRegistry from "../../services/ServiceRegistry";
import ArtistJumpBar from "./ArtistJumpBar";
import ArtistGridSection from "./ArtistGridSection";
import { Row, Col } from "react-flexbox-grid";
import PrerenderingHelper from "protofy-prerender";
import { Section } from "src/components/Section";
import { BackgroundLazyLoader } from "../../functions/lazyLoad.js";
import { Context } from "./../../FilterContext";

export interface Props {
  title: string;
  data: any;
}

export interface State {
  artists?: Artist[];
  alphabet: string[];
}

export default class ArtistList extends Component<Props, State> {
  private contentService: ContentService;

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZ#".split("")
    };

    this.contentService = ServiceRegistry.getContentService();
  }

  public componentDidMount(): void {
    PrerenderingHelper.waitFor(this);
    this.contentService
      .getAllArtists()
      .then((artists) => this.setState({ artists }));
  }

  public componentDidUpdate(): void {
    if (this.state.artists) {
      PrerenderingHelper.onNextFrameDone(this);
    }
    BackgroundLazyLoader();
  }

  public includes(arr: string[], obj: string) {
    return arr.indexOf(obj) !== -1;
  }

  public render(): JSX.Element {
    const artists: Artist[] = this.state.artists || [];
    const alphabet: string[] = this.state.alphabet;
    const mappedArtists = new Map();

    let filteredArtists;

    if (
      this.props.data.data.activeFilter === "On Tour" ||
      this.props.data.data.activeFilter === "Auf Tour"
    ) {
      filteredArtists = artists.filter((artist) => (artist.isOnTour === true && !artist.formerArtist));
    } else if (this.props.data.data.historyActive === true) {
      filteredArtists = artists.filter(
        (artist) => artist.formerArtist === true
      );
    } else if (this.props.data.data.searchValue !== "") {
      filteredArtists = artists.filter((artist) =>
        artist.name
          .toLowerCase()
          .includes(this.props.data.data.searchValue.searchValue.toLowerCase())
      );
    } else {
      filteredArtists = artists;
    }
    filteredArtists.map((artist) => {
      let firstChar = artist.name.charAt(0).toUpperCase();

      // Sort names with special characters or numbers in the A-block
      if (!this.includes(alphabet, firstChar)) {
        firstChar = "A";
      }

      const existing = mappedArtists.get(firstChar);

      let data: Artist[] = [];
      if (existing && Array.isArray(existing)) {
        data = mappedArtists.get(firstChar);
      }

      data.push(artist);
      mappedArtists.set(firstChar, data);
    });

    return (
      <>
        <Row>
          {/* Artists alphabetical navigation */}
          <Col xs={12}>
            <ArtistJumpBar artistMap={mappedArtists} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Section>
              <Context.Consumer>
                {(context) =>
                  context && (
                    <>
                      {alphabet.map((char) => {
                        const artistMap: Artist[] = mappedArtists.get(char);
                        return (
                          artistMap && (
                            <ArtistGridSection
                              key={char}
                              character={char}
                              artists={artistMap}
                            />
                          )
                        );
                      })}
                    </>
                  )
                }
              </Context.Consumer>
            </Section>
          </Col>
        </Row>
      </>
    );
  }
}
