import HeaderPart from "./HeaderPart";

export default class Creation {
  public readonly id: string;
  public readonly name: string;
  public readonly slug: string;
  public readonly introduction: string;
  public readonly description: string;
  public readonly profileImage: any;
  public readonly imageGallery: any[];
  public readonly contact: string;
  public readonly headerParts: HeaderPart[];
  public readonly website: string;
  public readonly videoDescription: string;
  public readonly youtubeLink: string;
  public readonly vimeoLink: string;

  public readonly seoTitle: string;
  public readonly seoDescription: string;
  public readonly seoNoIndex: boolean;

  public readonly seoFacebookProfileId: string;
  public readonly seoUsername: string;
  public readonly seoFirstName: string;
  public readonly seoLastName: string;
  public readonly seoGender: string;

  constructor(
    id: string,
    name: string,
    slug: string,
    introduction: string,
    description: string,
    profileImage: any,
    imageGallery: any[],
    contact: string,
    headerParts: any,
    website: string,
    videoDescription: string,
    youtubeLink: string,
    vimeoLink: string,
    seoTitle: string,
    seoDescription: string,
    seoNoIndex: boolean,
    seoFacebookProfileId: string,
    seoUsername: string,
    seoFirstName: string,
    seoLastName: string,
    seoGender: string
  ) {
    this.id = id;
    this.name = name;
    this.slug = slug;
    this.introduction = introduction;
    this.description = description;
    this.profileImage = profileImage;
    this.imageGallery = imageGallery;
    this.contact = contact;
    this.headerParts = headerParts;
    this.website = website;
    this.videoDescription = videoDescription;
    this.youtubeLink = youtubeLink;
    this.vimeoLink = vimeoLink;
    this.seoTitle = seoTitle;
    this.seoDescription = seoDescription;
    this.seoNoIndex = seoNoIndex;
    this.seoFacebookProfileId = seoFacebookProfileId;
    this.seoUsername = seoUsername;
    this.seoFirstName = seoFirstName;
    this.seoLastName = seoLastName;
    this.seoGender = seoGender;
  }
}
