import Arrow from "./arrow";
import facebook from "./facebook";
import instagram from "./Instagram";
import website from "./website";
import youtube from "./youtube";
import twitter from "./Twitter";
import mail from "./mail";
import mailContact from "./mailContact";
import filter from "./filter";
import vip from "./vip";
import phone from "./phone";
import tiktok from "./tiktok";
import spotify from "./spotify";

const Icons = {
  Arrow,
  facebook,
  instagram,
  vip,
  twitter,
  mail,
  mailContact,
  filter,
  website,
  youtube,
  phone,
  tiktok,
  spotify
};

const getIconComponent = (key: any) => {
  return Icons[key];
};

export default getIconComponent;
