export default class FooterColumn {
  public readonly id: string;
  public readonly title: string;
  public readonly text: any;
  public readonly actionLink: any;
  public readonly actionLabel: any;

  constructor(
    id: string,
    title: string,
    text: any,
    actionLink: any,
    actionLabel: string
  ) {
    this.id = id;
    this.title = title;
    this.text = text;
    this.actionLink = actionLink;
    this.actionLabel = actionLabel;
  }
}
