import React from "react";
import Moment from "react-moment";
import { Col, Row } from "react-flexbox-grid";
import { FlexRow } from "./FlexRow";
import styled from "react-emotion";
import { LightText } from "./LightText";

interface DateItemInterface {
  date: string;
  optional?: string;
  style?: any;
  locale: string;
}
interface DayNumInterface {
  theme?: any;
}

const DayNum = styled("span")((props: DayNumInterface) => ({
  fontFamily: props.theme.typography.headlineFontFamily,
  lineHeight: "normal",
  fontSize: "2.7em",
  fontWeight: "normal",
  display: "inline-block",
  marginTop: -8,
  marginBottom: -15
}));

const DateItem = ({ date, locale, optional, style }: DateItemInterface) => {
  
  return (
    <Row style={style}>
      <Col xs={4}>
        <DayNum>
          <Moment locale={locale} format="DD.">
            {date}
          </Moment>
        </DayNum>
      </Col>

      <Col xs={8}>
        {/* month year */}
        <FlexRow>
          <Col>
            <strong>
              <Moment locale={locale} format="MMM">
                {date}
              </Moment>
            </strong>
          </Col>
          <Col>&nbsp;</Col>
          <Col>
            <strong>
              <Moment locale={locale} format="YYYY">
                {date}
              </Moment>
            </strong>
          </Col>
        </FlexRow>
        {/* weekday / optional */}
        <FlexRow>
          <Col>
            <LightText>
              {optional ? (
                <span>{optional}</span>
              ) : (
                <Moment locale={locale} format="dddd">
                  {date}
                </Moment>
              )}
            </LightText>
          </Col>
        </FlexRow>
      </Col>
    </Row>
  );
};

export { DateItem };
