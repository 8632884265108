import ImageInfo from "./ImageInfo";
import Artist from "./Artist";

export default class HeaderPart {
  public readonly title: string;
  public readonly imageWide?: ImageInfo;
  public readonly imageTablet?: ImageInfo;
  public readonly imagePhone?: ImageInfo;
  public readonly youtubeVideoLink?: string;
  public readonly artist?: Artist;

  constructor(
    title: string,
    imageWide?: ImageInfo,
    imageTablet?: ImageInfo,
    imagePhone?: ImageInfo,
    youtubeVideoLink?: string,
    artist?: Artist
  ) {
    this.title = title;
    this.imageWide = imageWide;
    this.imageTablet = imageTablet;
    this.imagePhone = imagePhone;
    this.youtubeVideoLink = youtubeVideoLink;
    this.artist = artist;
  }
}
