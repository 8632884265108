import styled from "react-emotion";

interface ImageProps {
  url: string;
  theme?: any;
}

const AspectRatioImage = styled("div")((props: ImageProps) => ({
  width: "100%",
  paddingTop: "100%",
  position: "relative",
  backgroundImage: `url(${props.url})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center"
}));

export default AspectRatioImage;
