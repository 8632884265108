import {Component} from "react";
import PageElement from "../domain/PageElement";
import * as React from "react";
import KnowHowColumn from "./KnowHowColumn";
import PageElementColumn from "../domain/PageElementColumn";
import {Row, Col} from "react-flexbox-grid";
import {SectionHeadline} from "./Typography";
import styled from "react-emotion";
import {Section} from "./Section";
import {EqualHeight} from "react-equal-height";

interface StyleProps {
  theme?: any;
}

const StyledCol = styled(Col)((props: StyleProps) => ({}));


export interface Props {
  title: string;
  columns: PageElementColumn[];
}

export interface State {
}

export default class KnowHow extends Component<Props, State>
  implements PageElement {
  public render(): JSX.Element {
    const {title, columns} = this.props;
    return (
      <Section>
        <SectionHeadline>{title}</SectionHeadline>
        <Row>
          <EqualHeight>
            {columns.map(item => (
              <StyledCol xs={12} sm={6} lg={3}  key={item.id} style={{marginTop: "8px"}}>
                <KnowHowColumn
                  title={item.title}
                  text={item.text}
                  actionLabel={item.actionLabel}
                  actionLink={(item.actionLink || "").replace(
                    "https://neuland-concerts.com",
                    ""
                  )}
                  image={item.image}
                />
              </StyledCol>
            ))}
          </EqualHeight>
        </Row>
      </Section>
    );
  }
}
