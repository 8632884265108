import * as React from "react";
import { Section } from "../../components/Section";
import { Row, Col } from "react-flexbox-grid";
import StaticTextService from "../../services/StaticTextService";
import moment from "moment";
import UpcomingEventsWidgetItem from "../../components/UpcomingEventsWidgetItem";
import ArtistTourEvent from "../../domain/ArtistTourEvent";
import YearSelect from "./YearSelect";
import styled from "react-emotion";

export interface OwnProps {
  historyCategories: string[];
  mappedCategorizedEvents: Map<string, ArtistTourEvent[]>;
}

export interface State {
  activeYear?: string;
  years?: string[];
}

type Props = OwnProps;

const SectionHeadline = styled("h3")((props: any) => ({
  fontFamily: props.theme.typography.fontFamilyCondensedBold,
  fontWeight: "normal",
  fontSize: "1.625rem"
}));

const SelectContainer = styled("div")((props: any) => ({
  display: "flex",
  margin: `0 ${-props.theme.spacing.unit}px`
}));

class PastEventsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const currentYear = moment().format("YYYY");

    const years = props.historyCategories
      .map((cat) => moment(cat).format("YYYY"))
      .reverse();

    const setActiveYear = years[0] === currentYear ? currentYear : years[0];

    this.state = {
      activeYear: setActiveYear
    };
  }

  public componentDidMount() {
    const { historyCategories } = this.props;
    const years = historyCategories.map((cat) => moment(cat).format("YYYY"));

    if (Boolean(years.length)) {
      const uniqueYearStrings = [...Array.from(new Set(years))].reverse();
      this.setState({ years: uniqueYearStrings });
    }
  }

  public selectYear = (newYear: string) => {
    this.setState({
      activeYear: newYear
    });
  };

  public render() {
    const { historyCategories, mappedCategorizedEvents } = this.props;
    const { years } = this.state;

    if (!this.state.years) {
      return null;
    }
    return (
      <Section>
        <Row>
          <Col xs={12}>
            <h2>{StaticTextService.getPastEventsText()}</h2>
          </Col>
        </Row>

        <SelectContainer>
          {years &&
            years.map((year, index) => {
              return (
                <YearSelect
                  category={year}
                  handleSelectYear={this.selectYear}
                  active={this.state.activeYear === year}
                  key={`${year}-${index}`}
                >
                  {year}
                </YearSelect>
              );
            })}
        </SelectContainer>

        {historyCategories.map((catKey) => {
          const catYear = moment(catKey).format("YYYY");

          if (catYear !== this.state.activeYear) {
            return null;
          }

          return (
            <React.Fragment key={catKey}>
              <SectionHeadline>{moment(catKey).format("MMMM")}</SectionHeadline>
              {mappedCategorizedEvents
                .get(catKey)
                .map(
                  (event, eKey) =>
                    event && (
                      <UpcomingEventsWidgetItem
                        key={catKey + eKey}
                        event={event}
                        renderDate={true}
                        renderCTA={false}
                      />
                    )
                )}
            </React.Fragment>
          );
        })}
      </Section>
    );
  }
}

export default PastEventsList;
