import * as React from "react";
import { Component } from "react";
import styled from "react-emotion";
import ServiceRegistry from "./../../services/ServiceRegistry";
import Moment from "react-moment";

interface Props {
  active?: boolean;
  title: string;
  onClick?: any;
  moment?: boolean;
}

interface State {
  selectedContent: any;
}

const MultiSelectItem = styled("li")(({ active = false }: any) => {
  return {
    padding: "10px 10px",
    borderBottom: "1px solid #757575",
    backgroundColor: `${active ? "#eeeeee" : "#ffffff"}`,
    fontFamily: "Roboto",
    userSelect: "none",
    cursor: "pointer",

    ":last-of-type": {
      border: "none",
      paddingBottom: "0"
    },

    ":first-of-type": {
      paddingTop: "10px"
    }
  };
});

export default class DropdownMultiSelectItem extends Component<Props, State> {
  public state = { selectedContent: null };

  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    const locale = ServiceRegistry.getContentService().getLocale();

    return (
      <MultiSelectItem
        onClick={this.toggleSelectedContent}
        active={this.props.active}
      >
        {this.props.moment && (
          <Moment locale={locale} format="MMMM YYYY">
            {this.props.title}
          </Moment>
        )}
        {!this.props.moment && <>{this.props.title}</>}
      </MultiSelectItem>
    );
  }

  public toggleSelectedContent = (event: React.SyntheticEvent) => {
    this.props.onClick(this.props.title);
    if (this.state.selectedContent === null) {
      this.setState({
        selectedContent: this.props.title
      });
    } else {
      this.setState({
        selectedContent: null
      });
    }
  };
}
