import * as React from "react";

import styled from "react-emotion";
import Helmet from "react-helmet";
import SubHeadlineWithIcon from "./../components/HeadlineWithIcon";
import { Spacer } from "./../components/Spacer";
import { RouteComponentProps } from "react-router-dom";
import { Grid, Row, Col } from "react-flexbox-grid";
import StaticTextService from "../services/StaticTextService";

interface Props extends RouteComponentProps<{}> {}

const Wrapper = styled(Grid)({
  marginTop: 50,
  marginBottom: 75,
  "@media (min-width: 576px)": {
    marginTop: 75,
    marginBottom: 100
  },
  "@media (min-width: 992px)": {
    marginTop: 100,
    marginBottom: 100
  },
  "& a": {
    color: "#000"
  },
  "& a:visited": {
    color: "#000"
  }
});

const ContactCard = styled("div")({
  color: "#fff",
  backgroundColor: "#000",
  padding: 20,
  display: "flex",
  flexDirection: "column",
  margin: "30px 0 50px",
  maxWidth: 400
});

const ContactInfos = styled("p")({
  marginBlockStart: "0",
  marginBlockEnd: "0",
  marginTop: "0.2em",
  marginBottom: "0.2em"
});

const ContactTitle = styled("p")({
  marginBlockStart: "0",
  marginBlockEnd: "0",
  marginTop: "0.3em",
  fontSize: "12px",
  marginBottom: "1.5em"
});

interface State {}

class NewsLetterPage extends React.Component<Props, State> {
  public componentDidMount() {
    !(function(e, t, n, c, r, a, i) {
      (e.Newsletter2GoTrackingObject = r),
        (e[r] =
          e[r] ||
          function() {
            (e[r].q = e[r].q || []).push(arguments);
          }),
        (e[r].l = 1 * new Date()),
        (a = t.createElement(n)),
        (i = t.getElementsByTagName(n)[0]),
        (a.async = 1),
        (a.src = c),
        i.parentNode.insertBefore(a, i);
    })(
      window,
      document,
      "script",
      "https://static.newsletter2go.com/utils.js",
      "n2g"
    );
    const config = {
      container: { type: "div", class: "", style: "" },
      row: { type: "div", class: "", style: "margin-top: 15px;" },
      columnLeft: { type: "div", class: "", style: "" },
      columnRight: { type: "div", class: "", style: "" },
      label: { type: "label", class: "", style: "" }
    };
    n2g("create", "dejn10c3-7fhwq02f-546");
    n2g("subscribe:createForm", config);
  }

  public render() {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
          <title>{StaticTextService.getNewsletterPageTitle()}</title>
        </Helmet>
        <Wrapper>
          <Spacer>
            <SubHeadlineWithIcon>Newsletter Anmeldung</SubHeadlineWithIcon>
          </Spacer>
          <Row>
            <Col xs={12} lg={6}>
              <h2>Keine Ankündigung mehr verpassen!</h2>
              <h3>
                <em>Die erste Reihe entscheidet sich hier.</em>
              </h3>
              <p>
                Erfahre als Erste/Erster von neuen Konzerten und Tourneen und wo
                Du am günstigsten an Tickets kommst. Verschwende Deine Zeit
                nicht mit recherchieren und bezahle keine überflüssigen
                Gebühren.
              </p>
              <p>Trag Dich jetzt für unseren NEWSLETTER ein!</p>
              <p>
                <em>Dein Neuland Team</em>
              </p>
            </Col>
            <Col xs={12} lg={6}>
              <div id="n2g_script" className={"newsletter-n2g"} />
            </Col>
          </Row>
        </Wrapper>
      </>
    );
  }
}

export default NewsLetterPage;
