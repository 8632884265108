import HeaderPart from "./HeaderPart";

export enum HeaderType {
  Large,
  Medium,
  MediumWithHeadlines,
  FullHeight,
  Default
}

export default class Header {
  public readonly type: HeaderType;
  public readonly parts: HeaderPart[];
  public readonly headlines?: string[] | [];

  constructor(type: HeaderType, parts: HeaderPart[], headlines?: string[]) {
    this.type = type;
    this.parts = parts;
    this.headlines = headlines;
  }
}
