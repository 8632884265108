import * as React from "react";
import {Component} from "react";
import HeaderPart from "../../domain/HeaderPart";
import {
  BillboardContainer as Billboard,
  AspectRatio
} from "../Billboard";
// @ts-ignore
import SwipeableViews from "react-swipeable-views";
import {Grid, Row, Col} from "react-flexbox-grid";
import styled from "react-emotion";
import {BillboardSubheadline} from "../Billboard/BillboardHeadline";
import Artist from "../../domain/Artist";
import {withRouter} from "react-router";

export interface Props {
  parts: HeaderPart[];
  history: any;
}

interface DotsProps {
  active: boolean;
  theme?: any;
}

const DotsContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  paddingTop: 16,
  paddingBottom: 16
});

const Dot = styled("div")((props: DotsProps) => ({
  width: 7,
  height: 7,
  borderRadius: "50%",
  backgroundColor: props.active
    ? props.theme.palette.common.black
    : props.theme.palette.grey[500],
  marginRight: 9,
  cursor: "pointer"
}));

export interface State {
  index: number;
}

class MediumHeader extends Component<Props, State> {
  public state = {
    index: 0
  };
  // @ts-ignore
  public slideTimeout: NodeJS.Timeout = undefined;
  public slideIntervalInMs = 5000;

  constructor(props: Props) {
    super(props);
    if (this.props.parts.length > 1) {
      this.activateSlideInterval();
    }
  }

  public activateSlideInterval() {
    this.slideTimeout = setTimeout(() => {
      const numParts = this.props.parts.length;
      const currentIndex = this.state.index;
      const nextIndex = currentIndex <= numParts - 2 ? currentIndex + 1 : 0;
      this.setState({index: nextIndex});
      this.activateSlideInterval();
    }, this.slideIntervalInMs);
  }

  public deactivateSlideInterval() {
    clearTimeout(this.slideTimeout);
  }

  public setIndex = (index: number) => {
    this.setState({index});
  };

  public onClickSlideControlElement(index: number) {
    this.deactivateSlideInterval();
    this.setIndex(index);
  }

  public routeToArtistPage(artist: Artist): void {
    if (artist) {
      this.props.history.push(`/artists/${artist.slug}`);
    }
  }

  public render(): JSX.Element {
    const {parts} = this.props;
    return (
      (parts && parts.length > 0) ? (
        <>
          <SwipeableViews
            axis={"x"}
            index={this.state.index}
            onChangeIndex={this.setIndex}
            enableMouseEvents={true}
          >
            {parts.filter((part, key) => part.imageWide).map((part, key) => (
              <Billboard
                key={key}
                aspect={AspectRatio.LandscapeSmall}
                imgSrc={part}
              >
                <>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "flex-end",
                      cursor: part.artist ? "pointer" : "initial"
                    }}
                    onClick={() => this.routeToArtistPage(part.artist as Artist)}
                  >
                    <Grid>
                      <Row>
                        <Col
                          xs={12}
                          style={{
                            // padding: 0 // to keep the alignment
                            paddingBottom: "5%"
                          }}
                        >
                          <BillboardSubheadline>
                            {part.title}
                          </BillboardSubheadline>
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                </>
              </Billboard>
            ))}
          </SwipeableViews>
          {parts.length > 1 && (
            <DotsContainer>
              {parts.map((part, index) => (
                <Dot
                  key={part.title}
                  active={this.state.index === index}
                  onClick={() => this.onClickSlideControlElement(index)}
                />
              ))}
            </DotsContainer>
          )}
        </>
      ) : <></>
    );
  }
}

export default withRouter(MediumHeader);
