import React from "react";

const phone = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icons/phone</title>
    <desc>Created with Sketch.</desc>
    <g
      id="icons/phone"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="phone" transform="translate(1.000000, 1.000000)" fill="#000000">
        <path
          d="M4.42444444,9.52111111 C6.18444444,12.98 9.02,15.8155556 12.4788889,17.5755556 L15.1677778,14.8866667 C15.51,14.5444444 15.9866667,14.4466667 16.4144444,14.5811111 C17.7833333,15.0333333 19.25,15.2777778 20.7777778,15.2777778 C21.4527925,15.2777778 22,15.8249853 22,16.5 L22,20.7777778 C22,21.4527925 21.4527925,22 20.7777778,22 C9.30252798,22 1.97372982e-15,12.697472 8.8817842e-16,1.22222222 C-1.97372982e-16,0.898068735 0.128769472,0.58719173 0.357980601,0.357980601 C0.58719173,0.128769472 0.898068735,5.42775701e-16 1.22222222,0 L5.5,0 C6.17501469,0 6.72222222,0.547207528 6.72222222,1.22222222 C6.72222222,2.75 6.96666667,4.21666667 7.41888889,5.58555556 C7.55333333,6.01333333 7.45555556,6.49 7.11333333,6.83222222 L4.42444444,9.52111111 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

export default phone;
