import ServiceRegistry from "./ServiceRegistry";

export default class StaticTextService {
  public static getBookingRequestText(): string {
    return this.options("Buchungsanfrage", "Booking Request");
  }

  public static getCurrentTourText(): string {
    return this.options("Tournee", "Current Tour");
  }

  public static getPastEventsText(): string {
    return this.options("Vergangene Veranstaltungen", "Past Events");
  }

  public static getUpcomingEventsText(): string {
    return this.options("Aktuelle Tourneen & Events", "Current Tours & Events");
  }

  public static getSoldOutText(): string {
    return this.options("Ausverkauft", "Sold Out");
  }

  public static getTicketsFromText(): string {
    return this.options("Tickets ab", "Tickets from");
  }

  public static getOnTourLabel(): string {
    return this.options("Auf Tour", "On Tour");
  }

  public static getNewsletterLabel(): string {
    return this.options("Newsletter abonnieren", "Subscribe to Newsletter");
  }

  public static getDefaultDescription(): string {
    return this.options(
      "Neuland Concerts ist eine Booking Agentur sowie Tour Promoter und bietet Dienstleistungen in allen Bereichen des Live Entertainments an.",
      "Neuland Concerts ist eine Booking Agentur sowie Tour Promoter und bietet Dienstleistungen in allen Bereichen des Live Entertainments an."
    );
  }

  public static getDeleteFilter(): string {
    return this.options("Filter löschen", "Delete filter");
  }

  public static getHistoryFilter(): string {
    return this.options("History", "History");
  }

  public static getFilterName(): string {
    return this.options("Filter", "Filter");
  }

  public static getFilterHint(): string {
    return this.options("Klicken um zu Filtern", "Click to Filter");
  }

  public static getAllArtists(): string {
    return this.options("Alle Künstler anzeigen", "Show all artists");
  }

  public static getArtistPlaceholder(): string {
    return this.options("Künstlername", "Artistname");
  }

  public static getCityPlaceholder(): string {
    return this.options("Stadt", "City");
  }

  public static getNewsletterPageTitle(): string {
    return this.options(
      "Newsletter Anmeldung - Neuland Concerts",
      "Newsletter Subscription - Neuland Concerts"
    );
  }

  public static getEmptyFilter(): string {
    return this.options(
      "Es sind leider keine passenden Veranstaltungen vorhanden.",
      "Unfortunately there are no matching events."
    );
  }

  public static renderCopyright(title: string): string {
    const regex = new RegExp(/(\[©).*(\])/);
    const copyright = title.match(regex);
    const returnValue = copyright && copyright[0] || '';
    return returnValue.split('[').join('').split(']').join('');
  }

  private static options(de: string, en: string): string {
    return ServiceRegistry.getContentService().getLocale() === "de" ? de : en;
  }
}
