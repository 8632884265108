import styled from "react-emotion";
import React, { Component } from "react";

const FirstCrossLine = styled("hr")({
  transform: "rotate(45deg)",
  margin: "11px 0 0 ",
  backgroundColor: "black",
  height: "1.5px",
  width: "18px",
  border: "none",
  position: "absolute",
  borderRadius: "5px"
});

const SecondCrossLine = styled("hr")({
  transform: "rotate(-45deg)",
  top: "11px",
  backgroundColor: "black",
  height: "1.5px",
  width: "18px",
  border: "none",
  position: "absolute",
  borderRadius: "5px",
  margin: "0",
  marginRight: 10
});

const CrossWrapper = styled("div")({
  display: "inline-block",
  position: "relative",
  cursor: "pointer",
  width: "25px",
  margin: "-2px 0 0 10px",
  right: 0
});

interface Props {
  onClick: any;
}

class FilterTagIcon extends Component<Props> {
  constructor(props: any) {
    super(props);
  }
  public render() {
    return (
      <CrossWrapper onClick={this.props.onClick}>
        <FirstCrossLine />
        <SecondCrossLine />
      </CrossWrapper>
    );
  }
}

export default FilterTagIcon;
