import * as React from "react";
import Element = JSX.Element;
import {Entry} from "contentful";
import PageElementColumnContentType from "./PageElementColumnContentType";
import PageElementColumn from "../../domain/PageElementColumn";
import PagePlaceholderContentType from "./PagePlaceholderContentType";
import FooterContentType from "./FooterContentType";
import NewsWidget from "../../components/NewsWidget";
import Claims from "../../components/Claims";
import Citation from "../../components/Citation";
import Panorama from "../../components/Panorama";
import JobsProfile from "../../components/JobsProfile";
import KnowHow from "../../components/KnowHow";
import Tickets from "../../components/Tickets";
import Footer from "../../components/Footer";
import FooterColumn from "../../domain/FooterColumn";
import UpcomingEventsWidget from "../../pages/UpcomingEvents";
import FeaturedArtistsContentType from "./FeaturedArtistsContentType";
import FeaturedArtists from "../../pages/ArtistList/FeaturedArtists";
import ContentTypeMapper from "./ContentTypeMapper";
import Hyperlink from "../../domain/Hyperlink";
import BookingWidget from "../../components/BookingWidget";
import PageElementContentType from "./PageElementContentType";
import ArtistList from "../../pages/ArtistList/ArtistList";
import JobsWidget from "../../components/JobsWidget";
import {Context} from "src/FilterContext";
import FeaturedCreationsContentType from "./FeaturedCreationsContentType";
import MultiColumnTextContentType from "./MultiColumnTextContentType";
import FeaturedCreations from "../../pages/Creations/FeaturedCreations";
import MultiColumnText from "../../pages/Creations/MultiColumnText";
import CrewGridContentType from "./CrewGridContentType";
import FeaturedCrewMember from "../../pages/CrewList/FeaturedCrewMember";

export default class PageElementMapper {
  public static pageElementColumn(
    entry: Entry<PageElementColumnContentType>
  ): PageElementColumn {
    const fields = entry.fields;
    return new PageElementColumn(
      entry.sys.id,
      fields.title,
      fields.text,
      fields.actionLabel,
      fields.actionLink,
      fields.image &&
      ContentTypeMapper.imageInfo(fields.image, 400, window.devicePixelRatio)
    );
  }

  public static pageElement(entry: Entry<any>, index: number): Element {
    const key = "_" + (index + 1); // also used for anchors

    if (!entry.sys.contentType) {
      return <></>
    }

    const contentType = entry.sys.contentType.sys.id;
    switch (contentType) {
      case "placeholder":
        return PageElementMapper.placeholder(key, entry as Entry<PagePlaceholderContentType>);
      case "element":
        return PageElementMapper.element(key, entry);
      case "featuredArtists":
        return PageElementMapper.featuredArtists(key, entry as Entry<FeaturedArtistsContentType>);
      case "pageCrewGrid":
        return PageElementMapper.crewGrid(key, entry as Entry<CrewGridContentType>);
      case "pageFeaturedCreations":
        return PageElementMapper.featuredCreations(key, entry as Entry<FeaturedCreationsContentType>);
      case "elementMultiColumns":
        return PageElementMapper.multiColumnText(key, entry as Entry<MultiColumnTextContentType>);
      case "footer":
        return PageElementMapper.footer(key, entry as Entry<FooterContentType>);
      default:
        throw new Error(`Unknown contentType: ${contentType}`);
    }
  }

  public static footer(key: string, entry: Entry<FooterContentType>): Element {
    return (
      <Footer
        key={key}
        columns={entry.fields.columns.map(PageElementMapper.footerColumn)}
      />
    );
  }

  public static footerColumn(
    entry: Entry<PageElementColumnContentType>
  ): FooterColumn {
    return new FooterColumn(
      entry.sys.id,
      entry.fields.title,
      entry.fields.text,
      entry.fields.actionLink,
      entry.fields.actionLabel
    );
  }

  private static placeholder(
    key: string,
    entry: Entry<PagePlaceholderContentType>
  ): Element {
    switch (entry.fields.type) {
      case "LatestNews":
        return <NewsWidget key={key} title={entry.fields.title}/>;
      case "NextTours": // @todo review mapping
        //@ts-ignore
        return <UpcomingEventsWidget key={key} title={entry.fields.title}/>;
      case "AllArtists":
        return (
          <Context.Consumer key={key}>
            {context =>
              context && (
                <ArtistList
                  key={key}
                  title={entry.fields.title}
                  data={context}
                />
              )
            }
          </Context.Consumer>
        );
      case "CurrentJobAds":
        return <JobsWidget key={key} title={entry.fields.title}/>;
      // case "Newsletter":
      //   return <NewsletterEmbed />;
      default:
        return <div key={key}>{JSON.stringify(entry)}</div>;
    }
  }

  private static element(
    key: string,
    entry: Entry<PageElementContentType>
  ): Element {
    const fields = entry.fields;

    switch (fields.type) {
      case "Claims":
        return (
          <Claims
            key={key}
            title={fields.title}
            columns={
              fields.columns
                ? fields.columns.map(PageElementMapper.pageElementColumn)
                : []
            }
          />
        );
      case "Citation":
        return (
          <Citation
            key={key}
            title={fields.title}
            text={fields.text}
            personName={fields.personName}
            personDescription={fields.personDescription}
            image={ContentTypeMapper.imageInfo(
              fields.image,
              400,
              window.devicePixelRatio
            )}
          />
        );
      case "Panorama":
        return (
          <Panorama
            key={key}
            title={fields.title}
            text={fields.text}
            image={
              fields.image && ContentTypeMapper.imageInfo(fields.image, 1000, 1)
            }
          />
        );
      case "Profile":
        return (
          <JobsProfile
            key={key}
            id={key}
            title={fields.title}
            actionLabel={fields.actionLabel}
            actionLink={fields.actionLink}
            contactMail={fields.contactMail}
            contactPhone={fields.contactPhone}
            image={
              fields.image &&
              ContentTypeMapper.imageInfo(
                fields.image,
                400,
                window.devicePixelRatio
              )
            }
            personDescription={fields.personDescription}
            personName={fields.personName}
            text={fields.text}
          />
        );
      case "KnowHow":
        return (
          <KnowHow
            key={key}
            title={fields.title}
            columns={fields.columns.map(PageElementMapper.pageElementColumn)}
          />
        );
      case "Tickets":
        return (
          <Tickets key={key} title={fields.title} columns={fields.columns}/>
        );
      case "Booking":
        return (
          <BookingWidget
            key={key}
            title={fields.title}
            text={fields.text}
            callToAction={new Hyperlink(fields.actionLink, fields.actionLabel)}
          />
        );
      case "HorizontalLine":
        return <hr key={key}/>;
      default:
        throw new Error(`Unknown: ${JSON.stringify(entry)}`);
    }
  }

  private static featuredArtists(
    key: string,
    entry: Entry<FeaturedArtistsContentType>
  ): Element {
    const fields = entry.fields;
    return (
      <FeaturedArtists
        key={key}
        title={fields.title}
        onTourLabel={fields.onTourLabel}
        artists={fields.artists.map(ContentTypeMapper.artist)}
        showMore={new Hyperlink(fields.showMoreUrl, fields.showMoreLabel)}
      />
    );
  }

 private static crewGrid(
    key: string,
    entry: Entry<CrewGridContentType>
  ): Element {
    const fields = entry.fields;

    console.log("fields", fields);
    return (
      <FeaturedCrewMember
        key={key}
        title={fields.title}
        crewMember={fields.crew.map(ContentTypeMapper.crewMember)}
      />
    );
  }

  private static featuredCreations(
    key: string,
    entry: Entry<FeaturedCreationsContentType>
  ): Element {
    const fields = entry.fields;
    return (
      <FeaturedCreations
        key={key}
        title={fields.title}
        creations={fields.creations.map(ContentTypeMapper.creation)}
        showMore={new Hyperlink(fields.showMoreUrl, fields.showMoreLabel)}
      />
    );
  }

  private static multiColumnText(
    key: string,
    entry: Entry<MultiColumnTextContentType>
  ): Element {
    const fields = entry.fields;
    return (
      <MultiColumnText
        key={key}
        headline={fields.headline}
        text1={fields.text1}
        text2={fields.text2}
        text3={fields.text3}
        text4={fields.text4}
      />
    );
  }
}
