import React from "react";

const website = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icons/website</title>
    <desc>Created with Sketch.</desc>
    <g
      id="icons/website"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="web" fill="#000000" fillRule="nonzero">
        <path
          d="M17.232,14.4 C17.328,13.608 17.4,12.816 17.4,12 C17.4,11.184 17.328,10.392 17.232,9.6 L21.288,9.6 C21.48,10.368 21.6,11.172 21.6,12 C21.6,12.828 21.48,13.632 21.288,14.4 L17.232,14.4 Z M15.108,21.072 C15.828,19.74 16.38,18.3 16.764,16.8 L20.304,16.8 C19.152,18.78 17.316,20.316 15.108,21.072 Z M14.808,14.4 L9.192,14.4 C9.072,13.608 9,12.816 9,12 C9,11.184 9.072,10.38 9.192,9.6 L14.808,9.6 C14.916,10.38 15,11.184 15,12 C15,12.816 14.916,13.608 14.808,14.4 Z M12,21.552 C11.004,20.112 10.2,18.516 9.708,16.8 L14.292,16.8 C13.8,18.516 12.996,20.112 12,21.552 Z M7.2,7.2 L3.696,7.2 C4.836,5.208 6.684,3.672 8.88,2.928 C8.16,4.26 7.62,5.7 7.2,7.2 Z M3.696,16.8 L7.2,16.8 C7.62,18.3 8.16,19.74 8.88,21.072 C6.684,20.316 4.836,18.78 3.696,16.8 Z M2.712,14.4 C2.52,13.632 2.4,12.828 2.4,12 C2.4,11.172 2.52,10.368 2.712,9.6 L6.768,9.6 C6.672,10.392 6.6,11.184 6.6,12 C6.6,12.816 6.672,13.608 6.768,14.4 L2.712,14.4 Z M12,2.436 C12.996,3.876 13.8,5.484 14.292,7.2 L9.708,7.2 C10.2,5.484 11.004,3.876 12,2.436 Z M20.304,7.2 L16.764,7.2 C16.38,5.7 15.828,4.26 15.108,2.928 C17.316,3.684 19.152,5.208 20.304,7.2 Z M12,8.8817842e-17 C5.364,8.8817842e-17 0,5.4 0,12 C-2.66453526e-16,15.1825979 1.26428208,18.2348448 3.51471863,20.4852814 C5.76515517,22.7357179 8.81740212,24 12,24 C18.627417,24 24,18.627417 24,12 C24,8.81740212 22.7357179,5.76515517 20.4852814,3.51471863 C18.2348448,1.26428208 15.1825979,-1.77635684e-16 12,8.8817842e-17 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default website;
