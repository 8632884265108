import {Component} from "react";
import PageElement from "../domain/PageElement";
import * as React from "react";

export interface Props {
  title: string;
  columns: any[];
}

export interface State {
}

export default class Tickets extends Component<Props, State> implements PageElement {
  public render(): JSX.Element {
    const {title, columns} = this.props;
    let i = 0;
    return (<div>
      <h2>{title}</h2>
      <ul>
        {columns.map(item => <li key={i++}>{JSON.stringify(item)}</li>)}
      </ul>
    </div>);
  }
}